
.Thermometer .Chart {
  position: absolute;
  z-index: 9;
  top: 10px;
  left: 0;
  width: 400px;
  height: 400px;
  transform: scale(1.5);
  opacity: 0;
  transition: .4s;
  transform-origin: 90px 130px;
  user-select: none;
  opacity: 0;
  transform: scale(1);
  &.visible {
    opacity: 1;
    transform: scale(1);
  }
  &.hidden {
    opacity: 0 !important;
    transform: scale(0.95) !important;
    transition: 0.2s !important;
    pointer-events: none !important;
  }
  &.blur {
    filter: blur(3px);
  }
}

.Thermometer .Chart .circles-effect {
  position: absolute;
  z-index: -1;
  top: 0;
  left:0;
  width: 100%;
  height:100%;
  opacity: 0;
  transition: 1.5s;
  transform: scale(1.1) rotate(30deg);
}
.Thermometer .Chart.visible .circles-effect {
  opacity: 1;
  transform: scale(1) rotate(0);
  transition-delay: 0.2s;
}

.Thermometer .Chart .circle-effect {
  position: absolute;
  z-index: -1;
  top: 0;
  left:0;
  width: 100%;
  height:100%;
  opacity: 0.4;
  background: url(./images/circle-white.svg) no-repeat center;
  background-size: 80%;
  pointer-events: none;
}
.Thermometer .Chart .circle-effect.b {
  background-image: url(./images/circle-mid.svg);
  background-size: 100%;
  opacity: 1;
}
.Thermometer .Chart .circle-effect.a { animation: ThermometerChartCircle infinite 80s ease -0.1s }
.Thermometer .Chart .circle-effect.b { animation: ThermometerChartCircle infinite 50s ease -0.3s }

@keyframes ThermometerChartCircle {
  from { transform: rotate(0) }
  to   { transform: rotate(360deg) }
}




.Thermometer {
  .Chart .ring {
    position: absolute;
    top:  0;
    left: 0;
    border-radius: 900px;
    border: 1px solid rgba(255,255,255, 0.2);
  }

  .Chart .ring.a { width: 200px; height: 200px; border-color: rgba(255,255,255, 0.1); }
  .Chart .ring.b { width: 160px; height: 160px; border-color: rgba(255,255,255, 0.2); }
  .Chart .ring.c { width: 120px; height: 120px; border-color: rgba(255,255,255, 0.3); }

  .Chart .ring.a { border-left: 2px solid #fff; border-right: 2px solid #fff; }
  .Chart .ring.b { border-left: 2px solid #fff }
  .Chart .ring.c { border-left: 2px solid #fff }

  .Chart .ring.a { animation: IndiceAnimate-ring-a infinite 2.4s ease -0.1s; }
  .Chart .ring.b { animation: IndiceAnimate-ring-b infinite 3.3s ease -0.3s reverse; }
  .Chart .ring.c { animation: IndiceAnimate-ring-c infinite 3.9s ease -0.6s; }

  .Chart .conteudo {
    font-family: 'Share Tech';
    text-align: center;
    margin: 64px 0 0 47px;
    width: 100px;
  }

  .Chart .conteudo .numero {
    line-height: 0.9;
    font-size: 50px;
    color: #fff;
  }
  .Chart .conteudo .status {
    line-height: 0.9;
    font-size: 24px;
    color: #daa709;
  }
}

@keyframes IndiceAnimate-ring-a {
  from { transform: translate(0px,0px) rotate(0) }
  to {   transform: translate(0px,0px) rotate(360deg) }
}
@keyframes IndiceAnimate-ring-b {
  from { transform: translate(18px,19px) rotate(0) }
  to   { transform: translate(18px,19px) rotate(360deg) }
}
@keyframes IndiceAnimate-ring-c {
  from { transform: translate(37px,38px) rotate(0) }
  to   { transform: translate(37px,38px) rotate(360deg) }
}














.Chart .content-main {
  position: relative;
  top: -50%;
  left: 50%;
  margin-top: -10px;
  transform: translate(-50%,-50%);
  color: #fff;
  text-align: center;
  text-rendering: geometricPrecision;
  pointer-events: none;
  cursor: default;
  transition: 0.4s;
  transform-origin: center;
}
.Chart .content-main.hidden {
  transition: none;
  opacity: 0;
  transform: translate(-50%,-50%) scale(1.3);
}


.Chart .content-main .total {
  font-family: 'Share Tech';
  font-size: 80px;
  line-height: 0.8;
  letter-spacing: -5px;
  opacity: 0.9;
}

.Chart .content-main .name {
  font-family: 'Share Tech';
  font-size: 24px;
  font-weight: lighter;
  line-height: 1.1;
  opacity: 0.5;
  width: 230px;
  margin: 10px auto;
}

.Chart .content-main .status {
  font-family: 'Share Tech';
  font-size: 22px;
  letter-spacing: 2px;
}




.Chart .circles {
  position: relative;
  display: inline-block;
  width: 400px;
  height:400px;
  top: 50%;
  left:50%;
  transform: translate(-50%,-50%);
}



.Chart path.arc {
  cursor: pointer;
  &:not(.fake):hover {
    stroke: #fff5;
    stroke-width: 2px;
  }
}
.Chart path.arc.fake {
  cursor: default !important;
}
.Chart path.arc:not(.fake):active {
  transition: none       !important;
  transform: scale(1.01) !important;
  opacity: 0.9           !important;
}

.Chart path.arc.fade-out {
  opacity: 0.4 !important;
  transform: scale(0.98) !important;
}


.Chart .btn-back {
  position: relative;
  top: -270px;
  left: 184px;
  width: 32px;
  height:32px;
  cursor: pointer;
  background: url(./images/x-button.svg) no-repeat center;
  background-size: contain;
  border: 0;
  opacity: 0;
  transition: 0.3s;
}
.Chart .btn-back.visible {
  opacity: 0.5;
}
.Chart .btn-back.visible:hover {
  opacity: 0.6;
}
.Chart .btn-back.visible:active {
  opacity: 0.4;
  transition: 0;
  transform: scale(0.9);
}


.Chart {
  .content-main, .btn-back {
    visibility: hidden;
  }
}
