
.CardPNIDGeral {

  .title span {
    display: inline-block;
    padding-left: 5px;
    text-transform: uppercase;
    opacity: 0.5;
  }

  .MenuBar {
    min-width: 660px;
    button {
      padding-top: 10px;
      text-transform: uppercase;
    }
    button > div {
      display: none;
    }
  }

  .container-rows {
    max-height: 400px;
    margin: 0 -20px -20px -20px;
    padding: 20px 0;
    overflow-y: auto;
    background: #3B4046;
    .row {
      margin: 0 0 1px 0;
      padding: 10px 20px;
      cursor: pointer;
      border-bottom: 1px solid rgba(255,255,255, 0.05);
      transition: 0.1s;
      &:hover {
        background: rgba(0,0,0, 0.05);
      }
      &:active {
        opacity: 0.7;
        transition: none;
      }
      .row-title {
        margin: 0;
        line-height: 1.2;
        font-family: 'Share Tech';
        font-size: 18px;
        text-transform: uppercase;
        color: #fff;
        opacity: 0.9;
      }
      .row-subtitle {
        margin: 0;
        line-height: 1.2;
        font-family: 'Share Tech';
        font-size: 16px;
        text-transform: uppercase;
        color: #fff;
        opacity: 0.5;
      }
    }
    .row.todos {
      .tipo {
        display: inline-block;
        vertical-align: top;
        width: 150px;
        margin: 10px 0 0 0;
        line-height: 1.2;
        font-family: 'Share Tech';
        font-size: 18px;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        opacity: 0.9;
      }
      .text {
        display: inline-block;
        width: calc(100% - 170px);
      }
    }
  }

}
