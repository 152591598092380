.CriminalHistoricoDetalhe {

  height: 480px;
  width: 700px;
  overflow-y: auto;

  p.message {
    padding: 100px;
    font-family: 'Share Tech';
    font-size: 18px;
    text-rendering: geometricPrecision;
    color: #dba70a;
    opacity: 1;
  }

  .columns {
    display: flex;

    .column {
      transition: 0.2s;

      &.photo {
        margin-right: 10px;
        transition: 0.6s;
      }

      &.info {
        flex-grow: 2.5;
        color: #FFFFFF;

        overflow-x: hidden;
        overflow-y: auto;
      }

      &.info.border {
        padding-left: 20px;
        margin-left: 10px;
        border-left: 1px solid #dba70a;
      }
    }
  }

  &.hidden .columns .column.photo {
    transform: translate(0, 20px) scale(1.1);
    // opacity: 0;
  }

  .container-item {
    padding: 5px 10px 5px 0;
    text-rendering: geometricPrecision;

    &>.item {
      position: relative;
      display: inline-block;
      padding: 0 15px 5px 10px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-top: 0;
      border-right: 0;
      border-radius: 0 0 3px 3px;

      &.min80 {
        min-height: 80px;
      }

      label {
        display: block;
        margin: 0;
        margin-top: -8px;
        padding: 0 5px;
        font-family: 'Open Sans';
        font-size: 12px;
        color: rgba(255, 255, 255, 0.5);

        &:before {
          content: ' ';
          position: absolute;
          left: 0;
          top: 0px;
          width: 8px;
          height: 1px;
          background: rgba(255, 255, 255, 0.2);
        }
      }

      .value {
        font-family: 'Share Tech';
        font-size: 17px;
        color: #fff;
      }
    }
  }

  .group-info {
    padding: 5px 0;

    &.green {
      background: #3cff3c0d;
    }

    h1 {
      font-family: 'Share Tech';
      letter-spacing: 2px;
      font-size: 18px;
      color: #fff;
    }

    p {
      padding: 0;
      margin: 0;
      font-family: 'Roboto';
      text-rendering: geometricPrecision;
      line-height: 1.2;
      color: #fff;
      font-size: 14px;
      opacity: 0.8;
      cursor: default;

      &.main {
        opacity: 1;
        ;
        line-height: 1.5;
        font-weight: bold;
      }

      label {
        display: inline-block;
        width: 55px;
        padding-right: 5px;
        text-align: right;
        color: rgba(255, 255, 255, 0.5);
      }

      div {
        display: flex;
        flex-grow: 1;
      }
    }

    .row {
      cursor: default;
      display: flex;
    }

    .row>.container-item {
      flex: 1;

      .item {
        width: 100%;
      }
    }
  }

  .clickable {
    margin: 5px 10px 5px 0;
    padding: 5px 50px 5px 0;
    cursor: pointer !important;
    background: rgba(255, 255, 255, 0.02) url(../../../../images/arrow-right.svg) no-repeat 96% center;
    background-size: 22px;
    border-top: 1px solid rgba(255, 255, 255, 0.04);
    border-bottom: 1px solid rgba(255, 255, 255, 0.04);
    transition: 0.1s;

    &.blocked {
      pointer-events: none;
      padding: 5px 0 0 0;
      margin: 0;
      background: none;
      border: 0;
    }

    p {
      cursor: pointer;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.03);
      background-position: 97% center;
    }

    &:active {
      opacity: 0.8;
      transition: none;
    }
  }
}


.CriminalHistoricoDetalhe .picture {
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  margin: 0 auto;
  width: 128px;
  min-height: 150px;
  text-align: center;
  background: #fff;
  cursor: pointer;

  img {
    width: 100%;
  }

  &:active {
    transform: scale(0.98);
  }

  // se não existe imagem, desativamos interação de click/zoom na imagem
  &.empty {
    pointer-events: none;
    padding-top: 20px;
    height: 160px;

    img {
      width: auto;
      margin-top: -30px;
      padding-left: 17px;
    }
  }

  .see-more {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: 0.1s;
    pointer-events: none;

    .icon {
      display: inline-block;
      width: 50px;
      height: 50px;
      opacity: 0.6;
      background: url(../../../../images/icon-eye-opened.svg) no-repeat center;
      background-size: 40px;

      &.close {
        background: url(../../../../images/icon-eye-closed.svg) no-repeat center;
        background-size: 40px;
      }
    }
  }

  &:hover .see-more {
    opacity: 0.5;
  }

  &:active .see-more {
    opacity: 0.6;

    .icon {
      transform: scale(0.9);
    }
  }
}

.CriminalHistoricoDetalhe .images-count {
  width: 128px;
  margin-top: 5px;
  padding: 5px 0;
  font-family: 'Share Tech';
  font-size: 14px;
  line-height: 1;
  text-align: center;
  text-rendering: geometricPrecision;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  background: rgba(0, 0, 0, 0.1);
}

.CriminalHistoricoDetalhe .container-images {
  position: relative;
  // top: 60px;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);

  .container-header {
    color: #fff;
    background: rgba(0, 0, 0, 0.1);

    button.btn-back {
      vertical-align: top;
      padding: 3px 0 3px 16px;
      margin: 0;
      outline: 0;
      cursor: pointer;
      background: rgba(255, 255, 255, 0);
      border: none;
      opacity: 0.8;
      transition: 0.1s;

      &:hover {
        opacity: 0.9;
        background: rgba(255, 255, 255, 0.05);
      }

      &:active {
        opacity: 0.5;
        background: rgba(255, 255, 255, 0.03);
        transition: none;
      }

      .icon-left {
        display: inline-block;
        vertical-align: top;
        width: 28px;
        height: 28px;
        background: url(../../../../images/arrow-left.svg) no-repeat center;
        background-size: 18px;
      }

      .label {
        display: inline-block;
        vertical-align: top;
        padding: 5px 20px 0 2px;
        font-family: 'Roboto';
        font-size: 12px;
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }

  .images {
    height: 538px;
    // overflow-y: auto;

    .image {
      border-top: 1px solid #aaa7;
      margin-bottom: 1px;
    }

    img {
      display: block;
      pointer-events: none;
      width: 100%;
      min-height: 100px;
      padding: 5px;
      background: #6e7a7f;
    }

    img:first-child {
      padding-top: 10px;
    }

    img:last-child {
      padding-bottom: 10px;
    }

    .image-label {
      z-index: 9999;
      float: right;
      margin: -45px 10px 0 0;
      padding: 1px 20px 1px 10px;
      color: rgba(255, 255, 255, 0.7);
      background: rgba(0, 0, 0, 0.3);
      transform: translate(1px, 1px);
    }

    .text-loading {
      display: inline-block;
      vertical-align: middle;
      padding-right: 10px;
      font-size: 10px;
      color: #fff7;

      &.blink {
        animation: TextBlink infinite 0.3s ease alternate;
      }
    }
  }
}

.CriminalHistoricoDetalhe .container-images {
  pointer-events: none;
  visibility: hidden;
  height: 0;
}

.CriminalHistoricoDetalhe.picture-mode {
  height: 550px;

  // .columns {
  //   visibility: hidden;
  // }

  .column.info {
    // opacity: 0;
    pointer-events: none;
  }

  .panels {
    display: none;
  }

  .container-images {
    pointer-events: all;
    visibility: visible;
    height: 572px;
  }
}

.CriminalHistoricoDetalhe .panels {
  // padding: 10px 0 0 0;
  // margin: 0 -20px -20px 0;
  // overflow-y: auto;
  // max-height: 330px;

  .panel {
    margin-bottom: 10px;
    background: rgba(255, 255, 255, 0.07);
    border-left: 1px solid rgba(255, 255, 255, 0.5);

    &:first-child {
      background: rgba(255, 255, 255, 0.1);
    }

    &.panel.yellow {
      background: #686756a0;
      border-left-color: #dba70a;

      p.main {
        color: #dec26d !important;
        border-left-color: #dba70a !important;
      }
    }

    &.panel.red {
      background: #ce3e3e70;
    }
  }
}

.CriminalHistoricoDetalhe .group-info.mandatos {
  padding: 0 0 10px 0;
  margin-bottom: 10px;


  //height: 100px;
  overflow: auto;

  p.main {
    border-left: 3px solid rgba(255, 255, 255, 0.5);
    padding: 5px 0 5px 10px;
    margin-bottom: 5px;
    font-family: 'Share Tech';
    font-weight: lighter;
    font-size: 18px;
    color: #eee;
    text-rendering: geometricPrecision;
  }

  .row {
    padding: 5px;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
}

.CriminalHistoricoDetalhe .row-item {
  text-rendering: geometricPrecision;
  padding: 7px 20px 7px 5px;
  line-height: 1.2;
  min-width: 250px;
  cursor: default;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  .col {
    display: inline-block;
    vertical-align: middle;

    &.a {
      width: 90px;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      color: #ddd;
    }

    &.b {
      width: calc(100% - 90px)
    }
  }

  .name {
    font-size: 14px;
    color: rgcriminal-title ba(255, 255, 255, 0.7);
  }

  .desc {
    line-height: 1;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
  }
}

.menuCriminal-container {
  margin: -15px 0 20px 0;
}

.criminal-title {
  display: flex;
  pointer-events: none;
  font-family: 'Share Tech';
  letter-spacing: -1px;
  font-size: 20px;
  text-rendering: geometricPrecision;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 20px;
  padding-right: 55px;

  span {
    padding-left: 5px;
    opacity: 0.5;
  }
}