
.CardCameraLista {

  .card-title.category span {
    display: inline-block;
    padding-left: 5px;
    opacity: 0.5;
  }

  .container {
    align-items: center;
    margin: -15px -20px 0 -20px;
    padding: 10px 20px 15px 20px;
    text-rendering: geometricPrecision;
    color: #fff;
    border-bottom: 1px solid rgba(255,255,255, 0.1);
    border-top: 1px solid rgba(255,255,255, 0.1);

    .MapCircleSelection {
      padding-bottom: 5px;
    }
  }

  p.message {
    pointer-events: none;
    padding: 30px 20px;
    font-family: 'Share Tech';
    font-size: 18px;
    text-align: center;
    text-rendering: geometricPrecision;
    text-transform: uppercase;
    //color: #f9dfaab5;
    color: #DAA709;
    &.no-data {
      min-width: 610px;
      color: #DAA709;
    }
  }

  .summary {
    position: relative;
    margin: -10px 0 -15px 0;
    padding: 10px 0;
    input {
      width: 100%;
      padding: 2px 80px 2px 50px;
      font-size: 16px;
      color: #36393F;
      text-transform: uppercase;
      text-rendering: geometricPrecision;
      outline: 0;

      background: #F6F8FA url(../../../../images/icon-search2.svg) no-repeat 18px center;

      border: 1px solid rgba(255,255,255, 0.4);
      border-radius: 50px;

      &::placeholder { color: #36393F; text-transform: capitalize; }

      &:focus {
        background-color: #F6F8FA;
      }
    }
    .total-hidden {
      position: absolute;
      top: 15px;
      right: 20px;
      color: #36393F;
      
    }
    .btn-circle {
      display: none;
    }
  }

  .table-list .ReactVirtualized__List {
    outline: 0;
  }

  .table-list {
    padding: 5px 0 10px 0;
    margin: -5px -20px -20px -20px;
    max-height: 400px;
    overflow-y: auto;
    .row.header {
      pointer-events: none;
      padding-bottom: 7px;
      background: #0001;
      .col {
        font-family: 'Roboto';
        font-weight: bold;
        font-size: 12px;
      }
    }
    .row {
      padding: 5px 20px;
      line-height: 1.2;
      cursor: pointer;
      color: rgba(255,255,255, 0.7);
      text-rendering: geometricPrecision;
      text-transform: uppercase;
      opacity: 0.9;
      &:hover {
        opacity: 1;
        background: rgba(255,255,255, 0.05);

      }
      &:active {
        opacity: 0.5;
      }
      .col {
        display: inline-block;
        width: 75px;
        text-align: center;
        &.text {
          padding-left: 10px;
          width: 435px;
          text-align: left;
        }
      }
    }
  }

}
