
.MapLayers {
  user-select: none;
  overflow-x: hidden;
  position: fixed;
  z-index: 999999;
  right: 10px;
  bottom: 85px;
  padding: 10px 3px;
  min-width: 30px;
  min-height: 40px;
  background: #606a6fc7;
  border-radius: 2px;
  transition: 0.2s;
  opacity: 0;
  &.ready {
    opacity: 1;
  }
  &.hidden {
    z-index: 9;
    width: 30px;
    background: #757e8277;
    .line {
      padding-left: 0;
      .name {
        display: none;
      }
    }
  }
  
  .line {
    padding: 2px 5px 2px 20px;
    height: 26px;
    text-align: right;
    cursor: pointer;
    transition: 0.1s;
    &.closed {
      opacity: 0.7;
      .name {
        text-decoration: line-through;
        color: #ccc9;
      }
      .circle {
        filter: grayscale(100%) !important;
        opacity: 0.3;
        &::after {
          opacity: 1;
          transform: translate(-4px,4px) rotate(45deg);
        }
      }
    }
    &:active {
      opacity: 0.5;
      transition: none;
    }
    &:hover {
      background: #0001;
    }
    .name {
      display: inline-block;
      vertical-align: middle;
      padding-right: 10px;
      padding-left: 5px;
      margin-top: -2px;
      font-family: 'Share Tech';
      font-size: 16px;
      line-height: 26px;
      text-transform: uppercase;
      color: #fff7;
    }
    .circle-container {
      display: inline-block;
      vertical-align: middle;
      width: 14px;
      height: 14px;
      margin-top: -4px;
      padding: 1px 0 0 1px;
      background: #fff1;
      border: 1px solid #fff4;
      border-radius: 50px;
    }
    .circle {
      width: 10px;
      height: 10px;
      opacity: 0.8;
      background: #fff5;
      border-radius: 50px;
      &::after {
        position: absolute;
        display: block;
        content: ' ';
        width: 18px;
        height: 2px;
        background: #ccc;
        transform: translate(-2px,5px) rotate(0deg);
        transition: 0.1s;
        opacity: 0;
      }
    }
  }
}

body.no-header .MapLayers {
  opacity: 0;
  transition: none;
}