.CardFactionsEventDetails {

  .caption {
    line-height: 1;
    font-family: 'Share Tech';
    font-size: 16px;
    color: #fffa;
  }

  .text {
    max-width: 700px;
    max-height: 500px;
    overflow-y: auto;
    margin: 5px -20px;
    padding: 0 20px 25px 20px;
    line-height: 1.1;
    font-family: 'Share Tech';
    font-size: 16px;
    white-space: normal;
    text-rendering: geometricPrecision;
    color: #a7b0b3;
    & > div {
      pointer-events: none;
      min-height: 8px;
    }
  }

  .content {
    padding: 10px 20px 20px 20px;
    margin: -5px -20px -20px -20px;
    width: 785px;
    min-height: 150px;
    max-height: 500px;
    overflow-y: auto;
  }

  .photos {
    text-align: left;
    overflow-y: auto;
    white-space: nowrap;
    margin-bottom: 20px;
  }

  .photo {
    text-align: center;
    overflow: hidden;
    margin: 4px 4px 10px 4px;
    padding: 2px;
    cursor: pointer;
    transition: 0.1s;
    border: 2px solid rgba(255,255,255, 0);
    display: inline-block;
    width: 256px;
    vertical-align: middle;
    transition: all 100ms ease-in;
    
    &:hover {
      border-color: rgba(255,255,255, 0.1);
      background: rgba(255,255,255, 0.1);
    }
    
    &:active {
      transform: scale(0.98);
      border-color: rgba(255,255,255, 0.05);
    }

    img {
      height: 256px;
      width: 100%;
      transition: all 100ms ease-in;
      object-fit: cover;
    }

    label {
      display: block;
      width: 100%;
      padding: 5px 10px;
      text-rendering: geometricPrecision;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 1.1;
      color: rgba(255,255,255, 0.7);
      background: rgba(0,0,0, 0.2);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

  }

}