.Detalhe {
  position: relative;
  top: 0;
  transition: 0.4s;

  max-width: 800px;
  white-space: normal;

  &.hidden {
    transition: none;
    height: 0;
    opacity: 0;
    transform: translate(0, 10px);
  }

  .columns {
    display: flex;

    .column {
      transition: 0.2s;

      &.photo {
        margin-right: 10px;
        transition: 0.6s;
      }

      &.info {
        flex-grow: 2.5;
        color: #FFFFFF;

        height: 610px;

        overflow-x: hidden;
        overflow-y: auto;
      }
    }
  }

  .row {
    cursor: default;
    display: flex;
  }

  .row>.container-item {
    flex: 1;
    
    .item {
      width: 100%;
    }
  }

  &.hidden .columns .column.photo {
    transform: translate(0, 20px) scale(1.1);
    opacity: 0;
  }

  .group-info {
    display: flex;
    flex-wrap: wrap;
    gap: 0.2rem;

    h1 {
      font-family: 'Share Tech';
      letter-spacing: 2px;
      font-size: 18px;
      color: #fff;
    }

    p,
    div.line {
      padding: 0;
      margin: 0;
      font-family: 'Roboto';
      text-rendering: geometricPrecision;
      line-height: 1.2;
      color: #fff;
      font-size: 14px;
      opacity: 0.8;
      cursor: default;

      &.main {
        opacity: 1;
        line-height: 1.5;
        font-weight: bold;
      }

      label {
        vertical-align: top;
        display: inline-block;
        width: 165px;
        padding-right: 5px;
        text-align: right;
        color: rgba(255, 255, 255, 0.5);
      }

      &>div {
        display: inline-block;
        padding-right: 50px;
        max-width: 450px;
        white-space: normal;
        line-height: 1;
      }
    }

    div {
      display: flex;
      flex-grow: 1;
    }
  }

  .container-item {
    padding: 5px 10px 5px 0;
    text-rendering: geometricPrecision;

    &>.item {
      position: relative;
      display: inline-block;
      padding: 0 15px 5px 10px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-top: 0;
      border-right: 0;
      border-radius: 0 0 3px 3px;

      &.min80 {
        min-height: 80px;
      }

      label {
        display: block;
        margin: 0;
        margin-top: -8px;
        padding: 0 5px;
        font-family: 'Open Sans';
        font-size: 12px;
        color: rgba(255, 255, 255, 0.5);

        &:before {
          content: ' ';
          position: absolute;
          left: 0;
          top: 0px;
          width: 8px;
          height: 1px;
          background: rgba(255, 255, 255, 0.2);
        }
      }

      .value {
        font-family: 'Share Tech';
        font-size: 17px;
        color: #fff;
      }
    }
  }

  .picture {
    display: block;
    margin: 10px auto;
    width: 150px;
    text-align: center;
    background: #fff;
    transition: 0.2s;
    margin-top: 30px;
    cursor: pointer;

    img {
      width: 100%;
    }

    &:active {
      transform: scale(0.98);
      transition: 0.1s;
    }

    // se não existe imagem, desativamos interação de click/zoom na imagem
    &.empty {
      pointer-events: none;
      padding-top: 30px;
      height: 200px;

      img {
        width: auto;
      }
    }
  }

  .clickable {
    margin: 3px 10px 3px 0;
    padding: 3px 50px 3px 0px;
    cursor: pointer !important;
    background: rgba(255, 255, 255, 0.02) url(../../../../images/arrow-right.svg) no-repeat 96% center;
    background-size: 22px;
    border-top: 1px solid rgba(255, 255, 255, 0.04);
    border-bottom: 1px solid rgba(255, 255, 255, 0.04);
    transition: 0.1s;

    &.blocked {
      pointer-events: none;
      padding: 0;
      margin: 0;
      background: none;
      border: 0;
    }

    .group-info p {
      cursor: pointer;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.03);
      background-position: 97% center;
    }

    &:active {
      opacity: 0.8;
      transition: none;
    }
  }
}

.Detalhe.picture-mode {
  .column.photo .picture {
    width: 450px;
    margin: 0;
  }

  .column.info {
    display: none;
    opacity: 0;
    pointer-events: none;
  }
}