
.CardCVLIOcorrenciaDepoimentos {
  text-rendering: geometricPrecision;

  .card-title {
    color: #DAA709;
    span {
        display: inline-block;
        padding-left: 5px;
        letter-spacing: 0;
        color: #DAA709;
        opacity: 0.8;
    }
  }

  .MenuBar {
    padding: 0 50px 0 20px;
    border-bottom-color: #DAA709;
    .select-line {
      background: #DAA709;
    }
    .buttons button {
      font-size: 16px;
      padding-top: 10px;
      padding-bottom: 7px;
    }
  }

  .message.empty {
    padding: 50px 20px 20px 20px;
    text-align: center;
    font-family: 'Share Tech';
    color: #DAA709;
    text-rendering: geometricPrecision;
    font-size: 18px;
  }

  .content {
    margin: 0 -20px -20px  -20px;
    min-height: 130px;
  }

  .content > div:last-child {
    border-bottom: 0 !important;
  }

  .depoiment.interaction {
    background: rgba(255,255,255, 0);
    &:hover {
      background: rgba(255,255,255, 0.02);
      .name .code, .name .value {
        border-bottom-color: rgba(255,255,255, 0.1);
      }
    }
    .text {
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
      &:active {
        opacity: 0.5;
      }
    }
  }

  .content .depoiment {
    padding: 20px 20px 10px 20px;
    border-bottom: 2px solid rgba(0,0,0, 0.05);
    .caption {
      display: inline-block;
      padding: 0px 10px 0 20px;
      margin-left: -20px;
      font-family: 'Share Tech';
      font-size: 16px;
      color: #ddd;
      background: rgba(255,255,255, 0.1);
    }
    .name {
      display: inline-block;
      padding-left: 5px;
      .code {
        display: inline-block;
        margin-left: 5px;
        padding: 0 5px;
        letter-spacing: 0;
        font-family: 'Share Tech';
        font-size: 16px;
        color: #fff;
        opacity: 0.75;
        cursor: pointer;
        background: url(../../../../images/icon-search-16x16.svg) no-repeat 7px 45%;
        background-size: 0;
        border-bottom: 1px solid rgba(255,255,255, 0);
        &:hover {
          padding: 0 15px 0 25px;
          background-size: 12px;
          border-bottom-color: #ccb64a;
          opacity: 0.8;
        }
        &:active {
          padding: 0 15px 0 25px;
          background-size: 12px;
          border-bottom-color: #ccb64a;
          opacity: 0.6;
        }
      }
      .value {
        display: inline-block;
        margin-left: 5px;
        padding: 0 5px;
        font-family: 'Share Tech';
        font-size: 16px;
        color: #fff;
        opacity: 0.9;
        cursor: pointer;
        background: url(../../../../images/icon-search-16x16.svg) no-repeat 7px 45%;
        background-size: 0;
        border-bottom: 1px solid rgba(255,255,255, 0);
        &:hover {
          padding: 0 15px 0 25px;
          background-size: 12px;
          border-bottom-color: #DAA709;
          opacity: 0.95;
        }
        &:active {
          padding: 0 15px 0 25px;
          background-size: 12px;
          border-bottom-color: #DAA709;
          opacity: 0.8;
        }
      }
    }
    .text {
      overflow: hidden;
      max-width: 630px;
      max-height: 45px;
      padding: 5px 0;
      font-family: 'Share Tech';
      font-size: 16px;
      color: #fff;
      opacity: 0.5;
      p {
        margin: 0;
        padding: 0;
        line-height: 1.1;
        white-space: normal;
      }
    }
  }

  .depoiment.narrative {
    .text {
      overflow: visible;
      max-height: max-content;
      padding: 10px 0 20px 0;
      white-space: normal;
      line-height: 1.1;
      opacity: 0.7;
    }
  }

  .depoiment.interaction.maximized {
    background: #3B4046;
    .text {
      padding: 10px 0;
      max-height: max-content;
      overflow: visible;
      opacity: 0.7;
      &:hover {
        opacity: 0.75;
      }
      &:active {
        opacity: 0.6;
      }
    }
  }

  .depoiment.depoiment.interaction .headline {
    button {
      display: inline-block;
      outline: 0;
      cursor: pointer;
      text-rendering: geometricPrecision;
      background: none;
      border: 0;
    }
    button.btn-close {
      float: right;
      pointer-events: none;
      width: 16px;
      height: 16px;
      opacity: 0;
      background: url(../../../../images/cancel-x.svg) no-repeat center;
      background-size: 10px;
    }
    button.btn-code {
      padding: 0 10px 0 25px;
      margin: 0;
      background: url(../../../../images/icon-search.svg) no-repeat 5px 50%;
      background-size: 16px;
    }
    button.btn-name {
      padding: 0 10px 0 25px;
      margin: 0;
      background: url(../../../../images/icon-search.svg) no-repeat 5px 50%;
      background-size: 16px;
    }
  }

  .depoiment.depoiment.interaction.maximized .headline {
    .btn-close {
      pointer-events: all;
      opacity: 0.5;
      &:hover {
        opacity: 0.7;
      }
      &:active {
        opacity: 0.3;
      }
    }
  }

}
