
.SearchPanel-BodyOptions {
  transition: 0.1s;
  &.hidden {
    opacity: 0;
    transform: translate(0,10px);
  }

  .container {
    padding: 10px 0 20px 0;
    &.margin-top {
      padding: 30px 0 30px 0;
    }
    label {
      display: block;
      margin: 0px 0 20px 0;
      padding: 5px 10px;
      font-size: 16px;

      color: #CA9D40;
      border-left: 3px solid #CA9D40;

      font-family: 'Share Tech';
      b {
        padding-left: 4px;
        font-weight: normal;
        color: #fff8;
      }
      .btn-close {
        float: right;
        width: 16px;
        height:16px;
        margin: 3px 20px 0 0;
        cursor: pointer;
        background: url(../../../images/cancel-x.svg) no-repeat center;
        background-size: 12px;
        border: 0;
        opacity: 0.4;
        &:hover {
          opacity: 0.7;
        }
        &:active {
          opacity: 0.2;
        }
      }
    }
    .tags {
      padding: 0 15px 15px 20px;
      button {
        display: inline-block;
        margin: 5px;

        padding: 6px 30px;
        font-family: Inter, sans-serif;
        font-weight: 600;
        

        outline: 0;
        font-size: 14px;
        cursor: pointer;
       
        color:#F6F8FA;

        background: rgba(0,0,0, 0);

        border: 2px solid #F6F8FA;

        border-radius: 50px;
        &:hover {
          color: #DAA709;
          border-color: #DAA709;
        }
        &:active {
          opacity: 0.5;
        }
      }
    }
  }
}
