.initial-menu {
    position: absolute;
    top: 90px;
    left: 30px;
    z-index: 999997;

    background-color: #3B4046;
    width: 6rem;
    border-radius: 10px;
    border: 2px solid #B5B7B9;


    padding: 2rem 0.3rem 1rem 0.3rem;

    display: grid;
    grid-template-columns: 1fr;
    
    gap: 20px;

    animation-name: slide;
    animation-duration: 1s;

    @keyframes slide {
        from {
            left: -130px;
        }
        to {
            left: 30px;
        }
    }


    .icon-group {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        svg path {
            stroke: #F6F8FA;
            transition: ease-in-out 0.2s;
        }

        h6 {
            margin-top: 0.625rem;
            font-size: 1rem;
            font-family: Share Tech, sans-serif;
            font-weight: 400;
            color: #F6F8FA;
        }
    }

    .icon-group-active {

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        svg path {
            stroke: #D6A536;
            transition: ease-in-out 0.2s;
        }

        h6 {
            margin-top: 0.625rem;
            font-size: 1rem;
            font-family: Share Tech, sans-serif;
            font-weight: 400;
            color: #D6A536;
            transition: ease-in-out 0.2s;
        }
    }

    h6{
        margin-top: 0.625rem;
        font-size: 1rem;
        font-family: Share Tech, sans-serif;
        font-weight: 400;
    
        color: #F6F8FA;
    }
 
}

.hidden {
    pointer-events: none;
    opacity: 0;
    transition: 0;
}