
.CardCVLIOcorrencia {
  text-rendering: geometricPrecision;

  .card-title {
    color: #DAA709;
    span {
        display: inline-block;
        padding-left: 5px;
        letter-spacing: 0;
        color: #DAA709;
        opacity: 0.8;
    }
  }

  .group-info {
    pointer-events: none;
    padding: 5px 0;
    h1 {
      font-family: 'Share Tech';
      letter-spacing: 2px;
      font-size: 18px;
      color: #fff;
    }
    p, div.line {
      padding: 0;
      margin: 0;
      font-family: 'Roboto';
      text-rendering: geometricPrecision;
      text-transform: uppercase;
      line-height: 1.3;
      color: #fff;
      font-size: 14px;
      opacity: 0.8;
      cursor: default;
      &.main {
        opacity: 1;;
        line-height: 1.5;
        font-weight: bold;
      }
      label {
        vertical-align: top;
        display: inline-block;
        width: 100px;
        padding-right: 5px;
        text-align: right;
        color: rgba(255,255,255, 0.5);
      }
      & > div {
        display: inline-block;
        max-width: 400px;
        white-space: normal;
        line-height: 1;
      }
    }
  }

  .group {
      margin-right: -20px;
      padding: 20px 0;
      text-rendering: geometricPrecision;
      color: #fff;
      label {
        vertical-align: top;
        display: inline-block;
        width: 70px;
        padding: 8px 8px 4px 0;
        line-height: 1.2;
        text-align: right;
        font-size: 12px;
        text-transform: uppercase;
        font-family: 'Open Sans';
        color: rgba(255,255,255, 0.5);
      }
      b {
        vertical-align: top;
        display: inline-block;
        width: calc(100% - 70px);
        padding: 5px 40px 5px 10px;
        line-height: 1.1;
        font-family: 'Share Tech';
        font-size: 18px;
        color: rgba(255,255,255, 0.6);
        background: rgba(0,0,0, 0.1);
      }
      .row:last-child b {
        padding-bottom: 15px;
      }
  }

  .group-info {
    margin-top: -5px;
    .main {
      margin: 0 -20px 0 -20px !important;
      padding: 5px 0 5px 20px;
      background: rgba(0,0,0, 0.05) !important;
    }
    .space {
      height: 7px;
    }
  }

}
