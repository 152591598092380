
@keyframes shake {
  10%, 90%      { transform: translate3d( -4px, 0, 0) }
  20%, 80%      { transform: translate3d(  8px, 0, 0) }
  30%, 50%, 70% { transform: translate3d(-16px, 0, 0) }
  40%, 60%      { transform: translate3d(  8px, 0, 0) }
}

.MapCircleSelection {
  .fields {
    text-align: right;
    &.shake {
      animation: shake infinite 1s ease alternate;
      animation-iteration-count: 1;
    }
    &.no-edit {
      label {
        opacity: 0.2;
        transform: translate(7px,23px);
      }
      input {
        pointer-events: none;
        opacity: 0.3;
      }
    }
    .field:first-child {
      padding-left: 0;
    }
  }

  .field {
    vertical-align: bottom;
    display: inline-block;
    padding: 2px 5px;
    label {
      display: block;
      font-family: 'Share Tech';
      text-transform: uppercase;
      text-align: left;
      line-height: 1.2;
      color: #fff;
      opacity: 0.4;
    }
    input {
      max-width: 100px;
      padding: 2px 5px;
      margin-bottom: 3px;
      font-size: 16px;
      font-family: 'Roboto';
      text-rendering: geometricPrecision;
      outline: 0;
      color: #fffc;
      background: rgba(255,255,255, 0.1);
      border: 1px solid rgba(255,255,255, 0.2);
      border-radius: 2px;
      &:focus {
        color: #ffff;
        background: rgba(255,255,255, 0.15);
      }
    }
  }
  .field.action {
    display: inline-block;
    padding-right: 0;
    button {
      width: 110px;
      padding: 8px 15px;
      font-family: 'Open Sans';
      font-size: 12px;
      outline: 0;
      cursor: pointer;
      color: rgba(255,255,255, 0.7);
      background: rgba(0,0,0, 0.05);
      border: 1px solid rgba(255,255,255, 0.1);
      border-radius: 3px;
      transition: 0.2s;
      &:hover {
        color: rgba(255,255,255, 0.9);
        background: rgba(0,0,0, 0.1);
        border-color: rgba(255,255,255, 0.2);
      }
      &:active {
        color: rgba(255,255,255, 0.3);
        background: rgba(0,0,0, 0.02);
        border-color: rgba(255,255,255, 0.05);
        transition: none;
      }
    }
  }
}
