
.Thermometer .CenterPanel {
  position: absolute;
  z-index: 12;
  left: 170px;
  top: 100px;
  width: 60px;
  height: 220px;
  text-align: center;
  color: #fff;
  transition: 0.3s;
  &.loading {
    opacity: 0;
    transform: scale(0.8);
  }

  .container-indicator {
    pointer-events: none;
    position: absolute;
    top: 20px;
    left: -80px;
    width: 220px;
    text-align: center;
    transition: 0.2s;
    .value {
      line-height: 0.9;
      font-family: 'Share Tech';
      font-size: 58px;
      color: #fff;
    }
    .status {
      padding: 10px 0;
      line-height: 0.9;
      font-size: 24px;
      text-transform: uppercase;
      color: #daa709;
    }
    .title {
      line-height: 1;
      font-size: 18px;
      text-transform: uppercase;
      opacity: 0.5;
      color: #fff;
    }
  }
  &.selecting .container-indicator {
    pointer-events: none;
    transform: scale(0.9);
    opacity: 0;
  }

  .container-panel {
    position: absolute;
    top: 160px;
    display: block;
    width: 160px;
    height: 35px;
    left: -50px;
    padding: 5px 0;
    opacity: 0.8;
    background: #566065bb;
    border: 2px solid #6a7479bb;
    border-radius: 3px;
    transition: 0.1s;
    &:hover {
      background: #566065cc;
      border-color: #6a7479cc;
    }
    .panel {
      pointer-events: none;
      opacity: 0;
      transition: 0.2s ease 0;
      .panel-title {
        margin-top: -5px;
        line-height: 2;
        text-align: center;
        font-family: 'Roboto';
        font-size: 12px;
      }
      .panel-options {
        margin: 10px 0 0 -1px;
        button {
          display: block;
          width: 100%;
          padding: 4px 10px;
          outline: 0;
          cursor: pointer;
          line-height: 1.1;
          font-family: 'Share Tech';
          font-size: 14px;
          text-align: left;
          text-rendering: geometricPrecision;
          color: #fffc;
          background: #555e6300;
          border: 0;
          border-left: 2px solid #fff0;
          border-radius: 0;
          &.selected {
            background: #555e63aa;
            background: #636c71;
            border-left-color: #fffa;
          }
          &:hover {
            color: #fff;
            background: #737c81;
          }
          &:active {
            color: #fffb;
            border-left-color: #fffa;
            padding-left: 11px;
          }
        }
      }
      .panel-options:active {
        button:not(:active) {
          border-left-color: transparent;
          opacity: 0.6;
        }
      }
    }
    button.btn-select.current {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 5px 10px 5px 8px;
      font-family: 'Share Tech';
      font-size: 14px;
      text-align: left;
      text-rendering: geometricPrecision;
      outline: 0;
      cursor: pointer;
      color: #fffc;
      opacity: 0.8;
      background: url(../../images/btn-select.svg) no-repeat 98% center;
      border: 0;
      border-radius: 0;
      &:hover {
        opacity: 1;
      }
      &:active {
        opacity: 0.7;
        transition: none;
      }
    }
  }
  &.selecting .container-panel {
    top: 15px;
    left: -80px;
    width: 220px;
    height: 180px;
    padding-top: 10px;
    .panel {
      padding-top: 15px;
      pointer-events: all;
      opacity: 1;
      transition: 0.2s ease 0.1s;
      button {
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
    button.btn-select.current {
      pointer-events: none;
      opacity: 0;
    }
  }
  &:not(.selecting) .container-panel {
    &:hover {
      color: #fffc;
      opacity: 1;
      border-color: rgba(255,255,255, 0.07);
      transition: none;      
    }
    &:active {
      opacity: 0.9;
      color: #fff8;
      border-color: rgba(255,255,255, 0.05);
      transition: none;      
    }
  }

  button.btn-back {
    position: absolute;
    top: 180px;
    left: 16px;
    width: 32px;
    height: 32px;
    outline: none;
    cursor: pointer;
    opacity: 0.5;
    background: url(./images/x-button.svg) no-repeat center;
    background-size: contain;
    border: 0;
    transition: 0.2s;
    &:hover {
      opacity: 0.6;
    }
    &:active {
      opacity: 0.4;
      transition: 0;
      transform: scale(0.9);
    }
  }

}
