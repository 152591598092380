.CNHHabilitacaoCarteira {

  width: 710px;

  p.message {
    padding: 0 100px 10px 100px;
    font-family: 'Share Tech';
    color: #DAA709;
    text-rendering: geometricPrecision;
    font-size: 18px;
  }

  .columns {
    display: flex;
    width: 100%;
    margin-top: 5px;

    .column {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
    }

    .column.photo {
      // pointer-events: none;
      cursor: pointer;

      flex-grow: 0;

      img {
        position: relative;
        display: block;
        width: 180px;
        height: auto;
        max-height: 240px;
        margin: 10px 0 0 0;
        background: #fff;
        transition: 0.3s;
      }

      &.empty img {
        height: 240px;
        background: rgba(255, 255, 255, 0.2);
      }
    }

    .column.info {
      cursor: default;
      flex-grow: 1;
      flex-direction: column;
    }
  }

  .row {
    cursor: default;

    display: flex;
    flex-grow: 1;

  }

  .row>.container-item {
    flex: 1;
   
    .item {
      width: 100%;
    }
  }

  .container-item {
    padding: 5px 10px 5px 0;
    text-rendering: geometricPrecision;

    &>.item {
      position: relative;
      display: inline-block;
      padding: 0 15px 5px 10px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-top: 0;
      border-right: 0;
      border-radius: 0 0 3px 3px;

      &.min80 {
        min-height: 80px;
      }

      label {
        display: block;
        margin: 0;
        margin-top: -8px;
        padding: 0 5px;
        font-family: 'Open Sans';
        font-size: 12px;
        color: rgba(255, 255, 255, 0.5);

        &:before {
          content: ' ';
          position: absolute;
          left: 0;
          top: 0px;
          width: 8px;
          height: 1px;
          background: rgba(255, 255, 255, 0.2);
        }
      }

      .break-words {
        white-space: normal;
      }

      .value {
        font-family: 'Share Tech';
        font-size: 17px;
        color: #fff;
      }
    }
  }

  .clickable {
    margin: 3px -15px 3px -9px;
    padding: 7px 50px 7px 9px;
    line-height: 1.2;
    cursor: pointer !important;
    background: rgba(255, 255, 255, 0.02) url(../../../../images/arrow-right.svg) no-repeat 96% center;
    background-size: 22px;
    border-top: 1px solid rgba(255, 255, 255, 0.04);
    border-bottom: 1px solid rgba(255, 255, 255, 0.04);
    transition: 0.1s;

    &.blocked {
      pointer-events: none;
      padding: 5px 0;
      margin: 0;
      background: none;
      border: 0;
    }

    .group-info p {
      cursor: pointer;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.03);
      background-position: 97% center;
    }

    &:active {
      opacity: 0.8;
      transition: none;
    }
  }

  //*****************************************************************************

  .picture {
    width: 200px;
    margin-bottom: 10px;
    margin-top: -5px;

    img {
      position: relative;
      display: block;
      width: 180px;
      height: auto;
      max-height: 240px;
      margin: 10px 0 0 0;
      background: #fff;
      transition: 0.3s;
    }

    &:active {
      transform: scale(0.98);
      transition: 0.1s;
    }

    // se não existe imagem, desativamos interação de click/zoom na imagem
    &.empty {
      //pointer-events: none;
      padding-top: 30px;
      height: 200px;

      img {
        width: auto;
      }
    }
  }
}

.CNHHabilitacaoCarteira.picture-mode {

  .column.photo {
    width: 100%;
  }

  .column.photo .picture {
    width: 450px;

    height: auto;
    margin: 0;
    display: flex;

    img {
      width: 450px;
      height: auto;
      max-height: none;
    }
  }

  .column.info {
    display: none;
    opacity: 0;
    pointer-events: none;
  }

}