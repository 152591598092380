
.SearchPanel-SearchBar {
  .search-bar {
    max-width: 800px;
    width: calc(100% - 40px);
    height: 59px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    overflow-x: hidden;
    white-space: nowrap;
    text-align: left;
    margin: 50px auto 20px auto;
    padding: 3px 20px;
    font-size: 22px;
    font-weight: lighter;
    outline: 0;
    color: rgba(255,255,255, 0.5);
    background: rgba(255,255,255, 0.1);
    border-radius: 5px;
    border: 2px solid rgba(255,255,255, 0);
    &.focus {
      color: #3B4046;
      background: #F6F8FA;
    }
    .flex-container {
      display: flex;
      .flex-item {
        flex: 1;
      }
    }

    .search-panel-icon-container {
      position: relative;
      margin-right: 8px;
      width: 24px;
      height: 24px;

      .icon {
        position: absolute;
        transition: .2s ease-out;
        
        &.search {
          top: 0;
          left: 0;
          width: 24px;
          height: 24px;
          opacity: 1;
          background: url(../../../images/icon-search2.svg) no-repeat 0px center;
          background-size: 24px;
          &.hidden {
            transform: rotate(-45deg);
            opacity: 0;
          }
        }
  
        &.clear {
          top: 0;
          left: 0;
          width: 24px;
          height: 24px;
          opacity: 0.5;
          background: url(../../../images/icon-search-home2.svg) no-repeat center top;
          background-size: 22px;

          &:hover {
            cursor: pointer;
            opacity: .8;
            &:active {
              opacity: .5;
              transition: none;
            }
          }
          &.hidden {
            cursor: default;
            transform: rotate(45deg);
            opacity: 0;
          }
        }
      }
    }

    input.input {
      width: 100%;
      padding: 8px 0 8px 8px;
      outline: 0;
      font-family: 'Share Tech';
      text-transform: uppercase;
      text-rendering: geometricPrecision;
      background: none;
      border: 0;
      &::placeholder {
        font-family: 'Roboto';
        font-size: 18px;
        font-style: italic;
        color: rgba(255,255,255, 0.15);
      }
    }
    .input-options {
      display: inline-block;
      .search-input-option {
        width: 150px;
        margin: 6px 0 6px 0;
        padding: 2px 0 2px 8px;
        outline: 0;
        font-family: 'Share Tech';
        text-transform: uppercase;
        text-rendering: geometricPrecision;
        background: none;
        border: 0;
        border-left: 1px solid rgba(255,255,255, 0.05);
        &::placeholder {
          font-family: 'Roboto';
          font-size: 18px;
          font-style: italic;
          color: rgba(255,255,255, 0.15);
        }
        &:first-child {
          width: 250px;
          border-left: 0;
        }
      }
    }
  }
  .search-bar-description {
    margin: -17px 0 0 0;
    text-align: center;
    font-family: 'Roboto';
    font-size: 12px;
    text-transform: uppercase;
    text-rendering: geometricPrecision;
    color: rgba(255,255,255, 0.2);
    transition: 0.1s;
  }
  .search-bar .filter-tag {
    display: inline-block;
    width: auto;
    display: inline-block;
    margin: 5px -100px 0 0;
    padding: 4px 14px;
    outline: 0;
    font-size: 14px;
    cursor: pointer;
    text-transform: uppercase;
    color: rgba(255,255,255, 0.8);
    background: rgba(0,0,0, 0);
    border: 2px solid rgba(255,255,255, 0.2);
    border-radius: 50px;
    button.x {
      display: inline-block;
      width: 18px;
      height: 18px;
      background: #fff;
      border: 0;
      opacity: 0.4;
      border-radius: 50px;
    }
  }

  .btn-search-history{
    button{
      background: #D6A536;
      color: #F6F8FA;
      font-family: Open sans, sans-serif;
      font-size: 0.875rem;
      font-weight: bold;
      border: 1px solid #D6A536;
      border-radius: 7px;
      display: flex;
      width: 240px;
      padding: 0.375rem 1.35rem;
      position: absolute;
      right: 9.5rem;
      justify-content: center;


      @media (mix-width: 1500px) {
        position: absolute;
        right: 0;
      }
      
    }
  }

  .btn-search-history-active{
    background: #3B4046;
    color: #D6A536;
    font-family: Open sans, sans-serif;
    font-size: 0.875rem;
    font-weight: bold;
    border: 1px solid #D6A536;
    border-radius: 7px;
    display: flex;
    width: 240px;
    padding: 0.375rem 1.35rem;
    position: absolute;
    right: 9.5rem;
    justify-content: center;


    @media (mix-width: 1500px) {
      position: absolute;
      right: 0;
    }
  }

  .btn-search-history:hover{
    cursor: pointer;
  }
}
