
.ChartRowsTotal {
  pointer-events: none;
  text-rendering: geometricPrecision;
}

.ChartRowsTotal .label {
  font-size: 13px;
  font-family: 'Roboto';
  line-height: 1;
  color: #b4bcbf;
}

.ChartRowsTotal .total {
  line-height: 1.2;
  padding-bottom: 9px;
  font-size: 28px;
  font-family: 'Share Tech';
  color: rgba(255,255,255, 0.9);
}
