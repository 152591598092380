.MenuBar-container {
  display: flex;
  flex-direction: column;

  padding-right: 30px;


  .loading {
    min-width: 200px;
    padding: 5px 50px 5px 20px;
    font-family: 'Share Tech';
    font-size: 18px;
    font-weight: lighter;
    text-rendering: geometricPrecision;
    color: #fff;
    opacity: 1;
    background: #3B4046;
    border: 2px solid #B5B7B9;
    border-radius: 10px;

    animation: Loading-animate infinite 0.3s ease alternate;
    animation-delay: 0.1s;
  }

  .pagination-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 10px;

    gap: 5px;
    color: rgba(255, 255, 255, 0.7);

    .active-arrow {
      svg {
        path {
          stroke: #dba70a;
        }
      }
    }

    .inactive-arrow {
      svg {
        path {
          stroke: #A5A5A5;
        }
      }

      pointer-events: none;
    }
  }

  .hidden {
    display: none;
  }


  .MenuBar-veiculos {
    min-width: 400px;
    max-width: 740px;
    padding: 0 20px;
    background: rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    border-bottom-color: #dba70a;


    //MODIFICANDO
    overflow-x: auto;
    overflow: hidden;


    button {
      display: inline-block;
      vertical-align: top;
      width: 120px;
      padding: 7px 0 5px 0;
      font-family: 'Share Tech';
      font-size: 18px;
      letter-spacing: 1px;
      text-rendering: geometricPrecision;
      text-align: left;
      line-height: 1.1;
      color: #fff;
      cursor: pointer;
      background: none;
      outline: 0;
      border: 0;
      opacity: 0.6;
      transition: 0.1s;

      &:hover {
        opacity: 0.7;
      }

      &:active {
        opacity: 0.6;
        transform: translate(1px, 1px);
        transition: none;
      }

      div {
        font-family: 'Roboto';
        font-size: 13px;
        letter-spacing: 0;
        color: rgba(255, 255, 255, 0.7);
      }
    }

    button.selected {
      opacity: 0.9;
    }

    .select-line-veiculos {
      pointer-events: none;
      width: calc(120px - 20px);
      // width: 100px;
      height: 3px;
      background: #dba70a;
      transition: 0.1s;
    }

    .buttons-veiculos {
      display: flex;
      // width: 100%;
      // margin: 0 20px 0 0;
      // gap: 10px;


      button {
        display: block;
        width: 120px;
        line-height: 1.1;
        text-align: left;
        cursor: pointer;
        outline: 0;
        margin: 0;

        padding: 7px 20px 7px 0;


        //MODIFICANDO
        padding: 7px 0;
        //margin-right: 20px;


        text-rendering: geometricPrecision;
        color: rgba(255, 255, 255, 0.5);
        // background: none no-repeat 90% center;
        // background-size: 22px;
        border: 0;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;

        &.stolen {
          background-image: url(../../../../images/icon-alert.svg);
          background-color: rgba(0, 0, 0, 0.03);
          border-top-color: rgba(255, 255, 255, 0.05);
          border-bottom-color: rgba(255, 255, 255, 0.05);
        }

        span {
          font-family: 'Share Tech';
          font-size: 17px;
        }

        div {
          font-family: 'Roboto';
          font-size: 12px;
          opacity: 0.8;

          //MODIFICANDO
          width: 120px;
        }

        &.selected {
          cursor: default;
          pointer-events: none;
          color: #fff;
        }

        &:hover {
          color: rgba(255, 255, 255, 0.8);
        }

        &:active {
          color: rgba(255, 255, 255, 0.8);
          opacity: 0.8;
        }
      }
    }
  }
}