.Loading {
  min-width: 200px;
  padding: 5px 50px 5px 20px;
  font-family: 'Share Tech';
  font-size: 18px;
  font-weight: lighter;
  text-rendering: geometricPrecision;
  color: #fff;
  opacity: 1;
  background: #3B4046;
  border: 2px solid #B5B7B9;
  border-radius: 10px;

  animation: Loading-animate infinite 0.3s ease alternate;
  animation-delay: 0.1s;

  &.warn {
    animation: Loading-animate-close infinite 0.5s ease alternate;
    color: #f1e7d0;
    background: #af9553;
    opacity: 0.7;
  }

  &.error {
    animation: Loading-animate-close infinite 0.5s ease alternate;
    color: #f3c9c1;
    background: #ca594a;
    opacity: 0.7;
  }
}

.Loading.hidden {
  visibility: hidden;
}

@keyframes Loading-animate {
  from {
    opacity: 0.6
  }

  to {
    opacity: 0.9
  }
}

@keyframes Loading-animate-close {
  from {
    opacity: 0.7
  }

  to {
    opacity: 0.9
  }
}