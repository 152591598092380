
.Map {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  outline: 0;
  opacity: 0;
  transition: 1s;
  transform: scale(1.2);
  transform-origin: center top;
  &.visible {
    opacity: 1;
    transform: scale(1);
  }
  &.blur {
    filter: blur(3px);
  }
  .Loading {
    position: absolute;
    z-index: 99;
    right: 10px;
    bottom: 20px;
  }
  &.is-loading .mapboxgl-ctrl.mapboxgl-ctrl-group {
    pointer-events: none;
    opacity: 0;
    transition: 0.2s;
  }
}

.Map {
  &.selection {
    cursor: default;
  }
  .map-draw-selection {
    user-select: none;
    pointer-events: none;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.3s;
  }
  &.selection .map-draw-selection {
    opacity: 1;
  }
  .map-draw-cancel {
    position: fixed;
    z-index: 999999;
    bottom: 71px;
    right: 5%;
    width: 150px;
    height: 41px;
    padding: 1px 0 0 1px;
    background: #48403288;
    border-radius: 2px;
    transition: 0.2s;
    opacity: 0;
    &.visible {
      opacity: 1;
    }
    button.btn-cancel {
      user-select: none;
      width: 150px;
      height: 39px;
      cursor: pointer;
      outline: 0;
      color: #fffc;
      background: #fff3 url(../../images/cancel-x.svg) no-repeat 85% center;
      background-size: 14px;
      border: 0;
      border-radius: 2px;
      opacity: 0.3;
      span {
        pointer-events: none;
        display: inline-block;
        padding-right: 15px;
        font-size: 13px;
        text-rendering: geometricPrecision;
      }
      &:hover {
        color: #fff;
        background-color: #ffffff3a;
        &:active {
          opacity: 0.2;
        }
      }
    }
  }
  svg {
    .circle {
      fill: rgba(0,0,0, 0.15);
      stroke: rgba(255,255,255, 0.5);
      stroke-width: 1;
    }
    .line {
      stroke: rgba(255,255,255, 0.3);
      stroke-width: 1;
      stroke-dasharray: 5;
    }
  }
  div.map-draw-selection {
    .point-center {
      position: absolute;
      width: 8px;
      height: 8px;
      margin: -4px 0 0 -4px;
      background: #fff;
      border-radius: 50%;
      &:after {
        position: absolute;
        content: ' ';
        width: 30px;
        height: 30px;
        margin: 4px 0 0 4px;
        background: #fff;
        border-radius: 50%;
        animation: drawCircleSelection 0.4s ease infinite alternate;
      }
      &.hidden {
        display: none;
      }
    }
    .tip {
      pointer-events: none;
      position: fixed;
      bottom: 50px;
      left: 0;
      width: 100%;
      padding: 30px 50px;
      font-family: 'Roboto';
      font-size: 18px;
      line-height: 1;
      text-rendering: geometricPrecision;
      text-align: center;
      color: rgba(255,255,255, 0.3);
      background: #3d362bdd;
      border-top: 1px solid #fff2;
      border-bottom: 1px solid #fff2;
    }
    .text {
      position: absolute;
      padding: 2px 10px;
      font-size: 12px;
      text-align: center;
      color: rgba(255,255,255, 0.8);
      border-radius: 5px;
      transform: translate(-50%,-50%);
      &.hidden {
        opacity: 0;
        transition: 0.2s;
      }
    }
    .shape {
      position: absolute;
      border-radius: 50%;
      top: -100px;
      border: 5px solid transparent;
      border-top-color: #fff;
      border-bottom-color: #fff;
      transform: translate(-50%,-50%);
      transition: 1s transform, 1s opacity;
      &.shape.a { opacity: 0.3; border-width: 3px; }
      &.shape.b { opacity: 0.5; border-width: 5px; border-top-color: transparent; }
      &.shape.c { opacity: 1.0; border-width: 9px; border-top-color: transparent; border-bottom-color: #daa73f; }
    }
  }
}

.Map canvas {
  transition: 0.3s;
  &.selection {
    pointer-events: none;
    filter: sepia(100%);
  }
}

@keyframes drawCircleSelection {
  from { opacity: 0.6; transform: translate(-50%,-50%) scale(0.6) }
  to   { opacity: 0.1; transform: translate(-50%,-50%) scale(1.0) }
}

body.no-header .Map .mapboxgl-control-container {
  opacity: 0;
}

.Map .mapboxgl-ctrl-bottom-right,
.Map .mapboxgl-ctrl-bottom-left {
  position: fixed;
  bottom: -1000px;
}
.Map .mapboxgl-ctrl-bottom-right {
  bottom: -22px;
}

.Map .mapboxgl-ctrl.mapboxgl-ctrl-group {
  border-radius: 0;
  box-shadow: none;
  background: #606a6fc7;
  transition: 0.2s;
  transform: translate(0,-5px);
  &:hover {
    transition: none;
  }
  & > button + button {
    border-top-color: #fff1;
  }
  .mapboxgl-ctrl-icon {
    background-size: 24px;
    background-position: 3px;
    filter: invert(0.7);
    &:hover {
      background-color: #68727744;
      &:active {
        opacity: 0.5;
      }
    }
  }
}

