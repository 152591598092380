.image-viewer__container {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  z-index: 10000000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.image-viewer__frame img {
  min-height: 75vh;
}

.image-viewer__label {
  padding: 5px 10px;
  text-rendering: geometricPrecision;
  text-transform: uppercase;
  font-size: 12px;
  color: rgba(255,255,255, 0.7);
  background: rgba(0,0,0, 0.5);
  white-space: normal;
  max-width: 50%;
  text-align: center;
  line-height: 1.5;
  position: absolute;
  bottom: 80px;
}