.help-container {

    .HelpPanelBackground {
        position: fixed;
        z-index: 999996;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        opacity: 0.7;
        background: rgba(255, 255, 255, 0.7);
        transition: 0.2s ease 0.05s;

        &.hidden {
            pointer-events: none;
            opacity: 0;
            transition: 0;
        }
    }

    .HelpPanel {
        position: fixed;
        z-index: 999999;
        top: 100px;
        left: 50%;
        width: 75vw;

        //ALTERADO
        //height: 75vw;
        height: 69vh;
        max-width: 1100px;
        text-rendering: geometricPrecision;

        background: #3B4046;

        //border: 1px solid #F6F8FA;
        border-radius: 10px;

        transform: translate(-50%, 0);
        transition: 0.3s;

        ::-webkit-scrollbar-track {
            margin-top: 3rem;
        }

        &:before {
            display: block;
            position: absolute;
            content: ' ';
            z-index: -1;
        
        
            margin: -15px 30px;
            width: calc(75vw - 60px);
            height: calc(69vh + 30px);
            max-width: 1040px;
        
            background: #3B4046;
            border-radius: 10px;
        
            transition: 0.1s;
            opacity: 0.6;
          }

        &.hidden {
            pointer-events: none;
            opacity: 0;
            transform: translate(-50%, 10px);
            transition: 0.2s;
        }

        button.btn-close {
            float: right;
            margin: 10px 15px 0 0;
            width: 22px;
            height: 22px;
            cursor: pointer;
            outline: 0;
            background: rgba(0, 0, 0, 0) url(../../images/cancel-x.svg) no-repeat center;
            background-size: 14px;
            border: 0;
            border-radius: 50%;
            opacity: 0.3;

            &:hover {
                opacity: 0.4;
            }

            &:active {
                opacity: 0.2;
            }

            span {
                display: inline-block;
                padding-right: 10px;
                font-size: 13px;
                color: rgba(255, 255, 255, 0.6);
                text-rendering: geometricPrecision;
                transform: translate(-38px, 0);
            }
        }

        .container-content {

            //ALTERADO
            //height: calc(100% - 80px - 50px);
            height: calc(100% - 20px);
            overflow-y: auto;
            padding: 2.75rem 3.875rem 3.875rem 4.5rem;
        }

        .loading {
            width: 100%;
            height: 100px;
            background: url(../../images/icon-horizontal-loading-dark.gif) no-repeat center;
            opacity: 0.7;
        }

        .error-message {
            margin: 50px 0 0 0;
            padding: 20px 30px 60px 30px;
            font-size: 18px;
            text-align: center;
            text-rendering: geometricPrecision;
            color: #c7c6bc;
            background: #5f696a;
            border: 3px solid #c5ab77;
            border-left: 0;
            border-right: 0;

            .icon {
                display: block;
                height: 120px;
                opacity: 0.2;
                background: url(../../images/icon-notification-warn.svg) no-repeat center;
                background-size: 75px;
            }
        }

    }

    .container-title {

        .main-title {
            font-family: Share Tech, sans-serif;
            font-size: 3rem;
            color: #F6F8FA;
            display: flex;
            flex-direction: row;
        }

        .yellow-detail {
            width: 7.3rem;
            height: 3px;
            background: #CA9D40;
            display: flex;
            position: relative;
            top: -0.75rem;
        }
    }

    .questions-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        margin: 0.875rem 0 0.875rem 0;

        justify-content: space-between;

        .questions-item {

            border-bottom: 0.4px solid #F6F8FA;
            padding: 1rem 0;
            display: flex;
            justify-content: space-between;

            font-family: Roboto, sans-serif;
            font-weight: 500;
            color: #F6F8FA;

        }
    }

    .pagination-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        div:first-child {
            font-family: Roboto;
            font-size: 0.75rem;
            line-height: 1.75rem;

            display: flex;
            align-items: center;
            letter-spacing: 0.15px;

            color: #F6F8FA;
        }

        .pages {
            margin-left: 0.5rem;
            line-height: 2;

            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 0.75rem;

            display: flex;
            align-items: center;
            letter-spacing: 0.15px;

            color: #CA9D40;
        }

        svg {

            margin-left: 0.5rem;
            position: relative;
            top: 3px;
        }
        svg:hover {
            cursor: pointer;
        }

        .right-arrow {
            margin-right: 0;

            svg {
                stroke: #F6F8FA; 
            }
        }
    }

}