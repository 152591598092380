.CardConfig {
	width: 100%;
	display: flex;
	flex-direction: column;

	.loading {
		width: 100%;
		height: 40px;
		background: url(../../../images/icon-horizontal-loading-dark.gif) no-repeat center;
		opacity: 0.7;
	}

	.title {
		margin: 10px 0;
		align-self: center;
		font-family: Share Tech, sans-serif;
		font-size: 1.5rem;
		color: #CAD1D4;

		&.sub {
			margin: 40px 0 20px 0;
			font-size: 1rem;
		}
	}

	.export-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		.success-feedback {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			width: 100%;
			font-family: Roboto, sans-serif;
			font-size: 14px;
			color: rgba(255, 255, 255, 0.7);
			background-color: #868f3c70;
			padding: 5px 10px;
			margin-bottom: 20px;

			.success-icon {
				display: flex;
				margin-right: 8px;
				margin-top: -1px;
				width: 20px;
				height: 20px;
				opacity: 0.6;
				background: url(../../../images/check-mark.svg) no-repeat center;
			}
		}
	}

	.input-container {
		display: flex;
		flex-direction: column;

		input {
			outline: 0;
			padding: 10px;
			border-radius: 5px;
			border: 2px solid #CAD1D4;
			color: #CAD1D4;
			font-size: 18px;
			background-color: transparent;
		}

		label {
			font-size: 1rem;
			font-family: Share Tech, sans-serif;
			color: #CAD1D4;
			margin-left: 10px;
			padding: 0 10px;
			transform: translateY(50%);
			width: fit-content;
			background-color: #3B4046;
		}
	}

	.legends-area {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		font-family: Share Tech, sans-serif;
		color: #CAD1D4;

		span {
			height: 16px;
			width: 16px;
			border-radius: 50%;
			display: inline-block;
			margin-bottom: -2px;
		}

		label {
			margin-right: 8px;
		}
	}

	.buttons-container {
		width: 600px;

		:last-child {
			margin-left: 10px;
		}
	}

	.button {
		cursor: pointer;
		padding: 4px 8px;
		border-radius: 5px;
		border: none;
		font-size: 0.9rem;
		font-family: Roboto, sans-serif;
		font-weight: bold;
		color: #CAD1D4;
		background-color: #606060;

		&.main {
			padding: 6px 12px;
			color: #ffffff;
			font-size: 1rem;
			background-color: #dba70a;
		}
	}

	.checkboxes {
		display: flex;
		width: 600px;
		margin: 10px 0 60px 0;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.error {
		margin: 10px 0px;
		width: 100%;
		color: #dba70a;

		&.input {
			margin-top: 5px;
			margin-bottom: 0px;
		}
	}

	.checkbox-container {
		display: flex;
		align-items: center;
		min-width: 200px;
		margin-top: 10px;

		input {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;

			margin: 0;
			width: 18px;
			height: 18px;
			margin-right: 6px;
			border-radius: 2px;
			background-color: #ffffff;
			cursor: pointer;

			&:checked {
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;

				width: 18px;
				height: 18px;
				background: #dba70a url(../../../images/check-mark.svg) no-repeat center;
				background-size: 13px;
			}
		}

		label {
			font-size: 0.9rem;
			font-family: Roboto, sans-serif;
			font-weight: bold;
			color: #CAD1D4;
			cursor: pointer;
		}
	}

	.container-btn-back {
		position: absolute;
		margin-top: 10px;
		border-radius: 5px;
		color: #fff;
		background: rgba(0, 0, 0, 0.1);

		button.btn-back {
			vertical-align: top;
			padding: 3px 0 3px 16px;
			margin: 0;
			outline: 0;
			cursor: pointer;
			background: rgba(255, 255, 255, 0);
			border: none;
			opacity: 0.8;
			transition: 0.1s;

			&:hover {
				opacity: 0.9;
				border-radius: 5px;
				background: rgba(255, 255, 255, 0.05);
			}

			&:active {
				opacity: 0.5;
				border-radius: 5px;
				background: rgba(255, 255, 255, 0.03);
				transition: none;
			}

			.icon-left {
				display: inline-block;
				vertical-align: top;
				width: 28px;
				height: 28px;
				background: url(../../../images/arrow-left-icon.svg) no-repeat center;
				background-size: 18px;
			}

			.label {
				display: inline-block;
				vertical-align: top;
				padding: 5px 20px 0 2px;
				font-family: 'Roboto';
				font-size: 12px;
				color: rgba(255, 255, 255, 0.8);
			}
		}
	}
}

.resultsMenu {
	display: flex;
	flex-direction: column;
	position: absolute;
	font-family: Share Tech, sans-serif;
	color: #CAD1D4;
	top: 60px;
	background-color: transparent;
	min-width: 160px;

	h3 {
		font-size: 1.2rem;
		color: #CAD1D4;
	}

	ul {
		list-style: none;

	}

	li {
		display: flex;
		flex-direction: column;
		position: relative;
		border: 1px solid #CAD1D4;
		padding: 10px;
		background-color: #3B4046;

	}

	.removeBtn {
		background-color: #3B4046;
		border: none;
		position: absolute;
		right: 4px;
		padding: 4px;
		width: 30px;
		top: -14px;
		cursor: pointer;
	}

	.removeBtn:hover {
		background-color: #AF8826;
	}

	.dropIcon {
		position: absolute;
		left: 90px;
		cursor: pointer;
	}

	.dropIcon img {
		padding: 4px;
	}

	.dropIcon :hover {
		background-color: #5C6065;
	}
}

.infoCardContent {
	display: flex;
	flex-direction: column;
	position: absolute;
	font-family: Share Tech, sans-serif;
	color: #CAD1D4;
	top: 60px;
	right: 10px;
	max-width: 240px;
	border: 1px solid #CAD1D4;
	padding: 10px;
	z-index: 99999;
	background-color: #3B4046;

	h3 {
		font-size: 1.2rem;
		color: #CAD1D4;
	}

	div {
		white-space: pre-line;
	}

	.removeBtn {
		background-color: #3B4046;
		border: none;
		position: absolute;
		right: 4px;
		padding: 4px;
		width: 30px;
		top: -14px;
		cursor: pointer;
	}

	.removeBtn:hover {
		background-color: #AF8826;
	}
}