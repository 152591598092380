
.ui-list {
  .ReactVirtualized__List {
    padding: 10px 0;
    outline: 0;
  }
  padding: 0 0 10px 0;
  margin: 0 -20px -20px -20px;
  max-height: 450px;
  overflow-y: auto;
  .row {
    padding: 5px 20px;
    line-height: 1.2;
    cursor: default;
    color: rgba(255,255,255, 0.7);
    text-rendering: geometricPrecision;
    &.pointer, .pointer {
      cursor: pointer;
    }
    &:hover {
      background: rgba(255,255,255, 0.05);
      &:active {
        opacity: 0.5;
      }
    }
  }

  .column {
    display: inline-block;
  }

  .row.header {
    pointer-events: none;
    padding-bottom: 7px;
    margin-bottom: 2px;
    text-transform: uppercase;
    letter-spacing: 1px;
    background: #ffffff07;
    color: #fff7;
    .column {
      font-family: 'Roboto';
      font-weight: bold;
      font-size: 12px;
    }
  }

}
