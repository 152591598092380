.CardCadTeamsDetails {
  position: relative;
  top: 0;
  transition: 0.4s;
  text-transform: uppercase;

  &.hidden {
    transition: none;
    height: 0;
    opacity: 0;
    transform: translate(0, 10px);
  }

  .card-title span {
    display: inline-block;
    padding-left: 5px;
    opacity: 0.5;
  }

  .content {
    display: inline-block;
    vertical-align: top;
    width: 100%;

    .block-title {

      margin: 16px -16px 0 -64px;
      padding: 6px 42px 6px 84px;
      background-color: #FFF2;
      transform: skew(45deg);
      max-width: fit-content;

      .block-title-text {
        text-transform: uppercase;
        font-size: 12px;
        opacity: 1;
        font-weight: bold;
        font-family: 'Roboto';
        color: #b4bcbf;
        transform: skew(-45deg);
      }
    }

    .block-title-container {
      margin: 0 -20px;
      margin-bottom: 8px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      &:first-child {
        margin-top: -16px;
      }

      .block-title {

        margin: 16px -16px 0 -64px;
        padding: 6px 42px 6px 84px;
        background-color: #FFF2;
        transform: skew(45deg);
        max-width: fit-content;

        .block-title-text {
          text-transform: uppercase;
          font-size: 12px;
          opacity: 1;
          font-weight: bold;
          font-family: 'Roboto';
          color: #b4bcbf;
          transform: skew(-45deg);
        }
      }
    }

    .plate-button {
      display: flex;
      align-items: center;
      letter-spacing: 0;
      margin-right: 20px;
      font-family: 'Share Tech';
      font-size: 16px;
      color: #fff;
      opacity: 0.75;
      cursor: pointer;
      background-size: 0;
      border-bottom: 1px solid rgba(255,255,255, 0.5);
      transition: .2s;
  
      .plate-button-image {
        margin-left: -10px;
        margin-right: 0px;
        width: 12px;
        height: 12px;
        background:url(../../../../images/icon-search-16x16.svg) no-repeat 0 center;
        background-size: 12px;
        opacity: 0;
        transition: .2s;
      }
  
      &:hover {
        border-bottom-color: #ccb64a;
        opacity: 0.8;
        margin-right: 0;
  
        .plate-button-image {
          margin-left: 8px;
          margin-right: 2px;
          opacity: 1;
        }
      }
      &:active {
        border-bottom-color: #ccb64a;
        opacity: 0.6;
      }
    }



    .agent-name-container {

      display: flex;
      align-items: center;
      min-width: 230px;

      .leader-indication {
        margin-left: 2px;
        margin-bottom: 2px;
        margin-right: 20px;
        opacity: 0;
        width: 14px;
        height: 14px;
        background: url('../../../../images/star.svg') no-repeat center;
        background-size: 14px 14px;
        transition: .2s;
        pointer-events: none;

        &.visible {
          opacity: 0.9;
          pointer-events: all;
        }

        .leader-tooltip {
          background: #E7E7E7;
          color: #2C383E;
          font-size: 0.625rem;
          font-family: Roboto, sans-serif;
          border-radius: 2px;

          white-space: initial;

          padding: 0.5rem;
          position: absolute;
          top: 150px;
          left: 192px;
        }

        .leader-tooltip::after {
          content: " ";
          position: absolute;
          top: 100%;
          left: 8%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: #E7E7E7 transparent transparent transparent;
        }

        .leader-tooltip-hidden {
          pointer-events: none;
          opacity: 0;
          display: none;
        }
      }


      .agent-name {
        display: flex;
        align-items: center;
        letter-spacing: 0;
        margin-right: 20px;
        font-family: 'Share Tech';
        font-size: 16px;
        color: #fff;
        opacity: 0.75;
        cursor: pointer;
        line-height: 1;
        background-size: 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        transition: .2s;

        .plate-button-image {
          margin-left: -45px;
          margin-right: 0;
          width: 12px;
          height: 12px;
          background: url(../../../../images/icon-search-16x16.svg) no-repeat 0 center;
          background-size: 12px;
          opacity: 0;
          transition: .2s;
        }
        .plate-button-image-leader{
          margin-left: -30px;
          width: 12px;
          height: 12px;
          background: url(../../../../images/icon-search-16x16.svg) no-repeat 0 center;
          background-size: 12px;
          opacity: 0;
          transition: .2s;
        }


        &:active {
          border-bottom-color: #ccb64a;
          opacity: 0.6;
        }
      }

      &:hover {
        .agent-name {
          border-bottom-color: #ccb64a;
          opacity: 0.8;
          margin-right: 0;

          .plate-button-image {
            margin-left: -33px;
            margin-bottom: 2px;
            margin-right: 2px;
            opacity: 1;
          }
          .plate-button-image-leader {
            margin-left: -15px;
            margin-bottom: 2px;
            margin-right: 2px;
            opacity: 1;
          }
        }
      }
    }
  }

  .line-values {
    display: flex;
    padding: 3px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: -3px;
    margin-bottom: 5px;

    .item {
      flex: 1;
      padding-right: 20px;
      text-rendering: geometricPrecision;

      .label {
        text-transform: uppercase;
        font-size: 12px;
        font-family: 'Roboto';
        color: #b4bcbf;
        opacity: 0.9;
      }

      .value {
        display: block;
        line-height: 1;
        font-size: 20px;
        font-family: 'Share Tech';
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.7);
      }
    }

    .item.max-width {
      flex-grow: 9;
    }
  }

  .line-values.text {
    .value {
      min-width: 50px;
      font-size: 16px;
    }
  }

  .line-values.no-border {
    border: 0;
  }

  .line-values.inline {
    display: block;
    width: 100%;

    .item {
      vertical-align: top;
      display: inline-block;
    }
  }

  .line-values.limited {
    max-width: 500px;
    word-wrap: normal;
    white-space: normal;
  }

  .line-values.spaced {
    margin-top: 8px;
    padding-top: 8px;
  }

  .team-in-atendance {
    height: 11px;
    width: 11px;
    margin-top: 2px;
    border-radius: 100%;
    border: solid 1px #FFFA;

    &.active {
      background-color: greenyellow;
      animation: in-attendance-active 1s linear infinite;
      border: none;
    }
  }

  .card-table-container {
    display: flex;
    align-items: flex-start;

    .card-table-column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: #fff;
      font-size: 14px;
      opacity: 0.8;
      cursor: default;
      margin-right: 20px;

      &.center {
        align-items: center;
      }

      .card-table-column-title {
        font-size: 12px;
        font-family: 'Roboto';
        color: #b4bcbf;
        opacity: 0.9;
      }

      .card-table-cell {
        height: 24px;
        display: flex;
        align-items: center;

        .agent-name {
          display: flex;
          align-items: center;
        }
      }
    }
  }


}