
.Thermometer .Tooltip {
  user-select: none;
  position: absolute;
  z-index: 9;
  min-width: 350px;
  min-height: 430px;
  width: auto;
  padding: 5px 5px 5px 400px;
  text-rendering: geometricPrecision;
  color: #fff;
  background: rgba(83,95,101, 0.9);
  border: 1px solid #cad1d4;
  border-radius: 2px;
  transition: 0.2s all, 0.6s opacity;
  &.hidden {
    opacity: 0;
    pointer-events: none;
    transform: translate(-10px,0);
    transition-timing-function: linear;
    transition-duration: 0.1s;
  }

  .SwitcherOnOff {
    background: #eeeeee3b !important;
    div {
      background: #ccc !important;
    }
  }

  .minimizer {
    float: right;
    width: 15px;
    height: 140px;
    margin: 134px -21px 0 0;
    cursor: pointer;
    background: #6a7478aa url(../../images/arrow-left-simple.svg) no-repeat center;
    background-size: 10px;
    border-radius: 0 3px 3px 0;
    border: 1px solid #cad1d48f;
    border-left: 0;
    opacity: 0.6;
    transition: 0.1s;
    &:hover {
      opacity: 0.9;
      background-color: #6a7478bb;
      &:active {
        opacity: 0.8;
        background-color: #6a747899;
      }
    }
  }

  .columns {
    padding-bottom: 5px;
    letter-spacing: -1px;
    font-family: 'Share Tech';
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    color: #ddd;
    & > div {
      display: inline-block;
      padding: 0 10px;
      width: 50%;
      vertical-align: top;
    }
    .valor {
      width: calc(100% - 150px);
      text-align: left;
      font-weight: bold;
      font-size: 20px;
      text-transform: uppercase;
    }
    .status {
      width: 150px;
      text-align: right;
      font-weight: bold;
      font-size: 20px;
      transform: translate(0,3px);
      span {
        display: inline-block;
        padding-left: 10px;
        opacity: 0.7;
      }
    }
  }
  .nome {
    padding: 5px 10px;
    margin: 0 -5px 10px -5px;
    font-size: 14px;
    text-transform: uppercase;
    color: rgba(255,255,255, 0.7);
    background: #6a7478aa;
  }

  .settings {
    text-align: center;
    & > span {
      display: inline-block;
      vertical-align: middle;
      padding-right: 10px;
      font-size: 16px;
      color: #eee;
      font-family: 'Roboto';
      text-rendering: geometricPrecision;
      opacity: 0.5;
      transition: 0.2s;
      &.enabled {
        opacity: 1;
      }
    }
    & > div {
      display: inline-block;
    }
  }

  .group-info {
    white-space: nowrap;
    margin: -10px -5px 0 -5px;
    padding: 5px 0;
    background: #4f5c61cc;
    border-bottom: 1px solid rgba(255,255,255, 0.1);
    .column {
      display: inline-block;
      padding: 5px 10px 7px 20px;
      text-align: left;
      line-height: 1.3;
      label {
        font-family: 'Roboto';
        font-size: 12px;
        text-transform: uppercase;
        text-rendering: geometricPrecision;
        color: #fffa;
      }
      div {
        line-height: 1.2;
        font-size: 18px;
        font-family: 'Share Tech';
        text-transform: uppercase;
        text-rendering: geometricPrecision;
        color: #fff;
        opacity: 0.8;
      }
    }
  }

  .charts {
    width: 600px;
    overflow: hidden;
    white-space: nowrap;
    .chart-container {
      display: inline-block;
      position: relative;
      left: 0;
      top: 0;
      transition: 0.3s;
      &.acumulado {
        margin-left: -610px;
      }
    }
    &.left {
      .chart-container.absoluto {
        transform: translate(-10px,0);
        opacity: 0;
        pointer-events: none;
      }
      .chart-container.acumulado {
        transform: translate(0,0);
        opacity: 1;
      }
    }
    &.right {
      .chart-container.absoluto {
        transform: translate(0,0);
        opacity: 1;
      }
      .chart-container.acumulado {
        transform: translate(+10px,0);
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  .footer {
    margin: -15px 0 5px 0;
    font-family: 'Roboto';
    font-size: 12px;
    text-rendering: geometricPrecision;
    text-align: center;
    letter-spacing: 2px;
    color: #fff8;
  }
}

