
.CardCadOccurrenceDetails {
  position: relative;
  top: 0;
  transition: 0.4s;

  &.hidden {
    transition: none;
    height: 0;
    opacity: 0;
    transform: translate(0,10px);
  }

  .card-title {
    opacity: 1;
  }

  .card-title span {
    display: inline-block;
    padding-left: 8px;
    opacity: 0.5;
    text-transform: uppercase;
  }

  .content {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    
    .block-title-container {
      pointer-events: none;
      margin: 0 -20px;
      margin-bottom: 8px;
      border-bottom: 1px solid rgba(255,255,255, 0.1);

      &:first-child {
        margin-top: -16px;
      }

      .block-title {

        margin: 16px -16px 0 -64px;
        padding: 6px 42px 6px 84px;
        background-color: #FFF2;
        transform: skew(45deg);
        max-width: fit-content;

        .block-title-text {
          text-transform: uppercase;
          font-size: 12px;
          opacity: 1;
          font-weight: bold;
          font-family: 'Roboto';
          color: #b4bcbf;
          transform: skew(-45deg);
        }
      }
    }
  }

  .line-values {
    display: flex;
    padding: 3px 0;
    border-top: 1px solid rgba(255,255,255, 0.1);
    margin-top: -3px;
    margin-bottom: 5px;
    &:first-child {
      padding-top: 8px;
    }
    .item {
      display: flex;
      flex-direction: column;
      padding-right: 48px;
      text-rendering: geometricPrecision;
      &:last-child {
        padding-right: 0;
      }
      > :first-child {
        margin-right: 4px;
      }
      .label {
        text-transform: uppercase;
        font-size: 12px;
        font-family: 'Roboto';
        color: #b4bcbf;
        opacity: 0.9;
      }
      .value {
        display: block;
        line-height: 1;
        font-size: 20px;
        font-family: 'Share Tech';
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.7);
      }

    }
    .item.max-width {
      flex-grow: 9;
    }
  }
  .line-values.text {
    .value {
      min-width: 50px;
      font-size: 16px;
    }
  }
  .line-values.no-border {
    border: 0;
  }
  .line-values.inline {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 0;
    .item {
      flex-direction: row;
    }
    .label {
      text-transform: none;
      width: 80px;
      text-align: right;
      padding-right: 4px;
    }
  }

  .line-values.limited {
    max-width: 500px;
    word-wrap: normal;
    white-space: normal;
  }

  .line-values.spaced {
    margin-top: 8px;
    padding-top: 8px;
  }

  .line-values.centered {
    justify-content: center;
  }

  .line-values.background {
    background-color: #0001;
    padding: 8px 0px 12px 0px;
    margin-top: 8px;
    margin-bottom: 0;
  }

  .line-values.note {
    .item {
      background-color: rgba(193, 179, 101, 0.2);
      > :first-child {
        color: #ffce0a;
      }
    }
  }

  .cad-occurrence-note__container {
    pointer-events: none;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding: 10px;
    color: rgba(255,255,255, 0.8);
    background: rgba(193, 179, 101, 0.2);
    text-transform: uppercase;
    opacity: 0.7;

    .cad-occurrence-note__title {
      display: flex;
      font-size: 12px;
      font-family: 'Roboto';
      text-rendering: geometricPrecision;
      .cad-occurrence-note__group-name {
        margin-right: 4px;
        opacity: 0.7;
      }
      .cad-occurrence-note__user-name {
        margin-left: 4px;
        color: #ffce0a;
      }
    }
    .cad-occurrence-note__content {
      font-size: 16px;
      white-space: normal;
      font-family: 'Share Tech';
      line-height: 1;
    }
  }
}
