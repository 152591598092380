
.HeaderLeftParceiros {
    position: fixed;
    z-index: 9999999;
    top:   34px;
    left: 200px;
    transition: 0.2s;
    
    .logo {
        display: inline-block;
        width: 29px;
        height:35px;
        margin-left: 12px;
        border: 0;
        opacity: 0;
        transform: scale(0.5);
        transition: 0.2s;
        &.logo1 { background: url(../../../images/logo-ce.png)    }
        &.logo2 { background: url(../../../images/logo-sspds.png) }
        &.logo3 { background: url(../../../images/logo-supesp.png) }

    }
    &.visible .logo {
        cursor: pointer;
        text-decoration: none;
        opacity: 0.9;
        transform: scale(1);
        b {
            visibility: hidden;
        }
        &:active {
            opacity: 0.5;
            transition: none;
        }
        &.logo1 { transition-delay: 0.1s }
        &.logo2 { transition-delay: 0.2s }
        &.logo3 { transition-delay: 0.3s }
    }
}

body.is-search-focus .HeaderLeftParceiros {
    transform: translate(-10px,0);
}
