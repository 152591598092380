.SubHeaderRight {
    position: fixed;
    top:   0px;
    left: -200px;
    height: 80px;
    display: flex;
    padding: 20px;
    justify-content: flex-end;
    background: url(../../../../images/layout-header-right-white.svg) no-repeat left bottom;
    transform: translate(100px, 0px);
    transition: 0.5s;
    transition-delay: 0.2s;

    &.visible {
        transform: translate(0, 0);
    }

    .logo-header-right {
        margin-top: 10px;
        margin-left: 18px;
        background-image: url("../../../../images/supesp-logo-large.png");
        background-repeat: no-repeat;
        background-size: 12em;
        height: 50px;
        width: 300px;
    }
}