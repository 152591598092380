.CardCNHVeiculoById {
  width: 100%;
  margin-left: 15px;

  .title {
    margin: 0 0 10px -15px;
  }

  .message {
    text-align: center;
    padding: 10px 50px 10px 50px;
    font-family: "Share Tech";
    color: #daa709;
    text-rendering: geometricPrecision;
    font-size: 18px;
    white-space: normal;
  }

  .message-loading {
    margin: 0;
    padding: 5px 0 5px 20px;
    font-size: 14px;
    font-family: "Share Tech";
    text-rendering: geometricPrecision;
    color: #fff;
    opacity: 0.5;
    background: rgba(255, 255, 255, 0.1);
    animation: TextBlink infinite 0.3s ease alternate;
  }
}