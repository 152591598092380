
.SearchPanel-SearchBar {
  transition: 0.2s ease 0.2s;
  &.hidden {
    opacity: 0;
    transform: translate(20px,0);
  }
}

.SearchPanel-SearchBar .input-field {
  flex: 9;
  padding-left: 10px;
  line-height: 1;
  border-left: 1px solid rgba(255,255,255, 0.1);
  &.index0 {
    border-left: 0;
    padding-left: 15px;
  }
  &.spacer {
    flex: 99;
  }
  .label {
    pointer-events: none;
    line-height: 1;
    text-transform: uppercase;
    font-size: 11px;
    color: #3B4046;
    user-select: none;
    transition: 0.1s;
    transform: translate(0,3px);
    &.hidden {
      opacity: 0;
      transform: translate(5px,10px);
    }
    &.optional {
      opacity: 0.7;
      transform: translate(0,1px);
    }
  }
  input {
    outline: 0;
    width: 100%;
    line-height: 1.8;
    font-size: 20px;
    margin: -5px 0 0 0;
    padding-right: 5px;
    text-align: left;
    text-transform: uppercase;
    text-rendering: geometricPrecision;
    color: #3B4046;
    background: none;
    border: 0;
    &:focus {
      color: #3B4046;
    }
    &::placeholder {
      font-family: 'Roboto';
      font-size: 18px;
      font-style: italic;
      text-transform: uppercase;
      color: #3B4046;
      text-rendering: geometricPrecision;
    }
  }
}
