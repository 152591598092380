.SearchPanelBackground {
  position: fixed;
  z-index: 999998;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.7;
  background: rgba(255, 255, 255, 0.7);
  transition: 0.2s ease 0.05s;

  &.hidden {
    pointer-events: none;
    opacity: 0;
    transition: 0;
  }
}

.SearchPanel {
  position: fixed;
  z-index: 999999;
  top: 100px;
  left: 50%;
  width: 75vw;
  height: 72vh;
  max-width: 1100px;
  text-rendering: geometricPrecision;
  background: #3B4046;
  border-radius: 10px;
  transform: translate(-50%, 0);
  transition: 0.3s;
  &:before {
    position: fixed;
    content: ' ';
    z-index: -1;
    margin: -15px 30px;
    width: calc(75vw - 60px);
    height: calc(72vh + 30px);
    max-width: 1040px;
    background: #3B4046;
    border-radius: 10px;
    transition: 0.1s;
    opacity: 0.6;
  }

  &.hidden {
    pointer-events: none;
    opacity: 0;
    transform: translate(-50%, 10px);
    transition: 0.2s;
  }

  button.btn-close {
    float: right;
    margin: 10px 15px 0 0;
    width: 22px;
    height: 22px;
    cursor: pointer;
    outline: 0;
    background: rgba(0, 0, 0, 0) url(../../images/cancel-x.svg) no-repeat center;
    background-size: 14px;
    border: 0;
    border-radius: 50%;
    opacity: 0.3;

    &:hover {
      opacity: 0.4;
    }

    &:active {
      opacity: 0.2;
    }

    span {
      display: inline-block;
      padding-right: 10px;
      font-size: 13px;
      color: rgba(255, 255, 255, 0.6);
      text-rendering: geometricPrecision;
      transform: translate(-38px, 0);
    }
  }

  .container-content {
    height: calc(100% - 80px - 50px);
    overflow-y: auto;
  }

  .loading {
    width: 100%;
    height: 100px;
    background: url(../../images/icon-horizontal-loading-dark.gif) no-repeat center;
    opacity: 0.7;
  }

  .error-message {
    margin: 50px 0 0 0;
    padding: 20px 30px 60px 30px;
    font-size: 18px;
    text-align: center;
    text-rendering: geometricPrecision;

    color: #c7c6bc;

    background: #3B4046;

    border: 3px solid #D6A536;

    border-left: 0;
    border-right: 0;

    .icon {
      display: block;
      height: 120px;
      opacity: 0.2;
      background: url(../../images/icon-notification-warn.svg) no-repeat center;
      background-size: 75px;
    }
  }

}

.SearchPanel.minimized {
  height: 100px;
  background: #50616900;

  &:before {
    height: 130px;
    opacity: 0;
  }

  .search-bar {
    background: #626b6f9e;
    transform: translate(0, -3px);
    transition: 0.1s;

    &:hover {
      border-color: rgba(255, 255, 255, 0.1);
      box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.1);

      .icon {
        opacity: 0.55;
      }

      input.input::placeholder {
        color: rgba(255, 255, 255, 0.2);
      }
    }

    .icon {
      opacity: 0.4;
    }
  }

  .search-bar-description {
    opacity: 0;
    transform: translate(0, -10px);
  }
}