
.CardIndicadoresArea {
  width: 710px;
 
  .card-title span {
    display: inline-block;
    padding-left: 5px;
    opacity: 0.7;
  }

  p.message {
    padding: 40px 20px 10px 20px;
    font-family: 'Share Tech';
    font-size: 18px;
    text-align: center;
    text-rendering: geometricPrecision;
    color: #fffa;
    &.empty {
      color: #DAA709;
    }
  }

  .flex-container .SwitcherOnOffContainer {
    display: inline-block;
    padding-right: 30px;
  }

  .summary {
    position: relative;
    margin: -5px -20px 0 -20px;
    padding: 10px 20px;
    background: #3B4046;
    .flex-container {
      display: flex;
      align-items: center;
    }
    .flex {
      flex: 1;
    }
    .flex2 { flex-grow: 2 }
    .flex3 { flex-grow: 3 }
    .right {
      text-align: right;
      padding-left: 20px;
    }
    .input {
      position: relative;
      padding-top: 10px;
      input {
        width: 100%;
        padding: 2px 80px 2px 40px;
        font-size: 16px;
        color: #3B4046;
        text-transform: uppercase;
        text-rendering: geometricPrecision;
        outline: 0;
        background: #F6F8FA url(../../../../images/icon-search2.svg) no-repeat 12px center;
        border: 1px solid rgba(255,255,255, 0.4);
        border-radius: 50px;
        &::placeholder { color: #3B4046; text-transform: capitalize; }
        &:focus {
          background-color: #F6F8FA;
        }
      }
    }
    .total-hidden {
      pointer-events: none;
      position: absolute;
      top: 15px;
      right: 15px;
      color: #3B4046;
    }
    .detail span {
      vertical-align: middle;
      padding: 0 10px 0 30px;
      font-size: 12px;
      text-transform: uppercase;
      color: #fff;
      opacity: 0.5;
      text-rendering: geometricPrecision;
    }
  }

  .table-list .ReactVirtualized__List {
    outline: 0;
  }

  .table-list {
    padding: 5px 0 10px 0;
    margin: 0 -20px -20px -20px;
    overflow-y: auto;
    .row {
      text-rendering: geometricPrecision;
      cursor: pointer;
      color: rgba(255,255,255, 0.8);
      opacity: 0.8;
      &:hover {
        opacity: 1;
        background: rgba(0,0,0, 0.05);
        &:active {
          opacity: 0.8;
          transition: none;
        }
      }
    }
    .col {
      display: inline-block;
      padding-top: 5px;
      &.date { width: 110px; padding-left: 20px }
      &.hour { width:  60px }
      &.text { width: 400px }
    }
  }

}

