/* Exemplos de uso em Cards
***************************
this.props.createMapMarker({id: `geocode1`, point: [-38.579, -3.805], data: demo, color: `#fd5`, className: `circle`,                 popup: this.createPopup})
this.props.createMapMarker({id: `geocode2`, point: [-38.578, -3.805], data: demo, color: `#fd5`, className: `circle animated`,        popup: this.createPopup})
this.props.createMapMarker({id: `geocode3`, point: [-38.577, -3.805], data: demo, color:`#fd5`, className: `square`,                 popup: this.createPopup})
this.props.createMapMarker({id: `geocode4`, point: [-38.576, -3.805], data: demo, color:`#fd5`, className: `square animated`,        popup: this.createPopup})
this.props.createMapMarker({id: `geocode5`, point: [-38.575, -3.805], data: demo, color:`#fd5`, className: `square animated rotate`, popup: this.createPopup})
this.props.createMapMarker({id: `geocode6`, point: [-38.574, -3.805], data: demo, color:`#fd5`, className: `triade green`,                 popup: this.createPopup})
this.props.createMapMarker({id: `geocode7`, point: [-38.573, -3.805], data: demo, color:`#fd5`, className: `triade blue animated`,        popup: this.createPopup})
this.props.createMapMarker({id: `geocode8`, point: [-38.572, -3.805], data: demo, color:`#fd5`, className: `triade yellow animated rotate`, popup: this.createPopup})
this.props.createMapMarker({id: `geocode9`, point: [-38.571, -3.805], data: demo, color:`#fd5`, className: `triade bottom blue`,                 popup: this.createPopup})
this.props.createMapMarker({id: `geocodf0`, point: [-38.570, -3.805], data: demo, color:`#fd5`, className: `triade bottom green animated`,        popup: this.createPopup})
this.props.createMapMarker({id: `geocodf1`, point: [-38.569, -3.805], data: demo, color:`#fd5`, className: `triade bottom animated yellow rotate`, popup: this.createPopup})
//
this.props.createMapMarker({id: `geocodf2`, point: [-38.579, -3.806], data: demo, color:`#fd5`, className: `circle border`, popup: this.createPopup})
this.props.createMapMarker({id: `geocodf3`, point: [-38.578, -3.806], data: demo, color:`#fd5`, className: `circle animated rotate border`, popup: this.createPopup})
this.props.createMapMarker({id: `geocodf4`, point: [-38.577, -3.806], data: demo, color:`#fd5`, className: `square animated border`, popup: this.createPopup})
this.props.createMapMarker({id: `geocodf5`, point: [-38.576, -3.806], data: demo, color:`#fd5`, className: `square animated rotate border`, popup: this.createPopup})
//
this.props.createMapMarker({id: `geocodf6`, point: [-38.579, -3.807], data: demo, color:`#fd5`, className: `circle border fullborder`, popup: this.createPopup})
this.props.createMapMarker({id: `geocodf7`, point: [-38.578, -3.807], data: demo, color:`#fd5`, className: `circle animated rotate border fullborder`, popup: this.createPopup})
this.props.createMapMarker({id: `geocodf8`, point: [-38.577, -3.807], data: demo, color:`#fd5`, className: `square animated border fullborder`, popup: this.createPopup})
this.props.createMapMarker({id: `geocodf9`, point: [-38.576, -3.807], data: demo, color:`#fd5`, className: `square animated rotate border fullborder`, popup: this.createPopup})
*/

.Map .mapboxgl-popup-tip,
.Map .mapboxgl-popup-content {
  cursor: default;
  transform: translate(0,-5px);
  opacity: 0.95;
}
.Map .mapboxgl-popup-content { background: #b2b9bd }
.Map .mapboxgl-popup-tip     { border-top-color: #b2b9bd }

.Map .mapboxgl-popup-content .tooltip-box {
  .tooltip-title {
    line-height: 1.1;
    font-family: 'Share Tech';
    font-size: 16px;
    text-transform: uppercase;
    color: #76797b;
  }
  &.only-title .tooltip-title {
    padding: 0;
    margin: 0 0 -3px 0;
    line-height: 1;
    color: #fff;
  }
  .tooltip-text {
    line-height: 1;
    font-family: 'Share Tech';
    font-size: 18px;
    color: #fff;
  }
  .tooltip-source {
    margin-top: 7px;
    line-height: 1;
    font-family: 'Roboto';
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: rgba(255,255,255, 0.8);
  }
}

