
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/open+sans.woff2') format('woff2');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/open+sans.bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/roboto.woff2') format('woff2');
}

@font-face {
  font-family: 'Share Tech';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/share+tech.woff2') format('woff2');
}

@font-face {
  font-family: 'Saira Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/saira+condensed.woff2') format('woff2');
}
@font-face {
  font-family: 'Saira Condensed';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/saira+condensed.bold.woff2') format('woff2');
}
