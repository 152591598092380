.SearchPanel-BodyAsync {
  padding: 10px;

  height: 550px;
  overflow-y: auto;

  .row {
    display: block;
    width: 100%;
    padding: 5px 20px;
    text-align: left;
    font-family: "Roboto";
    font-size: 18px;
    cursor: pointer;
    outline: 0;
    color: #fff;
    border: 0;
    opacity: 0.8;
    transition: 0.1s;
    user-select: none;
    border-top: 1px solid rgba(255, 255, 255, 0.07);
    border-bottom: 1px solid transparent;

    &:last-child {
      border-bottom: 1px solid rgba(255, 255, 255, 0.07);
    }

    &:hover {
      opacity: 1;
      background: rgba(0, 0, 0, 0.05);
      border-top-color: rgba(255, 255, 255, 0.1);
      border-bottom-color: rgba(255, 255, 255, 0.1);

      .info .item-title {
        color: #daa709;
      }

      .info .row-autocomplete-line .line-item .value {
        color: #fff;
      }
    }

    &:active {
      opacity: 0.7;
      transition: none;

      .image img {
        transform: scale(0.98);
      }
    }
  }

  .row .image {
    pointer-events: none;
    vertical-align: middle;
    display: inline-block;
    width: 130px;
    text-align: center;

    &.no-image {
      width: 25px;
      margin: -20px 0;
      opacity: 0;
    }

    &.default img {
      width: 96px;
      height: 96px;
    }

    img {
      display: inline-block;
      width: 128px;
      height: 128px;
      background: #ddd;
      border-radius: 5px;
    }
  }

  .row .info {
    vertical-align: middle;
    display: inline-block;
    padding: 0 0 5px 15px;
    width: calc(100% - 130px);

    .info.no-image {
      width: calc(100% - 25px);
    }

    .item-title {
      margin: 0;
      padding: 3px 0;
      font-family: "Share Tech";
      text-transform: uppercase;
      font-size: 19px;
      line-height: 1.3;
      letter-spacing: 0px;
      color: inherit;
    }
  }

  .row-autocomplete-line {
    font-size: 14px;
    // line-height: 1.1;
    color: rgba(255, 255, 255, 0.9);

    .line-item {
      display: inline-block;
      // line-height: 1.5;
      vertical-align: text-bottom;

      .label {
        display: inline-block;
        vertical-align: middle;
        padding: 0 5px 0 5px;
        min-width: auto;
        font-size: 12px;
        text-transform: uppercase;
        text-align: left;
        opacity: 0.4;
      }

      .value {
        display: inline-block;
        vertical-align: left;
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }

  .row-autocomplete-line .line-item:first-child {
    .label {
      // min-width: 75px;
      padding-left: 0;
    }
  }

  .check-icon {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    margin-top: -5px;
    width: 15px;
    height: 15px;
    outline: 0;
    background: url(../../../images/icon-check-circle-criminal.svg) no-repeat center;
    background-size: contain;
    border: 0;
    border-radius: 0;
  }

  .checkbox-container {
    display: flex;
    align-items: center;
    pointer-events: none;

    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      margin: 0;
      width: 18px;
      height: 18px;
      margin-right: 15px;
      border-radius: 2px;
      background-color: #ffffff;

      &:checked {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        width: 18px;
        height: 18px;
        background: #dba70a url(../../../images/check-mark.svg) no-repeat center;
        background-size: 13px;
      }
    }

    label {
      font-size: 1rem;
      margin-top: 3px;
    }
  }

  .radioButton-container {
    display: flex;
    align-items: center;
    pointer-events: none;

    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      margin-right: 15px;
      border-radius: 50%;

      height: 20px;
      width: 20px;
      background-color: #ffffff;
      border-radius: 50%;

      &:checked {
        border: 3px solid #ffffff;
        background-color: #dba70a;
      }

      &:hover{
        opacity: 0.7;
        cursor: pointer;
      }
    }

    label {
      font-size: 1rem;
      margin-top: 3px;
    }
  }
}