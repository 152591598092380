
.CardViaturasOnline {
  width: 500px;

  .card-title span {
    display: inline-block;
    padding-left: 5px;
    opacity: 0.5;
  }

  p.message {
    padding: 30px 20px;
    text-align: center;
    font-family: 'Share Tech';
    color: #DAA709;
    text-rendering: geometricPrecision;
    text-transform: uppercase;
    font-size: 18px;
    &.error {
      color: #DAA709;
    }
  }

  .filter-container {
    margin: 0 -20px;
    padding: 20px 20px;
    background: #3B4046;
    border-bottom: 1px solid rgba(255,255,255, 0.1);
    border-top: 1px solid rgba(255,255,255, 0.1);
    .SwitcherOnOffContainer {
      vertical-align: top;
      display: inline-block;
      width: 200px;
      label {
        font-family: 'Share Tech';
      }
    }
    .SearchFilterWords {
      vertical-align: top;
      display: inline-block;
      width: 300px;
      margin-top: -10px;
      color: #3B4046;
    }
  }

  .ui-list .column {
    &.code  { width:  70px }
    &.name  { width: 110px }
    &.speed { width:  60px; text-align: right; padding-right: 10px }
  }

}
