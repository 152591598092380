
.CardCVLIAIS {

  .card-title span {
    opacity: 0.7;
  }

  p.message {
    padding: 20px 0;
    font-family: 'Share Tech';
    font-size: 18px;
    text-align: center;
    text-rendering: geometricPrecision;
    color: #f9dfaa;
    opacity: 0.7;
  }

  .row-values {
    pointer-events: none;
    display: flex;
    margin: -15px -20px 15px -20px;
    padding: 15px 20px;
    text-rendering: geometricPrecision;
    color: #fff;
    background: rgba(73, 85, 91, 0.8);
    border-bottom: 1px solid rgba(255,255,255, 0.1);
    border-top: 1px solid rgba(255,255,255, 0.1);
    .col {
      flex: 1;
      padding-right: 20px;
    }
    .col .label {
      font-size: 13px;
      font-family: 'Roboto';
      text-transform: uppercase;
      line-height: 1;
      color: #b4bcbf;
    }
    .col .value {
      font-family: 'Share Tech';
      font-size: 22px;
    }
  }

  .TableChart {
    margin: -15px -15px 25px -15px;
    .row-data .column:last-child {
      border-right: 0;
    }
    .totalizador {
      display: none !important;
    }
    .spacer {
      display: none !important;
    }
    .body .column {
      padding: 4px 0;
    }
    .column.label.pointer {
      font-size: 17px;
    }
  }

}