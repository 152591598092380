.page-container{
  display: flex;
  flex: 4;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #2C383E url(../../images/streets.svg) no-repeat center fixed;
  background-size: cover;

  .content {
    width: 100%;
  }

  .status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      margin: 0;
      color: white;
      opacity: 0.3;
      font-weight: bold;
      font-size: 10rem;
    }

    b {
      position: absolute;
      color: white;
      opacity: 0.8;
      font-size: 3rem;
    }
  }

  .message {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    opacity: 0.5;
    font-size: 1rem;
    font-weight: bold;
    color: #2C383E;
    text-transform: uppercase;
    height: 80px;
  }

}