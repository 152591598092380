
.CardSispenObservacoes {
  margin-top: 20px;
  border: 1px solid rgba(255,255,255, 0.2);
  
  .card-title span {
    display: inline-block;
    padding-left: 5px;
    opacity: 0.5;
  }

  // .group-lines {
  //   padding-bottom: 10px;
  // }

  .caption {
    line-height: 1;
    font-family: 'Share Tech';
    font-size: 16px;
    color: #fffa;
  }

  .text {
    max-width: 700px;
    max-height: 500px;
    overflow-y: auto;
    // margin: 5px -20px;
    // padding: 0 20px 25px 20px;
    line-height: 1.1;
    font-family: 'Share Tech';
    font-size: 16px;
    white-space: normal;
    text-rendering: geometricPrecision;
    color: #a7b0b3;
    & > div {
      //pointer-events: none;
      min-height: 8px;
    }
  }

}

