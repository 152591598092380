
.CardInepEscola {
  .title {
    margin-bottom: 7px;
  }
  .title span {
    display: inline-block;
    margin-left: -1px;
    margin-right: 20px;
    text-transform: uppercase;
    opacity: 0.7;
  }

  .basic-info {
    margin: -12px 0 0 0;
    color: rgba(255,255,255, 0.6);
    text-transform: uppercase;
    text-rendering: geometricPrecision;
    font-size: 12px;
    line-height: 1.1;
    .status {
      pointer-events: none;
      margin: 0 0 5px 0;
      font-weight: bold;
      color: rgba(218, 193, 57, 0.67);
    }
    .panel {
      margin: 0px -20px -10px -20px;
      padding: 0 20px;
      background: rgb(90, 101, 107);
      border-top: 1px solid rgba(255,255,255, 0.05);
      .info {
        pointer-events: none;
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 125px);
        padding-top: 10px;
        line-height: 1.1;
        font-family: 'Share Tech';
        font-size: 15px;
        transition: 0.1s;
        .periodo {
          margin: -2px 0 7px 0;
          font-family: sans-serif;
          font-weight: bold;
          font-size: 14px;
          letter-spacing: 0px;
          color: rgba(255,255,255, 0.6);
        }
        .extra {
          opacity: 0.7;
        }
      }
      .photo {
        display: inline-block;
        vertical-align: top;
        text-align: right;
        width: 120px;
        cursor: pointer;
        transition: 0.1s;
        div {
          position: relative;
          right: 0;
          width: 125px;
          height: 90px;
          background: no-repeat center;
          background-size: cover;
          border: 2px solid rgba(255,255,255, 0);
          transition: 0.1s;
          &:hover {
            border: 2px solid rgba(255,255,255, 0.5);
          }
          &:active {
            opacity: 0.8;
            transition: none;
            transform: scale(0.98);
          }
        }
      }
    }
    .panel.photo-focus {
      .info {
        opacity: 0;
        transform: translate(-10px,0);
      }
      .photo {
        transform: translate(-125px,0);
        div {
          width: 250px;
          height: 183px;
        }
      }
    }
  }

  .row-values {
    display: flex;
    margin: -5px -20px 15px -20px;
    padding:  0px 20px;
    text-rendering: geometricPrecision;
    color: #fff;
    background: rgba(73, 85, 91, 0.8);
    border-bottom: 1px solid rgba(255,255,255, 0.1);
    border-top: 1px solid rgba(255,255,255, 0.1);
    transition: 0.1s;
    .col {
      flex: 1;
      width: 100px;
      padding: 15px;
      opacity: 0.5;
      border: 1px solid rgba(255,255,255, 0);
      border-top: 0;
      border-bottom: 0;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
        background: rgba(255,255,255, 0.04);
        border-color:rgba(255,255,255, 0.08);
      }
      &:active {
        opacity: 0.7;
      }
      &:first-child {
        opacity: 1;
        min-width: 200px;
        pointer-events: none;
      }
    }
    .col .label {
      font-size: 13px;
      font-family: 'Roboto';
      line-height: 1;
      text-transform: uppercase;
      color: #b4bcbf;
    }
    .col .value {
      font-family: 'Share Tech';
      font-size: 22px;
    }
    .col.selected {
      opacity: 0.8;
      color: #fcfca8;
      background: rgba(255,255,255, 0.1);
      .label { color: #d0d28f }
    }
  }

  .content-search {
    margin-top: -15px;
    padding: 0 100px 10px 100px;
  }

  .container-rows {
    max-height: 400px;
    margin: 0 -20px -20px -20px;
    padding: 20px 0;
    overflow-y: auto;
    background: rgba(255,255,255, 0.05);
    .row {
      margin: 0 0 1px 0;
      padding: 10px 20px;
      cursor: pointer;
      border-bottom: 1px solid rgba(255,255,255, 0.05);
      transition: 0.1s;
      &:hover {
        background: rgba(0,0,0, 0.05);
      }
      &:active {
        opacity: 0.7;
        transition: none;
      }
      .row-title {
        margin: 0;
        line-height: 1.2;
        font-family: 'Share Tech';
        font-size: 18px;
        text-transform: uppercase;
        color: #fff;
        opacity: 0.9;
      }
      .row-subtitle {
        margin: 0;
        line-height: 1.2;
        font-family: 'Share Tech';
        font-size: 16px;
        text-transform: uppercase;
        color: #fff;
        opacity: 0.5;
      }
    }
    .row.todos {
      .tipo {
        display: inline-block;
        vertical-align: top;
        width: 150px;
        margin: 10px 0 0 0;
        line-height: 1.2;
        font-family: 'Share Tech';
        font-size: 18px;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        opacity: 0.9;
      }
      .text {
        display: inline-block;
        width: calc(100% - 170px);
      }
    }
  }

  .container-estrutura {
    pointer-events: none;
    max-width: 570px;
    padding: 0 20px;
    text-rendering: geometricPrecision;
    white-space: normal;
    .tag {
      display: inline-block;
      margin: 1px 4px;
      font-size: 10px;
      text-transform: uppercase;
      color: rgba(255,255,255, 0.6);
      border-radius: 50px;
      &.yes {
        padding: 1px 7px;
        background: rgba(21, 175, 100, 0.7);
      }
      &.no {
        padding: 1px 7px 1px 4px;
        padding-left: 20px;
        background: rgba(212, 193, 146, 0.2) url(./icon-x.svg) no-repeat 5px center;
        background-size: 8px;
        border-radius: 0;
        border-left: 1px solid #d69631;
      }
    }
  }

  .group {
    pointer-events: none;
    margin: 10px -20px 0 -20px;
    padding-bottom: 5px;
    text-rendering: geometricPrecision;
    &.background {
      padding-top: 5px;
      margin-bottom: -5px;
      border-top:    1px solid rgba(255,255,255, 0.08);
      border-bottom: 1px solid rgba(255,255,255, 0.08);
    }
    .caption {
      padding: 5px 0 5px 20px;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      color: rgba(255,255,255, 0.8);
    }
    .column {
      display: inline-block;
      vertical-align: top;
      width: 50%;
      padding-bottom: 10px;
      padding-left: 10px;
      &.a { padding-left:  10px; max-width: 200px; }
      &.b { padding-right: 40px; padding-left: 0;  }
    }
    .column .item {
      line-height: 1.2;
      b {
        display: inline-block;
        width: 50px;
        padding-right: 5px;
        text-align: right;
        color: rgba(255,255,255, 0.7);
      }
      label {
        display: inline-block;
        color: rgba(255,255,255, 0.5);
      }
    }
  }

}
