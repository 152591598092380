
.SearchFilterWords {
  position: relative;
  padding: 10px 0 0 0;
  input {
    width: 100%;
    padding: 2px 80px 2px 40px;
    margin-bottom: 10px;
    font-size: 16px;
    color: #3B4046;

    text-transform: uppercase;
    text-rendering: geometricPrecision;
    outline: 0;

    background: #F6F8FA url(../../../images/icon-search2.svg) no-repeat 12px center;

    border: 1px solid rgba(255,255,255, 0.4);
    border-radius: 50px;


    &::placeholder { color: #3B4046; text-transform: capitalize; }
    &:focus {

      background-color: #F6F8FA;
    }
  }
  .total-hidden {
    pointer-events: none;
    position: absolute;
    top: 15px;
    right: 15px;
    color: #3B4046
  }
}
