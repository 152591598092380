
.CardCVPPopupDetails {
  text-rendering: geometricPrecision;

  .card-title {
    color: #e3e57a;
    span {
        display: inline-block;
        padding-left: 5px;
        letter-spacing: 0;
        color: #d3d56a;
        opacity: 0.8;
    }
  }

  .group {
      margin-right: -20px;
      padding: 20px 0;
      text-rendering: geometricPrecision;
      color: #fff;
      label {
        vertical-align: top;
        display: inline-block;
        width: 70px;
        padding: 8px 8px 4px 0;
        line-height: 1.2;
        text-align: right;
        font-size: 12px;
        text-transform: uppercase;
        font-family: 'Open Sans';
        color: rgba(255,255,255, 0.5);
      }
      b {
        vertical-align: top;
        display: inline-block;
        width: calc(100% - 70px);
        padding: 5px 40px 5px 10px;
        line-height: 1.1;
        font-family: 'Share Tech';
        font-size: 18px;
        color: rgba(255,255,255, 0.6);
        background: rgba(0,0,0, 0.1);
      }
      .row:last-child b {
        padding-bottom: 15px;
      }
  }

  .subtitle {
      line-height: 1.3;
      text-align: left;
      font-size: 12px;
      text-rendering: geometricPrecision;
      text-transform: uppercase;
      font-family: 'Open Sans';
      color: rgba(255,255,255, 0.5);
      &.type {
          font-size: 14px;
          color: rgba(255,255,255, 0.8);
      }
      &.type.main {
          margin-top: -5px;
          padding-bottom: 5px;
          font-weight: bold;
      }
  }

  .footer {
      min-width: 350px;
      line-height: 1.3;
      text-align: right;
      font-size: 12px;
      text-transform: uppercase;
      font-family: 'Open Sans';
      color: rgba(255,255,255, 0.8);
  }
}
