@charset "UTF-8";
.TableChart {
  text-rendering: geometricPrecision;
  /**
  *** estilo para tabela onde cada dia do mês é uma coluna
  ***/ }
  .TableChart .column {
    display: inline-block;
    width: 50px;
    font-size: 18px; }
  .TableChart .spacer {
    display: inline-block;
    width: 20px; }
  .TableChart .header .column {
    padding: 4px 0;
    line-height: 1;
    font-family: 'Share Tech';
    letter-spacing: 1px;
    text-align: center;
    vertical-align: middle;
    color: rgba(255, 255, 255, 0.3);
    font-size: 17px; }
  .TableChart .header .column.totalizador {
    pointer-events: none;
    cursor: default;
    visibility: visible;
    display: inline-block;
    width: 100px;
    padding: 7px 0;
    text-align: center;
    font-size: 12px;
    font-family: 'Roboto';
    background: #5d686d; }
  .TableChart .header .column.totalizador:first-child {
    padding-left: 5px;
    width: 105px; }
  .TableChart .header .column.totalizador {
    pointer-events: none;
    cursor: default;
    padding: 7px 0;
    margin-right: -1px;
    width: 100px;
    line-height: 1;
    font-family: 'Roboto';
    font-size: 12px !important;
    font-weight: normal;
    text-rendering: geometricPrecision;
    color: rgba(255, 255, 255, 0.35);
    background: #3B4046; }
  .TableChart .cols {
    padding: 0 5px; }
  .TableChart .body .column {
    display: inline-block;
    width: 50px;
    padding: 7px 0;
    text-align: center;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(0, 0, 0, 0.12); }
  .TableChart .body .column.selected {
    color: rgba(255, 255, 255, 0.95);
    box-shadow: inset 0 0 2px #fff;
    background: rgba(255, 255, 100, 0.2); }
  .TableChart .header .column.just-label:first-child,
  .TableChart .body .column.label {
    width: 80px;
    padding: 4px 0;
    line-height: 1;
    font-family: 'Share Tech';
    letter-spacing: -1px;
    text-align: center;
    vertical-align: middle;
    font-size: 19px;
    color: rgba(255, 255, 255, 0.3); }
  .TableChart .body .column.totalizador {
    pointer-events: none;
    cursor: default;
    padding: 10px 0;
    width: 100px;
    line-height: 1;
    font-family: 'Roboto';
    font-size: 14px !important;
    font-weight: normal;
    text-rendering: geometricPrecision;
    color: rgba(255, 255, 255, 0.5);
    background: #3B4046; }
  .TableChart .body .column.cell {
    cursor: pointer; }
  .TableChart .body .column.cell:hover {
    color: rgba(255, 255, 255, 0.8);
    background: rgba(255, 255, 255, 0.05); }
  .TableChart .body .column.cell:active {
    color: rgba(255, 255, 255, 0.4);
    background: rgba(255, 255, 255, 0.03); }
  .TableChart .body .column.cell.selected:hover {
    background: rgba(255, 255, 100, 0.1); }
  .TableChart .column.pointer {
    cursor: pointer;
    font-family: 'Open Sans';
    font-size: 12px; }
  .TableChart .column.pointer:hover {
    color: rgba(255, 255, 255, 0.5);
    background: rgba(255, 255, 255, 0.05); }
  .TableChart .column.pointer:active {
    color: rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 255, 0);
    transition: none; }
  .TableChart .column.cell {
    text-align: center; }
  .TableChart .column .value,
  .TableChart .column .circle {
    transition: 0.2s; }
  .TableChart .column .circle {
    position: absolute;
    margin: 8px 0 0 25px;
    margin: 10px 0 0 25px;
    background: #fff;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(1);
    transform-origin: center;
    opacity: 0.5; }
  .TableChart .column .value {
    opacity: 0; }
  .TableChart:hover .column .value {
    opacity: 1; }
    .TableChart:hover .column .value.value-0 {
      opacity: 0; }
  .TableChart:hover .column .circle {
    opacity: 0.05;
    transform: translate(-50%, -50%) scale(0);
    transition-delay: 0s !important; }
  .TableChart:hover .column .value.value-0 {
    opacity: 0.15; }
  .TableChart.month .column {
    width: 30px; }
  .TableChart.month .column.column-D {
    background: rgba(255, 255, 255, 0.07); }
  .TableChart.month .column .circle {
    margin-left: 15px; }
