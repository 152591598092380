*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.side-menu-container {
  display: flex;
  flex: 1;
}

.nav {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  align-items: center;
  top: 0;
  position: sticky;
  height: 100vh;
  background-color: #fff;

  &__list {
    display: flex;
    flex-direction: column;
    min-width: 350px;

    &-item {
      list-style: none;
      transition: all 200ms ease-in;
      padding: 1rem 1.5rem;
      cursor: pointer;

      &:hover {
        background-color: #f5f5f5;
      }

      a {
        display: flex;
        align-items: center;
        color: #3b4046;
        text-decoration: none;
        font-size: 1.125rem;
        font-weight: 600;
      }

      .active {
        color: #daa709;
      }
    }
  }
}
