.sections-container {
  display: flex;
  flex: 3;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  background-color: #fff;

  section {
    padding: 3em 10% 0 10%;

    h1 {
      font-size: 2.25rem;
      font-weight: 700;
      color: #3b4046;
    }

    h2 {
      font-size: 1.75rem;
      font-weight: 700;
      color: #3b4046;
    }

    p {
      font-size: 1rem;
      color: #3b4046;
    }

    ol {
      margin-left: 1rem;
      color: #3b4046;
    }
  
    ul {
        margin-left: 1rem;
        color: #3b4046;
    }
    
    li {
        font-size: 1rem;
    }

    img {
      display: block;
      margin: 1rem auto;
      border-radius: 0.3rem;
      max-height: 28rem;
      max-width: 100%;
    }

    &:first-child {
      margin-top: var(--scroll-padding, 700px);
    }

    &:last-child {
      margin-bottom: var(--last-section-height, 100vh);
    }
  }
}
