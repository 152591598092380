
.SwitcherOnOff {
  display: inline-block;
  width: 40px;
  height:22px;
  text-align: left;
  cursor: pointer;
  background: #eeeeee3b;
  border-radius: 50px;
  transition: 0.2s;

  & > div {
    position: relative;
    display: inline-block;
    top: 2px;
    left: 3px;
    width: 18px;
    height:18px;
    background: #aaa;
    border-radius: 50px;
    transition: 0.1s;
  }

  &.true {
    background: #ffbe0052;
    & > div {
      left: 19px;
      background: #dba70a;
    }
  }
  &:active > div {
    opacity: 0.8;
    transform: scale(0.9);
    transition: none;
  }
}

.SwitcherOnOffContainer {
  padding: 2px 0;
  vertical-align: middle;
  & > .SwitcherOnOff {
    vertical-align: middle;
  }
  & > label {
    vertical-align: middle;
    padding-left: 10px;
    display: inline-block;
    font-size: 16px;
    color: #eee;
    font-family: 'Roboto';
    text-rendering: geometricPrecision;
    opacity: 0.5;
    transition: 0.2s;
    &.left {
      padding-right: 10px;
    }
  }
  &.true {
    label.right { opacity: 1.0 }
    label.left  { opacity: 0.5 }
  }
  &.false {
    label.right { opacity: 0.5 }
    label.left  { opacity: 1.0 }
  }
  &.disabled {
    cursor: not-allowed;
    .SwitcherOnOff {
      cursor: not-allowed;
      opacity: 0.4;
    }
    label {
      cursor: not-allowed;
      text-decoration: line-through;
    }
  }
}
