@keyframes shake {

  10%,
  90% {
    transform: translate3d(-5px, 0, 0)
  }

  20%,
  80% {
    transform: translate3d(10px, 0, 0)
  }

  30%,
  50%,
  70% {
    transform: translate3d(-20px, 0, 0)
  }

  40%,
  60% {
    transform: translate3d(20px, 0, 0)
  }
}

.SearchPanel-BodyOptions-Row {
  padding: 5px 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.07);
  opacity: 0.7;

  &:last-child {
    border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  }

  &:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.03);
  }

  &.shake {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.03);

    .content {
      animation: shake infinite 1s ease alternate;
      animation-iteration-count: 1;

      .SearchPanel-InputParam {
        border-color: #eac881;
      }
    }
  }

  .content {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 55px);
  }

  .context-name {
    display: inline-block;
    padding: 0 20px;
    vertical-align: middle;
    font-size: 22px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.4);
    user-select: none;

    b {
      display: inline-block;
      vertical-align: middle;
      line-height: 1;
      padding-right: 10px;
      font-size: 26px;
      opacity: 0.2;
    }
  }

  &:hover .context-name {
    color: #e4c769;

    b {
      opacity: 0.8;
      color: #fff
    }
  }

  .title {
    display: inline-block;
    padding: 10px 0 0 5px;
    vertical-align: middle;
    line-height: 1.1;

    b {
      font-family: 'Share Tech';
      font-size: 16px;
      text-transform: uppercase;
      text-rendering: geometricPrecision;
      color: rgba(255, 255, 255, 0.8);
    }

    p {
      font-family: 'Roboto';
      font-size: 14px;
      text-rendering: geometricPrecision;
      color: rgba(255, 255, 255, 0.8);
    }
  }


  .informative-title {
    padding: 10px 0 0 5px;
    font-family: 'Share Tech';
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    text-rendering: geometricPrecision;
    color: rgba(255, 255, 255, 0.8);
  }

  .informative-subtitle {
    padding: 0 0 10px 5px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    text-rendering: geometricPrecision;
    color: rgba(255, 255, 255, 0.8);
  }

  .description {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.1;

    p {
      margin-left: 5px;
      font-family: 'Roboto';
      font-size: 14px;
      text-rendering: geometricPrecision;
      color: rgba(255, 255, 255, 0.4);
    }
  }

  .tag {
    display: inline-block;
    margin: 5px;
    padding: 4px 12px;
    outline: 0;
    font-size: 14px;
    cursor: default;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.3);
    background: #3B4046;
    border: 3px solid rgba(255, 255, 255, 0.3);

    border-radius: 50px;
  }

	.divisor {
		height: 4px;
	}

  .btn-done {
    display: inline-block;
    vertical-align: middle;
    width: 50px;
    height: 50px;
    cursor: pointer;
    outline: 0;
    background: url(../../../images/arrow-right.svg) no-repeat center;
    background-size: 20px;
    border: 0;
    opacity: 0.2;

    &:hover {
      opacity: 0.9 !important;
    }

    &:active {
      opacity: 0.4 !important;
    }
  }

  &:hover .btn-done {
    opacity: 0.7;
  }

  &.clickable {
    user-select: none;
    cursor: pointer;

    &:hover {
      .btn-done {
        opacity: 0.7;
      }
    }

    &:active {
      opacity: 0.7;
      transition: none;
    }
  }
}