.MenuBar-criminal {
  min-width: 400px;
  max-width: 700px;
  padding: 0 20px;

  background: rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  border-bottom-color: #dba70a;

  position: relative;

  .pagination-container {
    display: flex;
    position: absolute;
    top: 10px;
    right: 0;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 10px;

    gap: 5px;
    color: rgba(255, 255, 255, 0.7);

    .active-arrow {
      svg {
        path {
          stroke: #dba70a;
        }
      }
    }

    .inactive-arrow {
      svg {
        path {
          stroke: #A5A5A5;
        }
      }

      pointer-events: none;
    }
  }

  .hidden {
    display: none;
  }

  button {
    display: inline-block;
    vertical-align: top;
    width: 120px;
    padding: 7px 0 5px 0;
    font-family: 'Share Tech';
    font-size: 18px;
    letter-spacing: 1px;
    text-rendering: geometricPrecision;
    text-align: left;
    line-height: 1.1;
    color: #fff;
    cursor: pointer;
    background: none;
    outline: 0;
    border: 0;
    opacity: 0.6;
    transition: 0.1s;

    &:hover {
      opacity: 0.7;
    }

    &:active {
      opacity: 0.6;
      transform: translate(1px, 1px);
      transition: none;
    }

    div {
      font-family: 'Roboto';
      font-size: 13px;
      letter-spacing: 0;
      color: rgba(255, 255, 255, 0.7);
    }
  }

  button.selected {
    opacity: 0.9;
  }

  .select-line {
    pointer-events: none;
    width: calc(120px - 20px);
    height: 3px;
    background: #dba70a;
    transition: 0.1s;
  }
}