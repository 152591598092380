
body .Tooltip-hint {
  pointer-events: none;
  position: fixed;
  z-index: 11;
  display: inline-block;
  padding: 5px 10px;
  line-height: 1;
  font-family: 'Roboto';
  font-size: 14px;
  color: #fff;
  background: #86afbfee;
  border-radius: 3px 3px 3px 0;
  transition: 0.2s;
  span {
    display: inline-block;
    padding-right: 5px;
    font-size: 10px;
    text-transform: uppercase;
    opacity: 0.8;
  }
  &.white {
    color: #777;
    background: #ddde;
  }
}

.Thermometer .Tooltip svg {
  path.domain, .tick line {
    stroke: #fff;
    opacity: 0.5;
  }
  .bar {
    fill: #88a0a9;
  }
  .bar:hover {
    fill: #91b4c1;
    stroke: #fff3;
    stroke-width: 1px;
  }
  .axis--x path { display: none; }
  .line {
    pointer-events: none;
    fill: none;
    stroke: #fff;
    stroke-width: 1;
    stroke-opacity: 0.8;
  }
  .dot {
    fill: #fff;
    stroke: #fff;
  }
  .dot-hint {
    fill: #fff;
    opacity: 0;
    &:hover {
      opacity: 0.3;
    }
  }
  text {
    fill: #fff;
  }
}
