
.CardSispenCriminal {
  margin-top: 20px;
  border: 1px solid rgba(255,255,255, 0.2);

  .header-line {
    // pointer-events: none;
    margin: 10px 0 10px -20px;
    padding: 4px 0 6px 20px;
    font-size: 14px;
    text-align: left;
    text-transform: uppercase;
    text-rendering: geometricPrecision;
    color: rgba(255,255,255, 0.8);
    opacity: 0.7;
    overflow: hidden;
    span {
      display: inline-block;
      padding-right: 15px;
    }
    // &:before {
    //   pointer-events: none;
    //   content: ' ';
    //   position: absolute;
    //   left: 20px;
    //   margin: -5px 0 0 -30px;
    //   width: 220px;
    //   height: 30px;
    //   background: rgba(255,255,255, 0.25);
    //   transform: translateX(0px) skewX(-25deg);
    //   opacity: 1;
    //   transition: 0.2s;
    // }
  }

  .panel {
    //pointer-events: none;
    padding-bottom: 10px;
    text-rendering: geometricPrecision;
    width: 100%;
    .line {
      display: flex;
      flex-direction: row;
      line-height: 1.2;
      padding: 0;
      font-family: 'Roboto';
      font-size: 14px;
      color: rgba(255,255,255, 0.8);
      .label {
        vertical-align: middle;
        display: inline-block;
        width: 130px;
        padding-right: 5px;
        font-size: 12px;
        text-align: right;
        text-transform: uppercase;
        opacity: 0.7;
      }
      .value {
        vertical-align: middle;
        display: inline-block;
        width: calc(100% - 120px);
        font-family: 'Share Tech';
        font-size: 16px;
        white-space: normal;
      }
    }
  }

  p.message.empty {
    padding: 50px 0;
    opacity: 0.7;
    font-size: 18px;
    font-family: 'Share Tech';
    text-align: center;
    text-rendering: geometricPrecision;
    color: #DAA709;
  }

  .process {
    //pointer-events: none;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 14px;
    font-family: 'Roboto';
    text-rendering: geometricPrecision;
    color: rgba(255,255,255, 0.8);
    background: #3B4046;
    border-radius: 2px;
    .line {
      display: flex;
      width: 100%;
      padding-bottom: 3px;
      line-height: 1.2;
      &.id {
        font-size: 15px;
        color: #ffce0a;
        opacity: 0.8;
      }
      &.dates {
        .col {
          display: inline-block;
          padding-right: 20px;
          font-family: 'Share Tech';
          font-size: 16px;
          span {
            display: inline-block;
            padding: 0 2px 0 0;
            text-transform: uppercase;
            opacity: 0.5;
          }
        }
      }
      &.text {
        max-width: 700px;
        white-space: normal;
        padding-bottom: 0;
        opacity: 0.9;
        &.small {
          line-height: 1;
          font-size: 13px;
          opacity: 0.7;
        }
      }
      &.value {
        max-width: 700px;
        white-space: normal;
      }
    }
  }

}

