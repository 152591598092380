
.CardSispenVisitasRecebidas {
  margin-top: 20px;
  border: 1px solid rgba(255,255,255, 0.2);

  .card-title {
    margin-bottom: 10px;
    span {
      display: inline-block;
      padding-left: 5px;
      opacity: 0.6;
    }
    b {
      display: inline-block;
      padding-left: 5px;
      font-weight: normal;
      color: #ffce0a;
    }
  }

  .message-loading {
    margin: 0;
    padding: 5px 0 5px 20px;
    font-size: 14px;
    font-family: "Share Tech";
    text-rendering: geometricPrecision;
    color: #fff;
    opacity: 0.5;
    background: rgba(255, 255, 255, 0.1);
    animation: TextBlink infinite 0.3s ease alternate;
  }

  .message {
    padding: 20px 20px 30px 20px;
    text-align: center;
    font-family: 'Share Tech';
    color: #DAA709;
    opacity: 0.7;
    text-rendering: geometricPrecision;
    font-size: 18px;
  }

  .container-list {
    .ReactVirtualized__List {
      outline: 0;
      // margin: 0 -20px -20px -20px;
    }
    .row {
      cursor: pointer;
      padding: 5px;
      background: rgba(255,255,255, 0);
      border-top: 1px solid rgba(255,255,255, 0.05);
      opacity: 0.8;
      &:hover {
        opacity: 1;
        background: rgba(255,255,255, 0.03);
      }
      &:active {
        opacity: 0.7;
      }
    }
    .row .column {
      display: inline-block;
      vertical-align: top;
      text-rendering: geometricPrecision;
      &.a {
        width: 100px;
        padding-left: 20px;
      }
      &.b {
        padding-left: 15px;
        width: calc(100% - 110px);
      }
    }
    .row .column.a {
      padding-top: 18px;
      line-height: 1.1;
      text-align: center;
      color: #DAA709;
      .date {
        font-size: 15px;
      }
    }
    .row .column.b {
      color: rgba(255,255,255, 0.8);
      .line {
        padding-top: 5px;
        line-height: 1;
        font-size: 12px;
        opacity: 0.5;
      }
      .mainline {
        font-family: 'Share Tech';
        font-size: 18px;
        line-height: 1;
        b {
          font-weight: normal;
          display: inline-block;
          padding-right: 5px;
        }
      }
      .labels {
        line-height: 1.1;
        font-size: 13px;
        span {
          display: inline-block;
          margin: 0 20px 0 0;
          color: rgba(255,255,255, 0.8);
          b {
            display: inline-block;
            padding-right: 5px;
            font-weight: normal;
            font-size: 12px;
            opacity: 0.5;
          }
        }
      }
    }
  }

}
