
.CardSateliteEquipe {
  min-height: 200px;

  .card-title {
    padding-right: 70px;
    text-transform: uppercase;
    span {
      padding-left: 8px;
      font-size: 18px;
      font-family: 'Share Tech';
      text-rendering: geometricPrecision;
      opacity: 0.5;
    }
  }

  .header-line {
    pointer-events: none;
    margin: 0 0 0 -20px;
    padding: 4px 0 6px 20px;
    font-size: 14px;
    text-transform: uppercase;
    text-rendering: geometricPrecision;
    color: #fff;
    opacity: 0.7;
    overflow: hidden;
    span {
      display: inline-block;
      padding-right: 15px;
    }
    &:before {
      pointer-events: none;
      content: ' ';
      position: absolute;
      left: 20px;
      margin: -9px 0 0 -30px;
      width: 300px;
      height: 38px;
      background: rgba(255,255,255, 0.25);
      transform: translateX(0px) skewX(-25deg);
      opacity: 1;
      transition: 0.2s;
    }
  }

  .satelite {
    padding-top: 20px;
  }

  .satelite .card-title {
    margin: 10px 0 5px 0;
  }

  .timeline-container {
    position: relative;
    width: 100%;
    .message {
      position: absolute;
      width: 100%;
      margin: -95px 0 0 0;
      padding: 20px 0;
      font-family: 'Share Tech';
      font-size: 16px;
      text-align: center;
      text-rendering: geometricPrecision;
      text-transform: uppercase;
      color: rgba(255,255,255, 0.7);
    }
    &.has-message {
      pointer-events: none;
      .container {
        opacity: 0.3;
      }
    }
  }
}

.CardSateliteEquipe .panels {
  display: flex;
  margin: 20px 0;
  
  .panel {
    flex: 1;
    margin-right: 10px;
    padding: 10px 10px 20px 10px;
    background: rgba(88, 209, 218, 0.3);
    border-radius: 5px;
    &:last-child {
      margin-right: 0;
    }
    h1 {
      padding: 10px 0;
      font-family: 'Share Tech';
      font-weight: bold;
      text-rendering: geometricPrecision;
      line-height: 1;
      font-size: 22px;
      color: #fff;
    }
  }

  .panel.controls {
    input {
      position: relative;
      &:after {
        content: ' ';
        position: absolute;
        top: 2px;
        left: 0;
        width: 20px;
        height:20px;
        background: #3a3;
        border-radius: 50px;
      }
    }
    .span {
      font-size: 16px;
      color: #fff;
    }
    .feedback {
      font-size: 12px;
      color: #ddd;
    }
  }
}

.CardSateliteEquipe .group {
  padding: 5px 0;
  margin: 5px 0;
  h1 {
    position: relative;
    margin: 0;
    padding: 10px 0 5px 0;
    font-family: 'Share Tech';
    text-rendering: geometricPrecision;
    line-height: 1;
    font-size: 22px;
    color: #fff;
    opacity: 0.9;
    &:before {
      content: ' ';
      position: absolute;
      left:   0;
      bottom: 0;
      width: 50px;
      height: 1px;
      margin-left: -10px;
      background: rgba(202, 209, 212, 0.8);
      border: 0;
      border-radius: 0;
    }
  }
  .list {
    padding: 5px 0 0 0;
    margin-right: -20px;
    border-radius: 0 0 0 2px;
    border-left: 1px solid rgba(202, 209, 212, 0.7);
  }
  .row {
    padding: 3px 0;
    font-size: 12px;
    font-family: 'Roboto';
    text-rendering: geometricPrecision;
    text-transform: uppercase;
    color: #fff;
    background: rgba(255,255,255, 0.06);
    opacity: 0.7;
    &:nth-child(even) {
      background: rgba(255,255,255, 0.03);
    }
    &:hover {
      opacity: 1;
      background: rgba(255,255,255, 0.07);
    }
    .code {
      display: inline-block;
      min-width: 50px;
      padding-right: 15px;
      text-align: right;
      color: #eee;
    }
    .name {
      letter-spacing: 2px;
    }
  }
}

.CardSateliteEquipe .group.controles {
  .list {
    border-bottom: 0;
  }
  .option {
    padding: 2px 20px;
  }
}

.CardSateliteEquipe .container.componentes {
  margin: 10px -20px 0 -20px;
  border-bottom: 1px solid rgba(255,255,255, 0.3);
  h1 {
    margin: 0;
    padding: 10px 0 5px 20px;
    font-family: 'Share Tech';
    text-rendering: geometricPrecision;
    line-height: 1;
    font-size: 20px;
    color: #fff;
    opacity: 0.9;
  }
  .table-container {
    padding: 5px 0 20px 0;
    background: rgba(0,0,0, 0.05);
    border-top: 1px solid rgba(255,255,255, 0.1);
  }
  table {
    width: 100%;
    color: #fff;
    text-align: left;
    text-rendering: geometricPrecision;
    opacity: 0.7;
    td {
      vertical-align: middle;
      padding: 2px 15px;
      font-family: 'Open Sans';
      .status {
        display: inline-block;
        width: 10px;
        height:10px;
        background: rgba(255,255,255, 0.2);
        border-radius: 50px;
        vertical-align: middle;
        &.online {
          background: #32c76e;
        }
      }
    }
    tr:nth-child(even) {
      background: rgba(0,0,0, 0.1);
    }
    td.view {
      padding: 0 3px 0 25px;
      width: 50px;
    }
    td.flag {
      width: 75px;
      padding: 0 3px;
      text-align: center;
      .time {
        vertical-align: middle;
        display: inline-block;
        margin: 0 -10px 0 5px;
        width: 60px;
        font-size: 13px;
        font-family: 'Share Tech';
        text-transform: uppercase;
        text-rendering: geometricPrecision;
        letter-spacing: 1px;
        line-height: 1.5;
        color: #fff;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 3px;
        transition: 0.2s;
        &.hidden {
          margin: 0 -100px 0 0;
          opacity: 0;
          transition: none;
        }
      }
      &.green   .time { color: #33eeaa; background: rgba(121,239,164, 0.60) }
      &.yellow  .time { color: #ffe54c; background: rgba(255,255,150, 0.20) }
      &.orange  .time { color: #ffa200; background: rgba(255, 187, 0, 0.26) }
      &.gray    .time { color: #aaa }
    }
    td.code {
      width: 50px;
      text-align: center;
    }
    td.name {
      text-align: left;
    }
    tr.closed td:not(.view) {
      opacity: 0.5;
      filter: grayscale(100%);
      .status {
        background: #aaa !important;
      }
    }
  }
  button.link {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    font-size: 12px;
    font-family: 'Share Tech';
    cursor: pointer;
    outline: none;
    color: #ffa200;
    opacity: 0.8;
    background: rgba(255,255,255, 0.05) url(../../../../images/arrow-right.svg) no-repeat center;
    background-size: 14px;
    border: 1px solid rgba(255,255,255, 0.15);
    border-radius: 3px;
    transition: 0.1s;
    transform: translate(0,-1px);
    &:hover {
      opacity: 1;
    }
    &:active {
      opacity: 0.7;
      transition: none;
      transform: scale(0.9);
    }
  }
}


.CardSateliteEquipe button.eye {
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height:18px;
  outline: 0;
  opacity: 0.4;
  cursor: pointer;
  background: url(../../../../images/icon-eye-opened.svg) no-repeat center;
  background-size: contain;
  border: 0;
  border-radius: 0;
  transition: 0.2s;
  &:hover {
    opacity: 0.6;
  }
  &:active {
    opacity: 0.3;
    transform: scale(0.9);
    transition: none;
  }
  &.closed {
    background-image: url(../../../../images/icon-eye-closed.svg);
  }
}

.CardSateliteEquipe .settings {
  .tip {
    margin-top: -3px;
    padding-left: 50px;
    line-height: 1;
    text-rendering: geometricPrecision;
    color: rgba(255, 228, 0, 0.5);
  }
}
