
@media (max-width: 600px) {
  .spi-oauth2-login-screen {

    .horizontal-bar {
      height: 70vh;
    }

    .main-shape {
      display: none;
      left: -50%;
      right: 50%;
      top: 7%;
      height: 80vh;
    }

    & > .form {
      width: 90%;
      & > .application {
        padding: 10px 0;
        line-height: 1;
        font-size: 24px;
      }
      button.spi-oauth2-button {
        width: 220px;
        padding: 1px 20px 2px 20px;
        .column.icon {
          width: 50px;
          height: 40px;
        }
        .column.text {
          padding: 3px 0 10px 0;
        }
        .column.text .main {
          font-size: 18px;
        }
        .column.text .help {
          letter-spacing: 2px;
        }
      }
    }

    .footer {
      height: 40px;
      width: 200px;
      min-width: auto;
      max-width: auto;
      & > .text {
        top: 15px;
        right: 5%;
        & > span {
          display: none;
        }
      }
    }
  }
}
