
.CardCVPLista {

  .card-title.category span {
    opacity: 0.7;
  }

  p.message {
    padding: 30px 100px;
    font-family: 'Share Tech';
    color: #f9dfaa;
    text-rendering: geometricPrecision;
    font-size: 18px;
    opacity: 0.7;
  }

  .row-values {
    display: flex;
    margin: -15px -20px 15px -20px;
    padding:  0px 20px;
    text-rendering: geometricPrecision;
    color: #fff;
    background: rgba(73, 85, 91, 0.8);
    border-top: 1px solid rgba(255,255,255, 0.1);
    transition: 0.1s;
    .col {
      flex: 1;
      width: 100px;
      padding: 15px;
      opacity: 0.5;
      border: 1px solid rgba(255,255,255, 0);
      border-top: 0;
      border-bottom: 0;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
        background: rgba(255,255,255, 0.04);
        border-color:rgba(255,255,255, 0.08);
      }
      &:active {
        opacity: 0.7;
      }
      &:first-child {
        opacity: 1;
        min-width: 200px;
        pointer-events: none;
      }
    }
    .col .label {
      font-size: 13px;
      font-family: 'Roboto';
      line-height: 1;
      color: #b4bcbf;
    }
    .col .value {
      font-family: 'Share Tech';
      font-size: 22px;
    }
    .col.selected {
      opacity: 0.8;
      color: #fcfca8;
      background: rgba(255,255,255, 0.1);
      .label { color: #d0d28f }
    }
  }

  .weekdays {
    margin: -15px -20px 15px -20px;
    padding: 5px 10px;
    text-align: center;
    background: #5a656a;
    border-bottom: 1px solid #b3babe21;
    .column {
      display: inline-block;
      width: 100px;
      margin: 5px 0;
      cursor: pointer;
      line-height: 1.2;
      text-rendering: geometricPrecision;
      border-right: 1px solid rgba(255,255,255, 0.1);
      &:last-child {
        border-right: 0;
      }
      .label {
        font-size: 12px;
        text-transform: uppercase;
        color: rgba(255,255,255, 0.3);
      }
      .value {
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: bold;
        color: rgba(255,255,255, 0.5);
      }
      &:hover {
        .label { color: rgba(255,255,255, 0.4) }
        .value { color: rgba(255,255,255, 0.6) }
      }
      &:active {
        .label { color: rgba(255,255,255, 0.2) }
        .value { color: rgba(255,255,255, 0.4) }
      }
    }
    .column.selected {
      .label { color: rgb(181, 185, 134) }
      .value { color: rgb(216, 219, 153) }
    }
  }

  .line-chart {
    margin: 0 -20px -20px -20px;
    padding: 10px 0 20px 0;
    overflow-y: auto;
    max-height: 550px;
    vertical-align: middle;
    text-rendering: geometricPrecision;
    color: rgba(255,255,255, 0.9);
    .row {
      padding: 1px 20px;
      cursor: pointer;
      background: rgba(255,255,255, 0);
      opacity: 0.8;
      transition: 0.1s;
      &:hover {
        opacity: 1 !important;
        background: rgba(255,255,255, 0.03);
      }
      &:active {
        opacity: 0.8;
        transition: none;
      }
      &.selected {
        opacity: 1 !important;
        background: rgba(255,255,255, 0.08);
      }
      &.empty {
        opacity: 0.4;
      }
      &.no-pointer {
        opacity: 0.2;
        // pointer-events: none;
        filter: grayscale(100%);
      }
    }
    .value {
      vertical-align: middle;
      display: inline-block;
      width: 50px;
      text-align: right;
    }
    .bar {
      position: relative;
      vertical-align: middle;
      display: inline-block;
      width: 80px;
      height: 14px;
      margin: 0 10px;
      background: #9b9c8e54;
      .life {
        vertical-align: top;
        display: inline-block;
        height: 14px;
        background: #f5f7d8;
        transition: 0.2s;
      }
      .percent {
        display: none;
        position: absolute;
        font-size: 11px;
        color: rgba(255,255,255, 0.8);
        top: 0;
        right: 50%;
        transform: translate(50%,0);
      }
    }
    .label {
      vertical-align: middle;
      display: inline-block;
      width: calc(100% - 80px -  80px);
    }
  }

}