
.SearchPanel-BodyPeople {
  padding: 10px;
  .row {
    display: block;
    width: 100%;
    padding: 5px 20px;
    text-align: left;
    font-family: 'Roboto';
    font-size: 18px;
    outline: 0;
    color: #fff;
    border: 0;
    opacity: 0.8;
    transition: 0.1s;
    user-select: none;
    border-top: 1px solid rgba(255,255,255, 0.07);
    border-bottom: 1px solid transparent;
    &:last-child {
      border-bottom: 1px solid rgba(255,255,255, 0.07);
    }
    &:hover {
      opacity: 1;
      background: rgba(0,0,0, 0.03);
      border-top-color: rgba(255,255,255, 0.1);
      border-bottom-color: rgba(255,255,255, 0.1);
      .info .row-autocomplete-line .line-item .value {
        color: #fff;
      }
    }
  }

  .row .column.image {
    vertical-align: middle;
    display: inline-block;
    width: 130px;
    text-align: center;
    img {
      display: inline-block;
      width: 128px;
      height: 128px;
      background: #ddd;
      border-radius: 5px;
      &.default {
        width: 96px;
        height: 96px;
      }
    }
  }
  .row .column.data {
    vertical-align: middle;
    display: inline-block;
    padding: 0 0 5px 15px;
    width: calc(100% - 130px);
    .title.fullname {
      margin: 20px 0 0 0;
      padding: 3px 0;
      cursor: pointer;
      font-family: 'Share Tech';
      text-transform: uppercase;
      font-size: 19px;
      line-height: 1.3;
      letter-spacing: 0px;
      color: inherit;
      &:hover {
        color: #e8c67f;
      }
      &:active {
        opacity: 0.6;
      }
    }
    .info-row {
      line-height: 1.1;
      .label, .value {
        display: inline-block;
      }
      .label {
        padding-right: 5px;
        font-size: 14px;
        text-transform: uppercase;
        color: rgba(255,255,255, 0.4);
      }
      .value {
        padding-right: 25px;
        font-size: 16px;
        text-transform: uppercase;
        opacity: 0.8;
      }
    }
    .options {
      padding-top: 10px;
      .action-row {
        padding: 0 25px 0 20px;
        margin-right: -30px;
        cursor: pointer;
        background: rgba(0,0,0, 0);
        border-top: 1px solid rgba(255,255,255, 0.05);
        &:hover {
          background: rgba(0,0,0, 0.05);
        }
        &:active {
          opacity: 0.7;
        }
        .text {
          display: inline-block;
          padding: 0 0 3px 0;
          vertical-align: middle;
          width: calc(100% - 30px);
          .basename {
            display: inline-block;
            vertical-align: middle;
            font-size: 14px;
            color: #e8c67f;
            color: #fffa;
            text-transform: uppercase;
          }
          .details {
            display: inline-block;
            vertical-align: middle;
            padding-left: 15px;
            font-size: 14px;
            text-transform: uppercase;
            color: rgba(255,255,255, 0.5);
          }
        }
        .btn-done {
          padding: 0 0 3px 0;
          display: inline-block;
          vertical-align: middle;
          width: 20px;
          height: 40px;
          cursor: pointer;
          outline: 0;
          background: url(../../../images/arrow-right.svg) no-repeat center;
          background-size: 20px;
          border: 0;
          opacity: 0.2;
          &:hover {
            opacity: 0.9 !important;
          }
          &:active {
            opacity: 0.4 !important;
          }
        }
        &:hover {
          .basename {
            color: #e8c67f;
          }
          .btn-done {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
