.CardAISList {
  .card-title span {
    display: inline-block;
    padding-left: 5px;
    opacity: 0.5;
    text-transform: uppercase;
  }

  .card-sub-title {
    color: white;
    opacity: 0.5;
    margin-top: -12px;
    margin-bottom: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }

  p.message {
    pointer-events: none;
    padding: 30px 20px;
    font-family: 'Share Tech';
    font-size: 18px;
    text-align: center;
    text-rendering: geometricPrecision;
    text-transform: uppercase;
    color: #f9dfaab5;
    &.no-data {
      min-width: 610px;
      color: #fffa;
    }
  }

  .table-list .ReactVirtualized__List {
    outline: 0;
    padding: 0px;
  }

  .table-list {
    padding: 5px 0 10px 0;
    margin: -5px -20px -20px -20px;
    max-height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
    .table-row.header {
      padding-top: 8px;
      padding-bottom: 8px;
      padding-right: 20px;
      background: #0001;
      transition: padding .2s;
      .col {
        font-family: 'Roboto';
        font-weight: bold;
        font-size: 12px;
      }
      .text {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        margin-top: 0px;
        transition: opacity .2s .2s, margin-top .2s;
      }
      &.dismiss {
        padding-top: 4px;
        padding-bottom: 4px;
        transition: padding .2s .2s;
        .text {
          margin-top: -16px;
          opacity: 0;
          transition: opacity .2s, margin-top .2s .2s;
        }        
      }
    }
    .table-row {
      padding: 2px 20px;
      display: flex;
      align-items: center;
      line-height: 1.2;
      cursor: pointer;
      color: rgba(255,255,255, 0.7);
      text-rendering: geometricPrecision;
      text-transform: uppercase;
      opacity: 0.9;
      &:hover {
        opacity: 1;
      }
      .selected-bar {
        position: relative;
        width: 0px;
        height: 32px;

        margin-left: -20px;
        margin-top: -7px;
        margin-right: 5px;

        transition: 0.2s;
      }
      .col {
        display: inline-block;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;

        &.name {
          margin-left: 20px;
          width: 90px;
          margin-top: -5px;

          transition: padding .2s, opacity .2s;

          &.header {
            margin-left: 30px;
            width: 64px;
            margin-top: 0px;
          }
        }

        &.hidden {
          opacity: 0.5;
        }
      }
      &.activated {
        .selected-bar {
          width: 5px;
          

          &::after {
            content: '';
            position: absolute;
            top: 80%;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 3px 2px 0 0;
            border-color: #daa709 transparent transparent transparent;
          }

          &::before {
            content: '';
            position: absolute;
            top: 0px;
            width: 100%;
            height: 80%;
            left: 0px;
            background-color: #daa709;
          }
        }        
        .col.name {
          padding-left: 12px;
        }
      }

      &.all-activated {
        .selected-bar {
          width: 5px;
        }
        .col.name {
          padding-left: 12px;
        }
      }
    }
  }

  button.check {
    display: inline-block;
    vertical-align: middle;
    margin-right: -20px;
    width: 16px;
    height:16px;
    outline: 0;
    opacity: 0.4;
    cursor: pointer;
    background: url(../../../images/icon-check-circle.svg) no-repeat center;
    background-size: contain;
    border: 0;
    border-radius: 0;
    transition: 0.2s;
    &:hover {
      opacity: 0.6;
    }
    &.header {
      margin-right: 0px;
      margin-left: -16px;
    }
  }

  button.eye {
    display: inline-block;
    vertical-align: middle;
    margin-right: -20px;
    width: 16px;
    height:16px;
    outline: 0;
    opacity: 0.4;
    cursor: pointer;
    background: url(../../../images/icon-eye-opened.svg) no-repeat center;
    background-size: contain;
    border: 0;
    border-radius: 0;
    transition: 0.2s;
    &:hover {
      opacity: 0.6;
    }
    &.closed {
      opacity: 0.2;
      background-image: url(../../../images/icon-eye-closed.svg);
    }
    &.header {
      margin-right: 0px;
      margin-left: -16px;
    }
  }
}

.Card.CardAISList-container {
  min-width: 100px;
}