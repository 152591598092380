
.CardCNHVeiculos {
  width: 100%;
  margin-top: 10px;
  
  .title > span {
    padding-left: 5px;
    opacity: 0.5;
  }
  p.message {
    padding: 10px 100px 0 100px;
    font-family: 'Share Tech';
    color: #DAA709;
    text-align: center;
    text-rendering: geometricPrecision;
    font-size: 18px;
    opacity: 1;
  }
  .content {
    pointer-events: none;
    position: relative;
    padding-top: 30px;
  }

  .panels {

    display: flex;
    flex-direction: column;


    vertical-align: top;
    .panel {
      display: inline-block;
      vertical-align: top;
    }
    .panel.menu {
      max-height: 650px;
      // width: 750px;
      padding: 10px 0 5px 0;
      margin-bottom: 10px;
      overflow-x: auto;
    }
    .panel.main {
      padding-left: 15px;
      //pointer-events: none;
    }
  }
  .panel.menu .message-loading {
    margin: 10px 0;
    padding: 5px 0 5px 20px;
    font-size: 14px;
    font-family: 'Share Tech';
    text-rendering: geometricPrecision;
    color: #fff;
    opacity: 0.5;
    background: rgba(255,255,255, 0.1);
    animation: TextBlink infinite 0.3s ease alternate;
  }

  .main-message-loading {
    margin: 0;
    padding: 5px 0 5px 20px;
    font-size: 14px;
    font-family: "Share Tech";
    text-rendering: geometricPrecision;
    color: #fff;
    opacity: 0.5;
    background: rgba(255, 255, 255, 0.1);
    animation: TextBlink infinite 0.3s ease alternate;
  }

  // .panel.menu button {
  //   display: block;
  //   width: 100%;
  //   line-height: 1.1;
  //   text-align: left;
  //   cursor: pointer;
  //   outline: 0;
  //   margin: 0;

  //   padding: 7px 20px 7px 0;
  //   margin-right: 20px;


  //   text-rendering: geometricPrecision;
  //   color: rgba(255,255,255, 0.5);
  //   background: none no-repeat 90% center;
  //   background-size: 22px;
  //   border: 0;
  //   border-top: 1px solid transparent;
  //   border-bottom: 1px solid transparent;
  //   &.stolen {
  //     background-image: url(../../../../images/icon-alert.svg);
  //     background-color: rgba(0,0,0, 0.03);
  //     border-top-color: rgba(255,255,255, 0.05);
  //     border-bottom-color: rgba(255,255,255, 0.05);
  //   }
  //   span {
  //     font-family: 'Share Tech';
  //     font-size: 17px;
  //   }
  //   div {
  //     font-family: 'Roboto';
  //     font-size: 12px;
  //     opacity: 0.8;
  //   }
  //   &.selected {
  //     cursor: default;
  //     pointer-events: none;
  //     color: #fff;
  //   }
  //   &:hover {
  //     color: rgba(255,255,255, 0.8);
  //     //background-color: rgba(255,255,255, 0.05);
  //   }
  //   &:active {
  //     color: rgba(255,255,255, 0.8);
  //     opacity: 0.8;
  //   }
  // }
}