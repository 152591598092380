
.CardTornozeladosOnline {

  .card-title span {
    display: inline-block;
    padding-left: 5px;
    opacity: 0.5;
  }

  p.message {
    padding: 30px 20px;
    text-align: center;
    font-family: 'Share Tech';
    color: #DAA709;
    text-rendering: geometricPrecision;
    text-transform: uppercase;
    font-size: 18px;
    &.error {
      color: #DAA709;
    }
  }

  .filter-container {
    margin: 0 -20px 0 -20px;
    padding: 20px 20px 0 20px;
    background: #3B4046;
    border-bottom: 1px solid rgba(255,255,255, 0.1);
    border-top: 1px solid rgba(255,255,255, 0.1);
    .SwitcherOnOffContainer {
      padding-top: 5px;
      vertical-align: top;
      display: inline-block;
      width: 180px;
      label {
        font-family: 'Share Tech';
      }
    }
    .SearchFilterWords {
      vertical-align: top;
      display: inline-block;
      width: 220px;
      margin-top: -10px;
    }

    .zoom-button {
      width: 220px;
      padding: 8px 15px;
      font-family: 'Open Sans';
      text-align: center;
      font-size: 12px;
      outline: 0;
      cursor: pointer;
      color: rgba(255,255,255, 0.7);
      background: rgba(0,0,0, 0.07);
      border: 1px solid rgba(255,255,255, 0.1);
      border-radius: 3px;
      transition: 0.2s;
      &:hover {
        color: rgba(255,255,255, 0.9);
        background: rgba(0,0,0, 0.1);
        border-color: rgba(255,255,255, 0.2);
      }
      &:active {
        color: rgba(255,255,255, 0.3);
        background: rgba(0,0,0, 0.02);
        border-color: rgba(255,255,255, 0.05);
        transition: none;
      }
    }

    .row {
      display: flex;
      margin-bottom: 15px;
    }
  }

  .status-bar {
    display: block;
    width: 440px;
    margin: 0 -20px;
    pointer-events: none;
    padding: 5px;
    margin-bottom: 5px;
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    text-rendering: geometricPrecision;
    color: #fff5;
    background: #fff1;
    &.alert {
      color: #e2dcb8;
      background: #e2dcb844;
    }
  }

  .ui-list .item-row {
    &.no-pointer {
      pointer-events: none;
      cursor: default;
      color: #e2dcb8 !important;
    }
    &.error {
      span.code {
        width: 300px;
      }
      span.msg {
        vertical-align: middle;
        display: inline-block;
        margin-left: 8px;
        margin-right: -25px;
        padding: 2px 8px;
        font-size: 11px;
        text-transform: uppercase;
        color: #e2dcb8;
        background: none;
        border-radius: 3px;
        transform: translate(0,0);
      }
    }
  }
  .ui-list .column {
    &.right { width: 90px; text-align: right }
    &.code  { width: 200px }
    &.code span {
      vertical-align: middle;
      display: inline-block;
      margin-left: 15px;
      margin-right: -25px;
      padding: 2px 8px;
      font-size: 10px;
      color: #fff;
      background: #ce8b38;
      border-radius: 3px;
      transform: translate(0,-1px);
    }
  }

}
