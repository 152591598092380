
.CardTornozeladosColetas {
  .card-title {
    span {
      display: inline-block;
      padding-left: 3px;
      text-transform: uppercase;
      opacity: 0.6;
    }
  }

  .container-form {
    margin: -5px -20px 0 -20px;
    padding: 10px 20px 15px 20px;
    text-rendering: geometricPrecision;
    color: #fff;
    background: #3B4046;
    border-bottom: 1px solid rgba(255,255,255, 0.1);
    border-top: 1px solid rgba(255,255,255, 0.1);
  }

  .container-fields {
    margin-left: -5px;
    text-align: right;
    transition: 0.2s;
    &.shake {
      animation: shake infinite 1s ease alternate;
      animation-iteration-count: 1;
    }
  }

  .field {
    vertical-align: bottom;
    display: inline-block;
    padding: 2px 5px;
    label {
      display: block;
      font-family: 'Share Tech';
      text-transform: uppercase;
      text-align: left;
      line-height: 1.2;
      color: #fff;
      opacity: 0.4;
    }
    input {
      width: 155px;
      padding: 2px 5px;
      margin-bottom: 3px;
      font-size: 16px;
      font-family: 'Roboto';
      text-rendering: geometricPrecision;
      outline: 0;
      color: #fffc;
      background: rgba(255,255,255, 0.1);
      border: 1px solid rgba(255,255,255, 0.2);
      border-radius: 2px;
      &::placeholder {
        font-family: 'Roboto';
        font-style: italic;
        color: rgba(255,255,255, 0.15);
      }
      &:focus {
        color: #ffff;
        background: rgba(255,255,255, 0.15);
      }
    }
  }
  .field.action {
    display: inline-block;
    padding-right: 0;
    button {
      width: 110px;
      padding: 8px 15px;
      font-family: 'Open Sans';
      font-size: 12px;
      outline: 0;
      cursor: pointer;
      color: rgba(255,255,255, 0.7);
      background: rgba(0,0,0, 0.07);
      border: 1px solid rgba(255,255,255, 0.1);
      border-radius: 3px;
      transition: 0.2s;
      &:hover {
        color: rgba(255,255,255, 0.9);
        background: rgba(0,0,0, 0.1);
        border-color: rgba(255,255,255, 0.2);
      }
      &:active {
        color: rgba(255,255,255, 0.3);
        background: rgba(0,0,0, 0.02);
        border-color: rgba(255,255,255, 0.05);
        transition: none;
      }
    }
  }

  .container-status {
    padding-top: 15px;
    padding-bottom: 5px;
    line-height: 1;
    font-family: 'Share Tech';
    font-size: 16px;
    text-align: center;
    text-rendering: geometricPrecision;
    text-transform: uppercase;
    color: #fffa;
    transition: 0.1s;
    &.hidden {
      transition: 0.2s;
      opacity: 0;
      height: 0;
    }
    &.loading {
      animation: TextBlink infinite 0.3s ease alternate;
    }
    &.error {
      color: #f9dfaa;
      animation: TextBlink infinite 0.6s ease alternate;
    }
  }

  button.eye {
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;
    width: 30px;
    height: 18px;
    outline: 0;
    opacity: 0.4;
    cursor: pointer;
    background: url(../../../../images/icon-eye-opened.svg) no-repeat center;
    background-size: contain;
    border: 0;
    border-radius: 0;
    transition: 0.2s;
    &:hover {
      opacity: 0.6;
      &:active {
        opacity: 0.3;
        transform: scale(0.9);
        transition: none;
      }
    }
    &.closed {
      background-image: url(../../../../images/icon-eye-closed.svg);
    }
  }

  .container-table {
    margin: 0 -20px 10px -20px;
    text-transform: uppercase;
    text-rendering: geometricPrecision;
    font-family: 'Share Tech';
    font-size: 16px;
    color: #fffc;
    .row {
      cursor: pointer;
      padding: 3px 20px;
      border-bottom: 1px solid #fff1;
      &:last-child {
        border-bottom: 0;
      }
      &:hover {
        background: #ffffff08;
        &:active {
          opacity: 0.7;
        }
      }
      .col {
        display: inline-block;
        vertical-align: top;
        &.eye   { width:  50px }
        &.color { width:  50px }
        &.date  { width: 100px }
        &.time  { width: 100px }
        &.total { width: 140px }
      }
      .icon {
        display: inline-block;
        vertical-align: top;
        margin-top: 8px;
        width: 22px;
        height: 7px;
        background: #fff1;
        border-radius: 2px;
      }
      .col.total {
        text-align: right;
        span {
          display: inline-block;
          padding-left: 5px;
          font-family: 'Roboto';
          font-size: 12px;
          opacity: 0.5;
        }
      }
    }
    .row.gray {
      cursor: default;
      &:active {
        opacity: 1;
      }
      .col  { color: #fff6 }
      .icon { opacity: 0.3 }
    }
  }

}
