
@keyframes TextBlink {
  from { opacity: 0.2 }
  to   { opacity: 0.6 }
}

@keyframes TextToTransparent {
  0%   { opacity: 0.5 }
  50%  { opacity: 0.5 }
  100% { opacity: 0.0 }
}

.CardViaturasHistoricoArea {
  width: 530px;
 
  .card-title span {
    display: inline-block;
    padding-left: 5px;
    opacity: 0.7;
  }

  p.message {
    padding: 30px 20px 5px 20px;
    text-align: center;
    font-family: 'Share Tech';
    color: #fff;
    text-rendering: geometricPrecision;
    text-transform: uppercase;
    font-size: 18px;
    opacity: 0.7;
    &.empty {
      color: #DAA709;
    }
  }

  .flex-container .SwitcherOnOffContainer {
    display: inline-block;
    padding-right: 30px;
  }

  .summary {
    position: relative;
    margin: -5px -20px 0 -20px;
    padding: 10px 20px;
    background: #3B4046;
    .stream-status {
      margin-bottom: -20px;
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      font-family: 'Share Tech';
      text-align: center;
      text-rendering: geometricPrecision;
      color: #fff;
      opacity: 0.5;
      &.error {
        color: #f9dfaa;
        animation: TextBlink infinite 0.6s ease alternate;
      }
      &.done {
        opacity: 0;
        animation: TextToTransparent 8s ease alternate;
      }
      &.blink {
        animation: TextBlink infinite 0.3s ease alternate;
      }
    }
    .input {
      position: relative;
      padding-top: 10px;
      margin-top: -5px;
      input {
        width: 100%;
        padding: 2px 80px 2px 40px;
        font-size: 16px;
        color: #3B4046;
        text-transform: uppercase;
        text-rendering: geometricPrecision;
        outline: 0;
        background: #F6F8FA url(../../../../images/icon-search2.svg) no-repeat 12px center;
        border: 1px solid rgba(255,255,255, 0.4);
        border-radius: 50px;
        &::placeholder { color: #3B4046; text-transform: capitalize; }
        &:focus {
          background-color: #F6F8FA;
        }
      }
    }
    .total-hidden {
      pointer-events: none;
      position: absolute;
      top: 15px;
      right: 15px;
      color: #3B4046;
    }
    .detail span {
      vertical-align: middle;
      padding: 0 10px 0 30px;
      font-size: 12px;
      text-transform: uppercase;
      color: #fff;
      opacity: 0.5;
      text-rendering: geometricPrecision;
    }
  }

  .ReactVirtualized__List {
    outline: 0;
  }

  .table-list {
    width: 570px;
    padding: 5px 0 10px 0;
    margin: -5px -20px -20px -20px;
    max-height: 400px;
    overflow-y: auto;
    color: #fffc;
    .row.header {
      pointer-events: none;
      padding-top: 7px;
      padding-bottom: 7px;
      background: #0001;
      .col {
        font-family: 'Roboto';
        font-weight: bold;
        font-size: 12px;
      }
    }
    .row.selected {
      line-height: 1.4;
      color: #fff !important;
      background: rgba(220,200,50, 0.2) !important;
      .col.close {
        opacity: 0.6;
        background-size: 10px;
        background-image: url(../../../../images/cancel-x.svg);
        background-position: center;
        background-repeat: no-repeat;
      }
      &:hover {
        &:active .col.close {
          opacity: 0.5;
        }
      }
    }
    .row {
      padding: 5px 20px;
      line-height: 1.3;
      cursor: pointer;
      color: rgba(255,255,255, 0.7);
      text-rendering: geometricPrecision;
      text-transform: uppercase;
      opacity: 0.9;
      &:hover {
        opacity: 1;
        background: rgba(255,255,255, 0.05);
        &:active {
          opacity: 0.5;
        }
      }
      .col {
        display: inline-block;
        &.code { width:  75px }
        &.name { width: 120px }
        &.pts  { width:  75px }
        &.time { width: 245px }
        &.close {
          width: 30px;
          color: transparent;
        }
      }
    }
  }

  .table-collects-list {
    width: 570px;
    padding: 10px 0 0 0;
    margin: 0;
    max-height: 400px;
    overflow-y: auto;
    color: #fffc;
    .row {
      padding: 5px 20px;
      line-height: 1.2;
      cursor: pointer;
      color: rgba(255,255,255, 0.7);
      text-rendering: geometricPrecision;
      text-transform: uppercase;
      opacity: 0.9;
      &:hover {
        opacity: 1;
        background: rgba(255,255,255, 0.05);
      }
      .col {
        display: inline-block;
        &.time { width:  70px; margin-left: 75px }
        &.date { width:  90px }
        &.land { width:  90px; text-align: right; padding-right: 15px }
        &.stat { width: 200px }
      }
    }
  }

}

