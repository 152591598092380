
.CardInepBrasil {

  .title span {
    display: inline-block;
    padding-left: 5px;
    margin-right: 20px;
    opacity: 0.5;
  }

  .legends {
    vertical-align: middle;
    margin: -10px 0 5px 125px;
    .legend {
      display: inline-block;
      vertical-align: middle;
      line-height: 1;
      cursor: pointer;
      padding: 0 10px 0 5px;
      border-radius: 20px;
      transition: 0.1s;
      .flag {
        display: inline-block;
        width:  8px;
        height: 8px;
        background: #5a5;
        border-radius: 50%;
      }
      .name {
        display: inline-block;
        vertical-align: middle;
        padding-left: 5px;
        line-height: 1.5;
        font-size: 11px;
        text-rendering: geometricPrecision;
        text-transform: uppercase;
        color: rgba(255,255,255, 0.7);
      }
    }
    .legend:hover {
      background: rgba(255,255,255, 0.05);
      .name { color: rgba(255,255,255, 0.8) }
    }
    .legend:active {
      opacity: 0.6 !important;
      transition: none !important;
    }
    .legend.selected {
      background: rgba(0,0,0, 0.1);
      .name { color: rgba(255,255,255, 0.8) }
    }
  }
  .legends.selection {
    .legend {
      opacity: 0.7;
      filter: grayscale(100%);
      &:active {
        opacity: 0.4 !important;
        transition: none !important;
      }
      &.selected {
        opacity: 1;
        filter: grayscale(0%);
      }
    }
  }

  .container-chart {
    margin: 0 -20px;
    .row {
      padding: 1px 20px;
      margin-top: 1px;
      line-height: 1;
      cursor: pointer;
      opacity: 0.7;
      &:nth-child(even) {
        background: rgba(255,255,255, 0.03);
      }
      &:hover {
        opacity: 1;
        background: rgba(255,255,255, 0.1);
      }
      &:active {
        opacity: 0.6;
      }
      .name {
        display: inline-block;
        width: 130px;
        text-align: right;
        padding-right: 10px;
        vertical-align: middle;
        font-size: 13px;
        line-height: 1;
        text-rendering: geometricPrecision;
        color: rgba(255,255,255, 0.8);
      }
      .bar {
        display: inline-block;
        width: 200px;
        height: 11px;
        vertical-align: middle;
        line-height: 1;
        overflow: hidden;
        .life {
          display: inline-block;
          vertical-align: middle;
          line-height: 1;
          height: 18px;
          opacity: 0.8;
        }
      }
      .total {
        display: inline-block;
        width: 80px;
        text-align: right;
        vertical-align: middle;
        text-rendering: geometricPrecision;
        line-height: 1;
        font-size: 14px;
        color: rgba(255,255,255, 0.8);
      }
    }
  }

  .headerline {
    margin: 0 -20px 0 -20px;
    padding: 0 20px 10px 20px;
    background: rgba(0,0,0, 0.1);
  }
  .content-search {
    margin-top: -15px;
    padding: 0 100px 10px 100px;
  }
  .container-search {
    margin-top: -10px;
  }

  .container-header {
    margin: -5px 0 0 -20px;
    color: #fff;
    .caption {
      float: right;
      font-size: 22px;
      font-weight: lighter;
      text-rendering: geometricPrecision;
      color: rgba(255,255,255, 0.2);
    }
    button.btn-back {
      vertical-align: top;
      padding: 3px 0 3px 16px;
      margin: 0;
      outline: 0;
      cursor: pointer;
      text-rendering: geometricPrecision;
      background: none;
      border: none;
      opacity: 0.8;
      transition: 0.1s;
      &:hover {
        opacity: 0.95;
      }
      &:active {
        opacity: 0.4;
        transition: none;
      }
      .icon-left {
        display: inline-block;
        vertical-align: top;
        width: 28px;
        height: 28px;
        background: url(../../../../images/arrow-left.svg) no-repeat center;
        background-size: 18px;
      }
      .label {
        display: inline-block;
        vertical-align: top;
        padding: 5px 20px 0 2px;
        font-family: 'Roboto';
        font-size: 12px;
        color: rgba(255,255,255, 0.8);
      }
    }
  }

  .table-list .ReactVirtualized__List {
    outline: 0;
  }

  .table-list {
    padding: 10px 0;
    margin: 0 -20px -20px -20px;
    max-height: 400px;
    min-height: 300px;
    overflow-y: auto;
    .row {
      padding: 5px 20px;
      line-height: 1.2;
      cursor: pointer;
      color: rgba(255,255,255, 0.7);
      text-rendering: geometricPrecision;
      &:hover {
        background: rgba(255,255,255, 0.05);
      }
      &:active {
        opacity: 0.5;
      }
      .name {
        max-width: 400px;
        overflow: hidden;
        font-size: 13px;
      }
      .details {
        font-size: 11px;
        opacity: 0.5;
        text-transform: uppercase;
      }
    }
  }

  .content-container {
    opacity: 1;
    transition: 0.1s;
    &.loading {
      opacity: 0;
      transform: translate(0,5px);
    }
  }

  .msg-loading {
    position: absolute;
    top: 140px;
    width: 490px;
    padding: 30px 80px 20px 80px;
    text-align: center;
    font-family: 'Share Tech';
    color: #fff;
    opacity: 0.4;
    text-rendering: geometricPrecision;
    text-transform: uppercase;
    font-size: 18px;
    border-top: 1px solid rgba(255,255,255, 0.1);
  }

}
