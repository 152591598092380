.SearchBar-Tag {
  flex: 1;
  margin: 2px 5px;
  padding: 5px 14px 3px 14px;
  max-height: 33px;
  outline: 0;
  cursor: pointer;
  background: rgba(0, 0, 0, 0);
  border: 1px solid #3B4046;
  border-radius: 50px;
  transition: 0.1s;
  user-select: none;

  &:hover {
    background: rgba(255, 255, 255, 0.03);
    border-color: #3B4046;

    &:not(.last):active {
      opacity: 0.9;
      transform: scale(0.97);
      transition: none;
    }
  }

  &.hidden {
    opacity: 0;
    transform: scale(1.2);
  }

  &.last {
    cursor: default;
  }

  .label {
    display: inline-block;
    position: relative;
    bottom: 3px;
    align-self: center;
    vertical-align: top;
    font-size: 14px;
    color: #3B4046;
    font-size: 1.125rem;
    font-family: Roboto, sans-serif;
  }

  .close {
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
    margin: 0 -8px 0 8px;
    cursor: pointer;
    background: #3B4046 url(../../../images/cancel-x.svg) no-repeat 5px center;
    background-size: 8px;
    border-radius: 50px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    opacity: 0.5;

    &:hover {
      opacity: 0.7;
    }

    &:active {
      opacity: 0.3;
    }
  }
}