
.CardSispenDetalhe {
  width: 100%;
  transition: all ease 0.3s;

  .details_section {
    margin-top: 20px;
    border: 1px solid rgba(255,255,255, 0.2);
  }

  .card-title {
    margin-bottom: 15px;
    span {
      display: inline-block;
      padding-left: 5px;
      opacity: 0.6;
    }
    b {
      display: inline-block;
      padding-left: 5px;
      font-weight: normal;
      color: #FFFFFF;
    }
  }

  .tabs-container {
    display: flex;
    margin: -10px -20px 15px -20px;
    padding:  0px 20px;
    text-rendering: geometricPrecision;
    color: #fff;
    background: rgba(73, 85, 91, 0.8);
    border-bottom: 1px solid rgba(255,255,255, 0.1);
    border-top: 1px solid rgba(255,255,255, 0.1);
    .tab {
      flex: 1;
      width: 100px;
      padding: 15px;
      opacity: 0.5;
      border: 1px solid rgba(255,255,255, 0);
      border-top: 0;
      border-bottom: 0;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
        background: rgba(255,255,255, 0.04);
        border-color:rgba(255,255,255, 0.08);
      }
      &:active {
        opacity: 0.7;
      }
      &:first-child {
        opacity: 1;
        min-width: 200px;
        pointer-events: none;
      }
    }
    .tab .label {
      font-size: 13px;
      font-family: 'Roboto';
      line-height: 1;
      color: #b4bcbf;
    }
    .tab .value {
      font-family: 'Share Tech';
      font-size: 22px;
    }
    .tab.selected {
      opacity: 0.8;
      color: #fcfca8;
      background: rgba(255,255,255, 0.1);
      .label { color: #d0d28f }
    }
  }
  
  .message {
    white-space: normal;
    padding: 20px 100px 10px 100px;
    text-align: center;
    font-family: "Share Tech";
    color: #daa709;
    text-rendering: geometricPrecision;
    font-size: 18px;
  }

  .message-loading {
    margin: 0;
    padding: 5px 0 5px 20px;
    font-size: 14px;
    font-family: "Share Tech";
    text-rendering: geometricPrecision;
    color: #fff;
    opacity: 0.5;
    background: rgba(255, 255, 255, 0.1);
    animation: TextBlink infinite 0.3s ease alternate;
  }

  .content {
    overflow-y: auto;
    width: 710px;
    height: 690px;
    padding-right: 8px;
    margin-top: -5px;

    &.w-possibilities {
      height: 630px;
    }
  }

  .tab-content {
    padding: 10px;
    text-align: center;
  }

  .header-line {
    pointer-events: none;
    margin: 10px 0 5px 0;
    padding: 4px 0 5px 10px;
    font-size: 14px;
    text-align: left;
    text-transform: uppercase;
    text-rendering: geometricPrecision;
    font-weight: 600;
    color: rgba(255,255,255, 0.8);
    border-bottom: 1px solid rgba(255,255,255, 0.5);
    border-left: 3px solid rgba(255,255,255, 0.5);
    opacity: 0.7;
    overflow: hidden;
    span {
      display: inline-block;
      padding-right: 15px;
    }
    // &:before {
    //   pointer-events: none;
    //   content: ' ';
    //   position: absolute;
    //   left: 20px;
    //   margin: -5px 0 0 -30px;
    //   width: 220px;
    //   height: 30px;
    //   background: rgba(255,255,255, 0.25);
    //   transform: translateX(0px) skewX(-25deg);
    //   opacity: 1;
    //   transition: 0.2s;
    // }
  }

  .line-values {
    // pointer-events: none;
    display: flex;
    padding: 3px 0;
    margin-top: -3px;
    margin-bottom: 5px;
    .item {
      flex: 1;
      padding-right: 20px;
      text-transform: uppercase;
      text-rendering: geometricPrecision;
      .label {
        font-size: 12px;
        font-family: 'Roboto';
        color: #b4bcbf;
        opacity: 0.9;
      }
      .value {
        display: block;
        font-size: 20px;
        font-family: 'Share Tech';
        line-height: 1;
        color: rgba(255, 255, 255, 0.7);
      }
    }
    .item.max-width {
      flex-grow: 9;
    }
  }
  .line-values.text {
    .value {
      font-size: 16px;
    }
  }
  .line-values.no-border {
    border: 0;
  }
  .line-values.inline {
    display: block;
    width: 100%;
    .item {
      display: inline-block;
    }
  }

  .group {
    padding-bottom: 5px;
    &.first-group {
      margin-top: 0px;
    }
  }
  .group p.message {
    font-size: 12px;
    text-transform: uppercase;
    text-rendering: geometricPrecision;
    color: rgba(255,225,100, 0.5);
  }

  .group.columns {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    background: rgba(0, 0, 0, 0.05);
    border-top: 1px solid rgba(255,255,255, 0.2);
    .column {
      vertical-align: top;
      display: inline-block;
    }
    .column.photo {
      cursor: pointer;
      width: 190px;
      transition: 1s;
      img {
        width: 100%;
        max-width: 180px;
        // &.default {
        //   width: 144px;
        //   margin: 20px 0 0 20px;
        // }
      }
    }
    .column.info {
      vertical-align: middle;
      width: calc(100% - 200px);
      padding: 10px 0 0 20px;
      & > .line-values {
        display: block;
        width: 100%;
        padding: 2px 0;
        .item {
          display: inline-block;
        }
      }
    }
  }

  .clickable {
    // margin: -5px 0px -4px -19px;
    padding: 7px 70px 7px 19px;
    font-family: 'Share Tech';
    font-size: 16px;
    line-height: 1.2;
    text-rendering: geometricPrecision;
    cursor: pointer !important;
    color: rgba(255,255,255, 0.7);
    background: rgba(255,255,255, 0.02) url(../../../../images/arrow-right.svg) no-repeat 96% center;
    background-size: 22px;
    border-top: 1px solid rgba(255,255,255, 0.04);
    border-bottom: 1px solid rgba(255,255,255, 0.04);
    transition: 0.1s;
    &.blocked {
      pointer-events: none;
      padding: 5px 0 0 0;
      margin: 0;
      background: none;
      border: 0;
    }
    .group-info p {
      cursor: pointer;
    }
    &:hover {
      background-color: rgba(255,255,255, 0.03);
      background-position: 97% center;
    }
    &:active {
      opacity: 0.8;
      transition: none;
    }
  }

  .SearchPanel-BodyAsync .row-autocomplete-line .line-item {
    line-height: normal;
    vertical-align: text-bottom;
  }

  .return-button-container {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;

    .return-button {
      margin-bottom: 25px;
      padding: 8px 8px;
      width: fit-content;
      border-radius: 7px;
      background: #F6F8FA;
      color: #3B4046;
      align-items: center;

      font-size: 0.875rem;
      font-family: Open sans, sans-serif;
      font-weight: 700;

      cursor: pointer;
    }
  }

  .label-list {
    font-family: 'Share Tech';
    font-size: 18px;
    color: #fff;
  }
  
}

.CardSispenDetalhe.picture-mode {

  .group.columns{
    padding: 10px;
    margin: 0;
    width: 470px;

    .column.photo{
      width: 100%;
    }

    .column.photo .default{
      width: 450px;
      max-width: none;
      margin: 0;
      transition: all 0.3s ease;
    }
  }

  .column.info {
    display: none;
    opacity: 0;
    pointer-events: none;
  }
}

