
body.no-header .CardMinify {
  display: none;
}

.CardMinify {
  position: fixed;
  z-index: 10000;
  bottom: 20px;
  left: 20px;
  padding: 0 3px;
  user-select: none;
  overflow: hidden;
  background: rgba(44, 56, 62, 0.6);
  &.n0 {
    pointer-events: none;
    opacity: 0.8;
    filter: grayscale(100%);
  }
  &:hover {
    background: rgba(44, 56, 62, 0.8);
  }

  .block {
    height: 7px;
    margin: 5px 0;
    border: 1px solid #daa709;
    border-top-width: 0;
    border-bottom-width: 0;
    &.top    { border-top-width:    1px }
    &.bottom { border-bottom-width: 1px }
  }

  .content {
    position: relative;
    margin: -8px 5px;
    padding: 5px 2px;
    width: calc(100% - 10px);
    min-width: 165px;
    min-height: 17px;
    text-rendering: geometricPrecision;
  }

  .hint {
    pointer-events: none;
    position: absolute;
    z-index: 1;
    margin: -10px 0 0 20px;
    font-size: 14px;
    font-family: 'Share Tech';
    text-rendering: geometricPrecision;
    color: #fff;
    opacity: 0.7;
    transition: 0.2s ease 0.3s;
  }
  &:hover {
    .hint {
      opacity: 0;
      transition: 0s;
    }
    .content .line {
      height: 26px;
      margin-bottom: 1px;
    }
  }

  .content .line {
    overflow: hidden;
    padding: 0 5px;
    height: 0;
    color: #aaa;
    background: rgba(35, 45, 50, 0.3);
    transition: 0.1s;
    opacity: 1;
    &.hidden div.label {
      opacity: 0.7;
    }
    &.deep1 { padding-left: 10px }
    &.deep2 { padding-left: 25px }
    &.deep3 { padding-left: 40px }
    &.deep4 { padding-left: 55px }
    &.deep5 { padding-left: 70px }
    &.border-bottom {
      background: rgba(113, 100, 55, 0.8);
    }
    button {
      vertical-align: middle;
      display: inline-block;
      outline: none;
      width: 25px;
      overflow: hidden;
      min-height: 22px;
      color: transparent;
      cursor: pointer;
      pointer-events: all;
      background: transparent no-repeat center;
      background-size: 16px;
      opacity: 0.4;
      border: none;
      &:hover {
        opacity: 0.5;
      }
      &:active {
        opacity: 0.3;
        transform: scale(0.9);
        transition: none;
      }
      &.eye { background-image: url(../../images/icon-eye-opened.svg) }
      &.eye.closed { background-image: url(../../images/icon-eye-closed.svg) }
    }
    div.label {
      vertical-align: middle;
      display: inline-block;
      width: calc(100% - 25px);
      padding-left: 5px;
      padding-right: 15px;
      cursor: pointer;
      overflow: hidden;
      font-family: 'Share Tech';
      font-size: 16px;
      text-transform: uppercase;
      word-wrap: normal;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #949b9c;
      opacity: 0.9;
      &:hover {
        opacity: 1;
      }
      &:active {
        opacity: 0.8;
      }
    }
    &.hidden div.label:hover {
      cursor: default;
      opacity: 0.8 !important;
    }
  }

  .line.main {
    .eye {
      transform: translate(0, 1px);
    }
    .label.header {
      line-height: 1.1;
      text-align: right;
      button.btn-close {
        vertical-align: middle;
        width: auto;
        font-size: 14px;
        color: #fff;
        opacity: 0.8;
        text-rendering: geometricPrecision;
        transform: translate(10px,3px);
        &:hover {
          opacity: 0.9;
        }
        &:active {
          opacity: 0.6;
        }
      }
    }
  }

}
