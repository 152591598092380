
.SearchPanel-BodyOptions-Input {
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
  padding: 4px 14px;
  outline: 0;
  font-size: 12px;
  width: auto;
  min-width: 100px;
  cursor: text;
  text-transform: uppercase;
  color: rgba(255,255,255, 0.6);
  background: rgba(0,0,0, 0);
  border: 1px solid rgba(255,255,255, 0.3);
  border-radius: 3px;
  &.focus {
    background: rgba(250,250,250, 0.02);
    border-color: #DAA709;

    .label {
      color: #DAA709;

    }
    .input {
      color: rgba(255,255,255, 0.9);
    }
  }

  .label {
    line-height: 1;
    text-transform: uppercase;
    font-size: 11px;
    color:#DAA709;
    user-select: none;
    span {
      display: inline-block;
      padding-left: 5px;
      font-size: 9px;
      color: rgba(255,255,255, 0.3);
    }
  }
  .input {
    outline: 0;
    width: auto;
    line-height: 1.4;
    font-size: 14px;
    text-align: left;
    text-transform: uppercase;
    color: rgba(255,255,255, 0.6);
    background: none;
    border: 0;
    &::placeholder {
      font-family: 'Roboto';
      font-size: 14px;
      font-style: italic;
      text-transform: none;
      color: rgba(255,255,255, 0.6);
      text-rendering: geometricPrecision;
    }
  }
}
