
.CardPoliciaMilitar {

  .card-title {
    text-rendering: geometricPrecision;
    .light {
      display: inline-block;
      padding: 0 8px;
      opacity: 0.6;
    }
    .name {
      display: inline-block;
      padding-right: 50px;
      color: #DAA709;
    }
  } 

  .content {
    //pointer-events: none;
    .column {
      display: inline-block;
      vertical-align: top;
      &.picture {
        display: inline-block;
        width: 200px;
        min-height: 260px;
        height: auto;
        background: #fff;
        &.empty {
          width: 180px;
          opacity: 0.4;
          background: #fff url(../../../../images/avatar.png) no-repeat center 30%;
          background-size: 128px;
        }
        img {
          width: 100%;
        }
      }
      &.info {
        width: calc(100% - 200px);
        padding: 20px 0 0 20px;
      }
    }
  }

  .line-values {
    pointer-events: none;
    display: flex;
    padding: 3px 0;
    border-top: 1px solid rgba(255,255,255, 0.1);
    margin-top: -3px;
    margin-bottom: 5px;
    .item {
      flex: 1;
      padding-right: 20px;
      text-rendering: geometricPrecision;
      .label {
        text-transform: uppercase;
        font-size: 12px;
        font-family: 'Roboto';
        color: #b4bcbf;
        opacity: 0.9;
      }
      .value {
        display: block;
        line-height: 1;
        font-size: 20px;
        font-family: 'Share Tech';
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.7);
      }
    }
    .item.max-width {
      flex-grow: 9;
    }
  }
  .line-values.text {
    .value {
      min-width: 50px;
      font-size: 16px;
    }
  }
  .line-values.no-border {
    border: 0;
  }
  .line-values.inline {
    display: block;
    width: 100%;
    .item {
      display: inline-block;
    }
  }

}

