
.HeaderRightUser {
    user-select: none;
    transition: 0.3s;
    opacity: 0;
    transform: translate(10px,-10px);
    
    &.visible {
        opacity: 1;
        transform: translate(0,0);
    }

    .username {
        vertical-align: middle;
        margin-bottom: -8px;
        display: inline-block;
        width: 200px;
        margin-top: -5px;
        font-family: 'Share Tech';
        font-size: 16px;
        text-align: right;
        text-rendering: geometricPrecision;
        color: #678;
        span.hello {
            padding-right: 3px;
            opacity: 0.6;
        }
        span.name {
            text-transform: uppercase;
        }
        div.organization {
            width: 200px;
            padding-left: 10px;
            text-transform: uppercase;
            line-height: 0.9;
            font-size: 14px;
            color: #0004;
        }
    }

    div.userphoto {
        display: inline-block;
        vertical-align: middle;
        width:  34px;
        height: 40px;
        margin: 8px 10px 5px 10px;
        background: url(../../../images/icon-people.svg) no-repeat center bottom;
        border-radius: 50px;
    }
    img.userphoto {
        display: inline-block;
        vertical-align: middle;
        width:  44px;
        height: 44px;
        margin: 6px 5px 5px 10px;
        background: #eee;
        border-radius: 50px;
    }

    .dropmenu {
        position: relative;
        top: 0;
        width: 200px;
        margin-left: 50px;
        overflow: hidden;
        padding: 20px 10px;
        text-align: left;
        background: #fff;
        border-radius: 0 0 3px 0;
        transform-origin: center top;
        transition: 0.1s;
        transform: translate(0,0);
        &.hidden {
            pointer-events: none;
            opacity: 0;
            transform: translate(0,-10px);
            transition: 0.2s;
        }
        & > button {
            display: block;
            padding: 10px;
            outline: 0;
            cursor: pointer;
            color: #555;
            background: transparent;
            border: 0;
            text-transform: uppercase;
            text-rendering: geometricPrecision;
            font-size: 12px;
            font-family: 'Open Sans';
            width: 100%;
            opacity: 0.7;
            text-align: left;
            & > span.icon {
                vertical-align: middle;
                display: inline-block;
                width:  18px;
                height: 18px;
                margin-right: 7px;
                opacity: 0.7;
                background: transparent no-repeat center;
                background-size: contain;
                &.help     { background-image: url(../../../images/icon-help-round-button.svg)        }
                &.settings { background-image: url(../../../images/icon-settings-cogwheel-button.svg) }
                &.exit     { background-image: url(../../../images/icon-exit-to-app-button.svg)       }
            }
            & > div {
                display: inline-block;
                vertical-align: middle;
                transform: translate(2px,0);
                transition: 0.2s;
            }
            &:before {
                pointer-events: none;
                content: ' ';
                position: absolute;
                left: 20px;
                margin: -9px 0 0 -30px;
                width: 100%;
                height: 40px;
                background: rgba(0,0,0, 0.05);
                background: rgba(105, 143, 156, 0.2);
                transform: translateX(0px) skewX(-25deg);
                opacity: 0;
                transition: 0.2s;
            }
            &:hover {
                opacity: 0.95;
                color: #6d8e9e;
                &:before {
                    transform: translateX(-10px) skewX(-25deg);
                    opacity: 1;
                }
                & > span.icon {
                    opacity: 0.8;
                }
                & > div {
                    transform: translate(0,0);
                }
            }
            &:active {
                opacity: 0.7;
            }
        }
    }
}

body.is-search-focus .HeaderRightUser {
    transform: translate(10px,0);
}

  