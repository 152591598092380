
.HeaderCenterSearch {
    display: inline-block;
    width: calc(100% - 800px);
    padding-top: 3px;
    text-align: left;
    transition: 0.1s;
    cursor: text;
    .icon {
        display: inline-block;
        width: 28px;
        height:28px;
        background: url(../../../images/icon-search.svg) no-repeat center;
        background-size: contain;
        transform: translate(0,5px);
    }
    .placeholder {
        display: inline-block;
        padding: 2px 0 0 20px;
        line-height: 26px;
        font-family: 'Share Tech';
        letter-spacing: -1px;
        font-size: 26px;
        text-align: left;
        text-rendering: geometricPrecision;
        color: rgba(0,0,0, 0.3);
    }
    &:hover {
        .icon {
            transform: translate(0,5px);
        }
        .placeholder {
            color: rgba(0,0,0, 0.4);
        }
    }
    &:active .placeholder {
        color: rgba(0,0,0, 0.3);
    }
}
