// Mudança da unificação dos cards
// Adicionamos para definir a largura e altura

.CardUnified-size {
  width: 920px;
  height: 760px;
}

.Card {
  position: absolute;
  margin-bottom: 50px;
  z-index: 999999;
  top: 200px;
  left: 500px;
  min-height: 90px;
  min-width: 300px;
  padding: 20px;
  overflow: hidden;
  //overflow-y: auto;
  white-space: nowrap;
  //user-select: none;
  background: #3B4046;
  border: 2px solid rgba(202, 209, 212, 0.8);
  border-radius: 4px;
  transition: 0.15s;

  &:hover {
    background: #3B4046;
    border-color: rgba(202, 209, 212, 1);
  }

  &.hidden {
    pointer-events: none;
    opacity: 0;
    transform-origin: 50% 70%;
    transform: translate(0, 5px) scale(0.9);
    transition: 0.15s;
  }

  &.no-anim {
    transition: none;
  }

  &.bg-highlight {
    background: #77848ad9;
    transform: scale(0.99);
    transition: none;
  }

  &.blur {
    filter: blur(3px);
  }

  &.closed {
    pointer-events: none;
    opacity: 0 !important;
    transform: translate(0, 10px) scale(0.9) !important;
    transition: 0.15s;
  }

  .card-highlight {
    position: absolute;
    pointer-events: none;
    visibility: hidden;
    opacity: 1;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    margin: -18px;
    border: 2px solid rgba(255, 255, 255, 0.3);

    transform: scale(0.9) rotateZ(0deg);
    transition: 0, opacity 1s ease 1s;
  }

  &:hover .card-highlight {
    visibility: visible;
    opacity: 0;
    transform: scale(1) rotateZ(0);
    border-color: rgba(255, 255, 255, 0.1);
    transition: 0.3s, opacity 1s ease 1s;
  }

  button.card-btn-close {
    position: absolute;
    z-index: 999999999;
    top: -1px;
    right: -1px;
    width: 44px;
    height: 30px;
    cursor: pointer;
    opacity: 0.8;
    background: url(../../images/card-btn-close-container.svg) no-repeat top right;
    border: 0;
    border-radius: 0 4px 0 0;
    outline: 0;
    transition: 0.2s;

    span {
      display: inline-block;
      width: 20px;
      height: 20px;
      opacity: 0.4;
      background: url(../../images/card-btn-close-icon-x.svg) no-repeat center;
      background-size: auto;
      transform: translate(6px, -1px) rotateZ(45deg);
      transition: 0.1s;
    }

    &:hover span {
      opacity: 1 !important;
    }

    &:active span {
      transition: 0 !important;
      opacity: 0.4 !important;
      transform: translate(6px, 1px) rotateZ(0deg) !important;
    }
  }

  &:hover button.card-btn-close {
    opacity: 1;
    transform: translate(0, 0);

    span {
      opacity: 0.7;
      transform: translate(6px, 0) rotateZ(0deg);
    }
  }

  button.card-btn-minimize {
    position: absolute;
    z-index: 999999999;
    top: -1px;
    right: 40px;
    width: 29px;
    height: 22px;
    cursor: pointer;
    opacity: 0.5;
    background: none;
    border: 0;
    border-radius: 0 4px 0 0;
    outline: 0;
    transform: translate(0, -2px);
    transition: 0.1s;

    span {
      display: inline-block;
      width: 17px;
      height: 2px;
      opacity: 0.5;
      background: #b2babe;
      transform: translate(0, -2px);
      transition: 0.1s;
    }

    &:hover span {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.5 !important;
      transition: 0;
      transform: translate(0, 1px) !important;
    }
  }

  &:hover button.card-btn-minimize {
    opacity: 1;
    transform: translate(0, 0);
  }

  .accordion {
    display: flex;
    align-items: flex-start;
    background-color: #4c5055;
    border: none;
    width: 100%;

    &:hover {
      background-color: #50555a;
      cursor: pointer;
    }
  }

  .accordion_title {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 10px 0;
    padding-left: 20px;
    pointer-events: none;
    font-family: 'Share Tech';
    letter-spacing: -1px;
    font-size: 20px;
    text-rendering: geometricPrecision;
    color: rgba(255, 255, 255, 0.9);
    .accordion_base {
      padding-left: 5px;
      opacity: 0.5;
    }
    .dropdown_icon {
      margin: 0 20px 0 auto;
      height: 18px;
      width: 18px;
      transform: rotate(90deg);
      background-image: url(../../images/arrow-left-simple.svg);
      background-size: 18px;
      transition: 0.4s;

      &.closed {
        transform: rotate(-90deg);
      }
    } 
  }

  .accordion_content {
    margin: 20px 0;
    padding: 0 10px;
    transform: scaleY(1);
    transition: 0.2s;

    &.r_vertical_margin {
      padding: 0;
    }

    &.closing {
      transform: scaleY(0);
      transition: 0.2s;
    }

    &.closed {
      transition: 0;
      margin: 0;
    }
  }

  .title,
  .card-title {
    margin: -10px 0 10px 0;
    pointer-events: none;
    font-family: 'Share Tech';
    letter-spacing: -1px;
    font-size: 20px;
    text-rendering: geometricPrecision;
    color: rgba(255, 255, 255, 0.9);
  }

  .card-title {
    margin: 0 0 10px 0;
    padding-right: 55px;
  }

  .card-title.category {
    margin-bottom: 20px;

    label {
      display: block;
      font-size: 14px;
      color: #ddd;
      opacity: 0.7;
      margin: -5px 0 -8px 0;
      letter-spacing: 0;
    }

    div {
      padding-right: 40px;
    }
  }

  &.moving {
    cursor: grabbing;
    background: #3B4046;
    transition: none;
  }

  .CardBody {
    margin-top: -10px;
  }


  &.loading {
    pointer-events: none;
    background: rgba(83, 95, 101, 0);
    border: 2px solid rgba(202, 209, 212, 0);

    &>button.card-btn-close,
    &>button.card-btn-minimize,
    &>.CardBody {
      display: none;
    }
  }

  .card-error-message {
    padding: 30px 30px 50px 0;
    font-family: 'Share Tech';
    font-size: 18px;
    line-height: 1.2;
    text-rendering: geometricPrecision;
    color: #f9dfaab5;

    &>b {
      display: block;
      padding-bottom: 10px;
      font-size: 22px;
      font-weight: normal;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.5);
    }
  }

}

.card-box-minimize-animation {
  position: absolute;
  z-index: 999999;
  pointer-events: none;
  overflow: hidden;
  //user-select: none;
  background: #3B4046;
  border: 2px solid rgba(202, 209, 212, 0.8);
  border-radius: 4px;
  transition: .5s ease-out;
}

.card-row {
  display: flex;
  flex-direction: row;
  position: relative;

  .left-menu {

    // Mudança da unificação dos cards
    // Adicionamos para definir a largura e altura
    // height: 800px;
    height: 760px;
    max-width: 170px;

    background: #2F3136;
    padding: 2rem 1rem 0 1rem;
    margin: -10px 20px 0 -20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .search-origin {
      background: #2F3136;
      margin: -20px 0 10px 0;
      text-align: left;
      align-self: flex-start;

      pointer-events: none;
      font-family: 'Share Tech';
      font-size: 14px;
      text-rendering: geometricPrecision;
      color: rgba(255, 255, 255, 0.9);
      flex-wrap: wrap;

    }

    .divisor {
      height: 1px;
      width: 100%;
      background: rgba(255, 255, 255, 0.9);
    }

    .labels-container {

      padding-top: 1.5rem;

      .arrow-menu {

        padding: 5px 15px 0 15px;
        font-size: 1.25rem;

        background: url(../../images/simple-arrow-right.svg) no-repeat 15px center;
        // background-size: 8px;
      }

      .menu-labels {
        font-family: Share Tech, sans-serif;
        font-size: 1.25rem;
        color: #CAD1D4;
        line-height: 2.5rem;
        cursor: pointer;
      }

      .selected {
        color: #dba70a;
        transition: all 0.3s;
      }

    }

    .tooltip-menu-message {
      background: #E7E7E7;
      color: #2C383E;
      font-size: 0.625rem;
      font-family: Roboto, sans-serif;
      text-align: start;
      border-radius: 2px;
      width: 150px;
      height: auto;
      padding: 0.5rem;
      position: absolute;
      top: 35px;
      left: 0px;
      z-index: 2;
      white-space: normal;
    }

    .tooltip-menu-message::after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 8%;
      margin-left: 15px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #E7E7E7 transparent;
    }

    .tooltip-hidden {
      pointer-events: none;
      opacity: 0;
      display: none;
    }

    .menu-footer {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-bottom: 1.5rem;
      position: absolute;
      bottom: 0;

      .menu-divisor {
        height: 2px;
        width: 90%;
        border-top: 2px #CAD1D4 solid;
        margin-bottom: 1rem;
      }

      .create-report-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #CAD1D4;
        font-family: Share Tech, sans-serif;
        font-size: 1rem;
        cursor: pointer;

        &.selected{
          color: #dba70a;
          .report-download-icon{
            filter: invert(72%) sepia(82%) saturate(2611%) hue-rotate(7deg) brightness(97%) contrast(92%);
          }
        }

        .report-download-icon {
          width: 1rem;
          height: 1rem;
          margin-right: 5px;
          filter: invert(100%) sepia(12%) saturate(2281%) hue-rotate(170deg) brightness(90%) contrast(82%);
        }
      }
    }
  } .message-loading-menu {
    margin: 0;
    padding: 5px 35px;
    font-size: 14px;
    font-family: "Share Tech";
    text-rendering: geometricPrecision;
    color: #fff;
    opacity: 0.5;
    background: rgba(255, 255, 255, 0.1);
    animation: TextBlink infinite 0.3s ease alternate;
    position: absolute;
    bottom: 2rem;
  }

  .hidden-menu {
    display: none;
  }

	.button-rede {
		display: flex;
		align-items: center;
		letter-spacing: 0;
		padding: 8px;
		font-family: 'Share Tech';
		font-size: 16px;
		color: #fff;
		opacity: 0.75;
		cursor: pointer;
		background-size: 0;
		border-radius: 10px;
		transition: .2s;
		background-color: #DAA72C;
		border: 2px solid #2C383E;
		margin-bottom: 12px;
	}

}