
.CardBiometriaPopupDetails {

  .card-title span {
    padding-left: 10px;
    opacity: 0.75;
  }

  .row-values {
    align-items: center;
    margin: 5px -20px 10px -20px;
    padding: 15px 100px 15px 20px;
    text-rendering: geometricPrecision;
    color: #fff;
    background: rgba(73, 85, 91, 0.8) url(../../../../images/fingerprint.svg) no-repeat right center;
    border-bottom: 1px solid rgba(255,255,255, 0.1);
    border-top: 1px solid rgba(255,255,255, 0.1);
    .col {
      display: inline-block;
      padding-right: 25px;
    }
    .col .label {
      font-size: 13px;
      font-family: 'Roboto';
      line-height: 1;
      color: #b4bcbf;
    }
    .col .value {
      font-family: 'Share Tech';
      font-size: 20px;
    }
  }
}