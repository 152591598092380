
.CardFUNCEMEGeral {

  .title span {
    display: inline-block;
    padding-left: 5px;
    opacity: 0.5;
  }

  .row-values {
    display: flex;
    align-items: center;
    margin: -15px -20px 15px -20px;
    padding: 10px 20px 2px 20px;
    text-rendering: geometricPrecision;
    color: #fff;
    background: rgba(73, 85, 91, 0.8);
    border-bottom: 1px solid rgba(255,255,255, 0.03);
    border-top: 1px solid rgba(255,255,255, 0.1);
    .col {
      flex: 1;
      padding-right: 20px;
    }
    .col .label {
      font-size: 13px;
      font-family: 'Roboto';
      line-height: 1;
      color: #b4bcbf;
    }
    .col .value {
      font-family: 'Share Tech';
      font-size: 22px;
    }
    .col:last-child {
      padding-right: 0;
      text-align: right;
      button {
        padding: 8px 15px;
        font-family: 'Open Sans';
        font-size: 12px;
        outline: 0;
        cursor: pointer;
        color: rgba(255,255,255, 0.7);
        background: rgba(0,0,0, 0.05);
        border: 1px solid rgba(255,255,255, 0.1);
        border-radius: 3px;
        transition: 0.2s;
        &:hover {
          color: rgba(255,255,255, 0.9);
          background: rgba(0,0,0, 0.1);
          border-color: rgba(255,255,255, 0.2);
        }
        &:active {
          color: rgba(255,255,255, 0.3);
          background: rgba(0,0,0, 0.02);
          border-color: rgba(255,255,255, 0.05);
          transition: none;
        }
      }
    }
  }

  p.message {
    padding: 30px 20px;
    text-align: center;
    font-family: 'Share Tech';
    color: #fffa;
    text-rendering: geometricPrecision;
    font-size: 18px;
    &.empty {
      color: #DAA709;
    }
    &.error {
      color: #DAA709;
    }
  }

  .summary {
    position: relative;
    margin: -15px -20px 0 -20px;
    padding: 10px 20px;
    background: rgba(0,0,0, 0.1);
    input {
      width: 100%;
      padding: 2px 80px 2px 40px;
      font-size: 16px;
      color: #3B4046;
      text-transform: uppercase;
      text-rendering: geometricPrecision;
      outline: 0;
      background: #F6F8FA url(../../../../images/icon-search2.svg) no-repeat 12px center;
      border: 1px solid rgba(255,255,255, 0.4);
      border-radius: 50px;
      &::placeholder { color: #3B4046 ; text-transform: capitalize; }
      &:focus {
        background-color: #F6F8FA;
      }
    }
    .total-hidden {
      position: absolute;
      top: 15px;
      right: 35px;
      color: #3B4046;
    }
    .btn-circle {
      display: none;
    }
  }

  .container-table {
    position: relative;
    margin: 0 -20px -20px -20px;
    padding: 0 0 20px 0;
    overflow-y: auto;
    max-height: 300px;
    vertical-align: middle;
    text-rendering: geometricPrecision;
    color: rgba(255,255,255, 0.9);
    table {
      width: 100%;
      min-width: 500px;
    }
    thead {
      th {
        padding: 5px 10px;
        background: rgba(255,255,255, 0.05);
      }
    }
    table tbody {
      pointer-events: none;
    }
    table.pointer tbody {
      pointer-events: all;
      tr {
        cursor: pointer;
      }
      tr:active {
        opacity: 0.8 !important;
      }
    }
    tbody tr {
      padding: 1px 20px;
      color: rgba(255,255,255, 0.5);
      background: rgba(255,255,255, 0);
      opacity: 1;
    }
    tbody tr {
      &:hover {
        color: rgba(255,255,255, 0.9);
        opacity: 1 !important;
        background: rgba(255,255,255, 0.07);
      }
      &:nth-child(even) {
        background: rgba(255,255,255, 0.03);
        &:hover {
          background: rgba(255,255,255, 0.06);
        }
      }
      td {
        padding: 2px 10px;
      }
    }

    .code {
      width: 70px;
      text-align: right;
    }
    td.color {
      width: 30px;
      padding: 0;
      margin: 0;
      text-align: center;
      .circle {
        display: inline-block;
        margin: 0 5px;
        width: 8px;
        height: 8px;
        background: #aaa;
        border-radius: 50%;
      }
    }
    td.cap {
      text-align: right;
    }
    tr {
      th:last-child, td:last-child {
        padding-right: 20px;
        text-align: right;
      }
    }
  }

  .chart.colors {
    text-align: left;
    margin: -10px -20px 0 -20px;
    padding: 20px 50px;
    background: rgba(0,0,0, 0.05);
    .bar {
      display: inline-block;
      .value {
        display: block;
        margin-top: -15px;
        font-family: 'Share Tech';
        font-size: 14px;
        cursor: pointer;
        color: #fff;
        transform: translate(0,-10px);
        opacity: 0.8;
        &:hover {
          opacity: 1;
        }
        &:active {
          opacity: 0.7;
          transform: translate(0,-9px);
        }
      }
    }
  }

  .error-message {
    pointer-events: none;
    padding: 40px 0 30px 0;
    font-family: 'Share Tech';
    font-size: 18px;
    text-align: center;
    text-rendering: geometricPrecision;
    text-transform: uppercase;
    color: #DAA709;
    &.no-data {
      min-width: 610px;
      color: #fffa;
    }
    }


  

}
