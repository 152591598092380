
.Thermometer {
  position: absolute;
  z-index: 10;
  top: 80px;
  left: 10px;
  width: 400px;
  height: 425px;
  transform: scale(1);
  transform-origin: 90px 130px;
  transition: 0.5s;
  &.hidden {
    pointer-events: none;
    opacity: 0;
    transform: scale(0.95);
  }
}
