.CardSispenColetas {
  margin-top: 20px;
  border: 1px solid rgba(255,255,255, 0.2);


  .card-title span {
    display: inline-block;
    padding-left: 5px;
    opacity: 0.5;
  }


  .timeline {
    margin: -10px 0 20px 0;
    text-align: center;
    .chart {
      padding: 0 50px;
      vertical-align: bottom;
      .space {
        vertical-align: bottom;
        display: inline-block;
        width: 50px;
        height: 0;
        margin-bottom: 5px;
        border-bottom: 1px dashed rgba(255,255,255, 0.4);
      }
      .landmark {
        vertical-align: bottom;
        display: inline-block;
        text-align: left;
        text-rendering: geometricPrecision;
        font-family: 'Share Tech';
        cursor: pointer;
        width: 24px;
        opacity: 0.8;
        transition: 0.1s;
        &:hover {
          opacity: 1;
          transition: none;
          label {
            color: rgba(255,255,255, 0.7);
          }
        }
        &:active {
          opacity: 0.6;
          transition: none;
        }
        label {
          cursor: pointer;
          display: inline-block;
          margin: 0 0 0 12px;
          transform: translate(-50%,0);
          color: rgba(255,255,255, 0.5);
        }
        button {
          display: block;
          width: 12px;
          height: 12px;
          margin: 0 auto;
          padding: 0;
          outline: 0;
          background: none;
          border-radius: 6px;
          border: 1px solid #ccc;
          &:after {
            content: ' ';
            position: absolute;
            width: 6px;
            height: 6px;
            margin: -3px;
            border-radius: 8px;
            background: #fff;
            opacity: 0;
            transition: 0.1s;
          }
        }
      }
      .landmark:hover {
        button:after {
          opacity: 0.2;
        }
      }
      .landmark:active {
        label {
          transform: translate(-50%,1px);
        }
      }
      .landmark.selected {
        opacity: 1;
        label {
          color: #fff;
        }
        button {
          border-color: #fff;
        }
        button:after {
          opacity: 1
        }
      }
    }
  }
  &.many .timeline {
    label {
      transition: 0.1s;
    }
    .space {
      width: 11px;
    }
    .landmark label { opacity: 0 }
    .landmark:hover label { opacity: 0.8 !important }
    .landmark:active label { opacity: 0.6 }
    .landmark.selected label { opacity: 1 }
    .chart:hover .landmark.selected label { opacity: 0 }
  }


  .hands-container {
    position: relative;
    // margin: 5px -20px;
    // padding: 0 20px;
    background: rgba(0,0,0, 0.03);
    border-top: 1px solid rgba(255,255,255, 0.2);
    border-bottom: 1px solid rgba(255,255,255, 0.2);
  }
  .hands {
    vertical-align: top;
    position: relative;
    display: inline-block;
    width: 240px;
    height: 100px;
    padding: 10px 10px 0 10px;
    margin-bottom: -1px;
    border-bottom: 3px solid transparent;
    transition: 0.2s;
    &.selected {
      background: rgba(255,255,255, 0.05);
      border-bottom-color: #daa709;
      &.E .esquerda { color: rgba(255,255,255, 0.5) }
      &.D .direita  { color: rgba(255,255,255, 0.5) }
    }
    .picture-hands {
      width: 220px;
      height: 90px;
      background: url(../../../../images/hands.svg) no-repeat left top;
      .label {
        position: absolute;
        top: 60px;
        font-size: 18px;
        font-family: 'Open Sans';
        text-rendering: geometricPrecision;
        color: rgba(255,255,255, 0.2);
        transition: 0.2s;
        &.esquerda { left: 56px }
        &.direita  { left: 174px }
      }
      .finger {
        position: absolute;
        top: 0;
        left: 0;
        width: 14px;
        height: 14px;
        padding: 0;
        opacity: 0.4;
        outline: 0;
        cursor: pointer;
        background: rgba(255,255,255, 0.1);
        border: 1px solid #fff;
        border-radius: 10px;
        transition: 0.1s;
        &:hover {
          opacity: 0.7;
          background-color: rgba(255,255,255, 0.5);
        }
        &:active {
          transform: scale(1.3);
          transition: none;
          opacity: 0.4;
        }
        &.selected {
          margin: -1px 0 0 -1px;
          width: 16px;
          height: 16px;
          opacity: 1;
          background-color: rgba(255,255,255, 0.1);
          border: 2px solid #daa709;
        }
        &.disabled {
          pointer-events: none;
          visibility: hidden;
        }
      }
    }
  }

  .tab-buttons {
    vertical-align: top;
    display: inline-block;
    // width: calc(100% - 220px);
    // margin: 0 0 -10px 0;
    // padding: 0 0 0 20px;
    button {
      display: inline-block;
      padding: 15px 15px 0 15px;
      margin-bottom: -1px;
      height: 100px;
      // min-width: 80px;
      outline: 0;
      text-align: left;
      text-rendering: geometricPrecision;
      font-size: 12px;
      font-family: 'Roboto';
      line-height: 1;
      cursor: pointer;
      opacity: 0.5;
      color: #b4bcbf;
      text-transform: uppercase;
      background: transparent;
      border: 0;
      border-bottom: 3px solid transparent;
      transition: 0.2s;
      &:hover {
        opacity: 0.7;
        transition: none;
      }
      &:active {
        opacity: 0.4;
        transition: none;
      }
      &.selected {
        opacity: 1;
        background-color: rgba(255,255,255, 0.05);
        border-bottom-color: #daa709;
      }
      b {
        display: block;
        font-size: 26px;
        line-height: 1.5;
        font-family: 'Share Tech';
        color: rgba(255,255,255, 0.7);
      }
    }
  }

  .coletas-content {
    padding: 10px 20px 20px 20px;
    // margin: -5px -20px -20px -20px;
    // width: 785px;
    min-height: 150px;
    // max-height: 500px;
    overflow-y: auto;
    text-align: center;
    h1 {
      color: rgba(255,255,255, 0.2);
      text-align: center;
      text-rendering: geometricPrecision;
      transform: translate(0, 100px);
    }
    .message.empty {
      pointer-events: none;
      padding-top: 45px;
      font-size: 18px;
      font-family: 'Share Tech';
      text-align: center;
      text-transform: uppercase;
      text-rendering: geometricPrecision;
      color: #f9dfaa;
      opacity: 0.7;
    }
  }

  .coletas-content .fingerprint {
    display: inline-block;
    max-width: 200px;
    text-align: center;
    img {
      display: inline-block;
      width: 100%;
      min-height: 50px;
      min-width: 100px;
      background: rgba(255,255,255, 0.1);
    }
    label {
      display: block;
      width: 100%;
      padding: 5px 10px;
      text-rendering: geometricPrecision;
      text-transform: uppercase;
      font-size: 12px;
      color: rgba(255,255,255, 0.7);
      background: rgba(0,0,0, 0.2);
    }
  }

  .coletas-content .photos {
    text-align: center;
    white-space: normal;
    .photo {
      vertical-align: top;
      display: inline-block;
      max-width: 300px;
      img {
        display: block;
        width: 100%;
        min-height: 50px;
        min-width: 100px;
        background: rgba(255,255,255, 0.1);
      }
    }
    label {
      display: block;
      width: 100%;
      padding: 5px 10px;
      text-rendering: geometricPrecision;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 1.1;
      color: rgba(255,255,255, 0.7);
      background: rgba(0,0,0, 0.2);
    }
  }

  .coletas-content .voices {
    padding-top: 20px;
    .audio {
      padding: 5px 0 10px 0;
      audio {
        display: block;
        width: 100%;
        height: 55px;
        padding: 0px 30px;
        background: #f1f3f4;
        border-radius: 0;
      }
      label {
        display: block;
        width: 100%;
        padding: 5px 10px;
        text-rendering: geometricPrecision;
        text-transform: uppercase;
        font-size: 12px;
        color: rgba(255,255,255, 0.7);
        background: rgba(0,0,0, 0.2);
      }
    }
  }

  .coletas-content .videos {
    .video {
      padding: 5px 0 10px 0;
      video {
        display: block;
        width: 100%;
      }
      label {
        display: block;
        width: 100%;
        padding: 5px 10px;
        text-rendering: geometricPrecision;
        text-transform: uppercase;
        font-size: 12px;
        color: rgba(255,255,255, 0.7);
        background: rgba(0,0,0, 0.2);
      }
    }
  }
  .coletas-content .videos {
    padding-top: 20px;
    .downloadbar {
      text-align: left;
      background: #fff;
      padding: 0 10px;
      .link {
        vertical-align: middle;
        display: inline-block;
        padding: 10px 0;
        width: calc(100% - 110px);
        text-rendering: geometricPrecision;
        overflow-x: hidden;
        font-size: 14px;
        color: #777;
      }
      a {
        vertical-align: middle;
        display: inline-block;
        width: 110px;
        padding: 5px 20px;
        text-align: center;
        font-family: 'Open Sans';
        font-size: 11px;
        font-weight: bold;
        text-decoration: none;
        color: rgba(81,92,99, 0.8);
        background: rgba(0,0,0, 0.1);
        border-radius: 3px;
        transition: 0.1s;
        &:hover {
          background: rgba(0,0,0, 0.15);
        }
        &:active {
          opacity: 0.8;
          transition: none;
        }
      }
    }
  }

  .coletas-content {
    .fingerprint, .photo {
      margin: 4px 4px 10px 4px;
      padding: 2px;
      cursor: pointer;
      transition: 0.1s;
      border: 2px solid rgba(255,255,255, 0);
      &:hover {
        border-color: rgba(255,255,255, 0.1);
      }
      &:active {
        opacity: 0.9;
        transform: scale(0.98);
        border-color: rgba(255,255,255, 0.05);
      }
    }
    &.maximized {
      .fingerprint, .photo {
        max-width: 100%;
      }
    }
  }

}
