
.CardBuscaFonetica {

  .title {
    display: flex;
    margin-bottom: 0;
    padding-top: 10px;
    .col {
      vertical-align: top;
      &.b {
        padding-left: 8px;
        padding-right: 50px;
        div {
          font-size: 16px;
          line-height: 1.1;
          opacity: 0.6;
        }
      }
    }
  }
  .title.nominal .col.b {
    padding-top: 6px;
    font-size: 18px;
  }

  .parent-name {
    line-height: 1;
    font-family: 'Share Tech';
    font-size: 16px;
    color: rgba(255,255,255, 0.5);
  }

  .message {
    padding: 50px 20px 20px 20px;
    text-align: center;
    font-family: 'Share Tech';
    color: #DAA709;
    text-rendering: geometricPrecision;
    font-size: 18px;
    &.warn {
      color: #f9dfaa;
      opacity: 0.7;
    }
  }

  .MenuBar {
    margin-top: 10px;
    min-width: 570px;
    .input {
      float: right;
      position: relative;
      width: 250px;
      margin: 9px 0 0 10px;
      input {
        width: 100%;
        padding: 2px 80px 2px 40px;
        font-size: 16px;
        color: #3B4046;
        text-transform: uppercase;
        text-rendering: geometricPrecision;
        outline: 0;
        background: #F6F8FA url(../../../../images/icon-search2.svg) no-repeat 12px center;
        border: 1px solid rgba(255,255,255, 0.4);
        border-radius: 50px;
        &::placeholder { color: #3B4046; text-transform: capitalize; }
        &:focus {
          background-color: #F6F8FA;
        }
      }
      .total-hidden {
        pointer-events: none;
        position: absolute;
        padding: 2px 10px;
        top: 3px;
        right: 5px;
        font-size: 14px;
        text-rendering: geometricPrecision;
        color: #3B4046;
      }
    }
  }

  .content {
    margin: 0 -20px -20px -20px;
    padding: 15px 5px;
    max-height: 400px;
    min-height: 200px;
    overflow-y: auto;
  }

  .content .row {
    padding: 5px 20px;
    cursor: pointer;
    text-rendering: geometricPrecision;
    opacity: 0.7;
    background: rgba(255,255,255, 0);
    &.disabled {
      pointer-events: none;
      cursor: default;
    }
    .column {
      display: inline-block;
      vertical-align: top;
      &.photo {
        width: 150px;
      }
      &.info {
        width: calc(100% - 150px);
        padding-top: 5px;
      }
    }
    &:hover {
      opacity: 1;
      background: rgba(255,255,255, 0.03);
    }
    &:active {
      background: rgba(0,0,0, 0);
      transition: none;
      .info {
        opacity: 0.8;
      }
    }
  }

  .content .row {
    img {
      pointer-events: none;
      position: relative;
      display: block;
      width: 128px;
      height: 128px;
      background: #fff no-repeat center;
      &.empty {
        background-image: url(../../../../images/avatar.png);
        background-size: 92px;
      }
    }
    .name {
      font-family: 'Share Tech';
      font-size: 18px;
      color: rgba(255,255,255, 0.9);
    }
    .line {
      line-height: 1;
      label {
        display: inline-block;
        width: 50px;
        padding-right: 5px;
        font-size: 13px;
        text-align: right;
        color: rgba(255,255,255, 0.5);
      }
      b {
        font-family: 'Share Tech';
        font-size: 15px;
        font-weight: normal;
        color: rgba(255,255,255, 0.8);
      }
    }
  }

}
