.CNHVeiculosCarDocument {
  position: relative;
  top: 0;
  transition: 0.4s;
  padding-bottom: 20px;

  padding-right: 35px;
  width: 720px;

  &.hidden {
    display: none;
  }

  .row {
    cursor: default;
    // pointer-events: none;
    display: flex;

    margin: 0 -10px 0 -15px;
    
  }
  .row > .container-item {
    flex: 1;
    &.flex2 {
      flex: 2;
    }
    &.yellow .item {
      border-color: #d8c316;
      label:before { background: #d8c316 }
      .value { color: #d8c316 }
    }
    .item {
      width: 100%;
    }
  }

  .container-item {
    padding: 5px 10px 5px 0;
    text-rendering: geometricPrecision;
    & > .item {
      position: relative;
      display: inline-block;
      padding: 0 15px 5px 10px;
      border: 1px solid rgba(255,255,255, 0.2);
      border-top: 0;
      border-right: 0;
      border-radius: 0 0 3px 3px;
      &.min80 {
        min-height: 80px;
      }
      label {
        display: block;
        margin: 0;
        margin-top: -8px;
        padding: 0 5px;
        font-family: 'Open Sans';
        font-size: 12px;
        color: rgba(255,255,255, 0.5);
        &:before {
          content: ' ';
          position: absolute;
          left: 0;
          top:  0px;
          width: 8px;
          height: 1px;
          background: rgba(255,255,255, 0.2);
        }
      }
      .value {
        font-family: 'Share Tech';
        font-size: 17px;
        color: #fff;
      }
    }
  }

  .group .row .container-item.yellow {
    text-transform: uppercase;
    .value {
      text-transform: uppercase;
      color: rgba(226, 217, 143, 0.9);
      span {
        display: inline-block;
        padding: 0 7px;
      }
    }
  }
}

