
.CardTornozeladosDetalhe {

  .card-title {
    margin-bottom: 5px;
    span {
      display: inline-block;
      padding-left: 3px;
      text-transform: uppercase;
      opacity: 0.6;
    }
  }

  .container-lines {
    pointer-events: none;
    margin: 3px -20px 0 -20px;
    padding: 10px 20px 5px 20px;
    text-rendering: geometricPrecision;
    text-transform: uppercase;
    color: #fff;
    background: #3B4046;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    .line {
      line-height: 1.2;
      padding: 5px 0 5px 0;
      .item {
        display: inline-block;
        padding: 0 20px 0 0;
        .label {
          display: block;
          margin: 0;
          margin-top: -8px;
          padding: 0;
          font-family: 'Open Sans';
          font-size: 12px;
          color: rgba(255, 255, 255, 0.5);
        }
        .value {
          font-family: 'Share Tech';
          font-size: 16px;
          color: #fffc;
        }
      }
    }
  }

  .table {
    text-rendering: geometricPrecision;
    margin: 10px -20px -20px -20px;
    padding-bottom: 15px;
    .row {
      padding: 3px 0;
      vertical-align: middle;
      border-top: 1px solid #fff1;
      &.header {
        padding: 6px 0 2px 0;
        font-size: 13px;
        border-top: 0;
        .column {
          font-weight: bold;
        }
      }
      &.data {
        cursor: pointer;
        background: #00000008;
        &:hover {
          background: #ffffff08;
          &:active {
            background: #ffffff07;
            opacity: 0.7;
          }
        }
      }
    }
    .column {
      vertical-align: middle;
      display: inline-block;
      width: 100px;
      padding: 1px 20px;
      text-rendering: geometricPrecision;
      color: #fffa;
      line-height: 1;
      &.area    { width: 100px }
      &.inicio  { width: 150px }
      &.tipo    { width:  95px }
      &.horario { width: 105px }
      &.semana  { width: 160px }
      &.local   { width: 450px;  text-transform: uppercase; line-height: 1.1 }
    }
  }

  .table .column {
    &.inicio, &.horario, &.tipo {
      & > div {
        height: 20px;
        line-height: 18px;
      }
    }
  }

  .table .column.local {
    .description {
      line-height: 1.2;
    }
    .address {
      font-family: 'Roboto';
      font-size: 12px;
    }
  }

  .table .weekday-timerules {
    padding-top: 2px;
    height: 22px;
    .day {
      display: inline-block;
      vertical-align: top;
      text-align: center;
      font-family: 'Roboto';
      padding: 3px 0 0 0;
      margin-right: 2px;
      width: 18px;
      height: 18px;
      font-size: 12px;
      color: #fff8;
      background: #fff1;
      border-radius: 3px;
      &.on {
        color: #efe486;
        background: #efe48655;
      }
    }
  }

}
