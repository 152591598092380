.spi-oauth2-button {
  display: inline-block;
  width: 270px;
  padding: 6px 35px 5px 35px;
  overflow: hidden;
  cursor: pointer;
  outline: 0;
  background: #DAA709;
  border-radius: 50px;
  border: 3px solid rgba(0, 0, 0, 0.01);
  box-shadow: 0 6px 30px -10px #5c5c5c;

  &:hover {
    background: #DAA709;
    border-color: rgba(0, 0, 0, 0.05);
  }

  &:active {
    border-color: transparent;
    transform: scale(0.99);
    transition: none;
  }

  .column.icon {
    display: inline-block;
    vertical-align: middle;
    width: 60px;
    height: 50px;
    opacity: 0.8;
    background: url(../../images/icon-btn-login.svg) no-repeat center bottom;
    background-size: contain;
    transition: 0.2s;
    transform: translate(-2px, 2px);
  }

  .column.text {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 62px);
    text-align: left;
    line-height: 1.2;
    padding: 2px 0 10px 0;
    margin-left: -5px;

    .main {
      font-family: 'Open Sans';
      font-size: 22px;
      font-style: normal;
      color: #fff;
      opacity: 0.8;
      transition: 0.2s;
    }

    .help {
      font-family: 'Open Sans';
      font-size: 10px;
      font-style: normal;
      letter-spacing: 3px;
      color: #fff;
      opacity: 0.5;
      transition: 0.2s;
    }
  }

  &:hover .column.icon {
    opacity: 1;
    transform: translate(2px, 2px);
  }

  &:hover .column.text {
    .main {
      opacity: 1;
      transform: translate(0, 6px);
    }

    .help {
      opacity: 0;
      transform: translate(0, -2px);
    }
  }

  &:active .column {
    opacity: 0.7 !important;
    transform: translate(0, 1px);
    transition: none;
  }
}