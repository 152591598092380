.CardHistoricoCriminal {
  width: 100%;
  margin-top: 10px;

  .error-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    padding-right: 6px;
    margin: 5px 0;
    
    .error-message {
      display: flex;
      align-items: center;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.7);
      background-color: #ce3e3e70;
      padding: 5px;
      margin: 0;
      width: 100%;

      .alert-icon {
        display: flex;
        margin-right: 5px;
        margin-top: -1px;
        width: 25px;
        height: 25px;
        background: url(../../../../images/icon-alert.svg) no-repeat center;
      }
    }
  }

  .message {
    padding: 0 100px 10px 100px;
    font-family: "Share Tech";
    text-align: center;
    color: #DAA709;
    text-rendering: geometricPrecision;
    font-size: 18px;
  }

  .message-loading {
    margin: 0;
    padding: 5px 0 5px 20px;
    font-size: 14px;
    font-family: "Share Tech";
    text-rendering: geometricPrecision;
    color: #fff;
    opacity: 0.5;
    background: rgba(255, 255, 255, 0.1);
    animation: TextBlink infinite 0.3s ease alternate;
  }

  .return-button-container {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;

    &.unlink{
      .return-button {
        margin-bottom: 6px;
      }
    }

    .return-button {
      margin-bottom: 25px;
      padding: 8px 8px;
      width: fit-content;
      border-radius: 7px;
      background: #F6F8FA;
      color: #3B4046;
      align-items: center;

      font-size: 0.875rem;
      font-family: Open sans, sans-serif;
      font-weight: 700;

      cursor: pointer;
    }
  }

  .label-list {
    font-family: 'Share Tech';
    font-size: 18px;
    color: #fff;
  }

  .pagination-button-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    .pagination-button {
      display: inline;
      padding: 8px;
      margin: 0 5px;
      width: fit-content;
      background: #F6F8FA;
      color: #3B4046;
      border-radius: 5px;

      font-size: 0.875rem;
      font-family: Open sans, sans-serif;
      font-weight: 700;

      cursor: pointer;
    }
  }
}

.SearchRows {
  .row-autocomplete-line {
    font-size: 14px;
    line-height: 1.1;
    color: rgba(255, 255, 255, 0.9);

    .line-item {
      display: inline-block;
      margin-right: 10px;

      .label {
        display: inline-block;
        vertical-align: middle;
        padding: 0 5px 0 25px;
        min-width: auto;
        font-size: 12px;
        text-transform: uppercase;
        text-align: left;
        opacity: 0.4;
      }

      .value {
        display: inline-block;
        vertical-align: middle;
        color: rgba(255, 255, 255, 0.7);
        text-align: left;
        padding-left: 5px;
      }
    }
  }

  .row-autocomplete-line .line-item {
    .label {
      padding-left: 0;
    }
  }
}