.slider {
  width: auto;
  min-width: 1.2em;
  float: left;
  margin-left: 2rem;

  input {
    display: inline-block;
    padding-right: 0.25em;
    margin-right: -999px;
    transform-origin: top left;
    transform: rotate(90deg) translate(-100%);
    -webkit-appearance: none !important;
    height: 1px;
    background: #3b4046;
  }
}

.slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  width: 71px;
  height: 8px;
  background: #daa709;
}

.slider input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none !important;
  width: 71px;
  height: 8px;
  background: #daa709;
}
