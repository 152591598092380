
.HeaderRight {
    position: fixed;
    z-index: 9999999;
    top:   -20px;
    right: -10px;
    width: 400px;
    height: 80px;
    display: flex;
    padding: 20px;
    justify-content: flex-end;
    background: url(../../../images/layout-header-right-white.svg) no-repeat left bottom;
    transform: translate(60px,-90px);
    transition: 0.5s;
    transition-delay: 0.2s;

    &.visible {
        transform: translate(0,0);
    }
}

body.no-header .HeaderRight {
    transform: translate(0,-80px);
    transition: 0.2s;
}

body.is-search-focus .HeaderRight {
    transform: translate(+20px,0);
    transition: 0.2s;
}
