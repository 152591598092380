
html {
  background: #303b41;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #303b41;
}

div {
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(202, 209, 212, 0.2);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #b2b9bc;
    outline: 0;
  }
}

.ant-message {
  top: auto;
  bottom: 20px;
  z-index: 9999999999;
}
