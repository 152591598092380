
.CardCivilSindionibus {

  .MenuBar {
    margin-top: -15px;
    .buttons > button {
      letter-spacing: -1px;
      text-transform: uppercase;
    }
  }

  .message {
    padding: 0 50px 20px 50px;
    font-family: 'Share Tech';
    font-size: 18px;
    text-align: center;
    text-rendering: geometricPrecision;
    color: #DAA709;
    opacity: 1;
  }

  .content {
    position: relative;
    &.single {
      margin-top: -30px;
    }
  }

}
