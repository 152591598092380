.CardBiometriaGeoHistory {

  .card-title.category span {
    display: inline-block;
    padding-left: 5px;
    opacity: 0.7;
  }

  .area-info {
    .col {
      display: inline-block;
      padding: 5px 20px 5px 0;
      font-size: 18px;
      color: #fff;
      opacity: 0.8;
    }
  }

  p.empty {
    pointer-events: none;
    padding: 30px 20px;
    text-align: center;
    font-family: 'Share Tech';
    color: #DAA709;
    text-rendering: geometricPrecision;
    font-size: 18px;
    &.white {
      color: #DAA709;
      padding-bottom: 0;
    }
  }

  .container {
    align-items: center;
    margin: -15px -20px 0 -20px;
    padding: 10px 20px 15px 20px;
    text-rendering: geometricPrecision;
    color: #fff;

    background: #3B4046;
    border-bottom: 1px solid rgba(255,255,255, 0.1);
    border-top: 1px solid rgba(255,255,255, 0.1);
  }

  .officers {
    margin: 0 -20px -20px -20px;
    padding-top: 10px;
    max-height: 300px;
    overflow-y: auto;
    h1 {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 5px 20px;
      font-family: 'Share Tech';
      text-rendering: geometricPrecision;
      line-height: 1;
      font-size: 18px;
      color: #fff;
      opacity: 0.9;
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
      span {
        flex: 1;
        align-content: space-between;
        &.info {
          text-align: right;
          padding-left: 7px;
          font-size: 16px;
          opacity: 0.8;
          b {
            font-size: 34px;
          }
        }
      }
    }
    table {
      width: 100%;
      margin: 10px 0 20px 0;
      text-align: left;
      text-rendering: geometricPrecision;
      opacity: 0.7;
      color: #fff;
      td {
        cursor: pointer;
        vertical-align: middle;
        padding: 4px 10px;
        font-family: 'Open Sans';
        &.id {
          padding-left: 20px;
          text-align: right;
          color: rgba(255,255,255, 0.5);
        }
        &.name {
          padding-right: 40px;
        }
        &.total {
          padding-left: 10px;
          text-align: right;
        }
        &.chart {
          text-align: right;
          padding: 0 20px 2px 0;
          .bar {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            width: 100px;
            height: 15px;
            text-align: left;
            background: rgba(255,255,255, 0.1);
            .life {
              display: block;
              width: 0;
              height: 100%;
              background: #DEED66;
            }
          }
        }
      }
    }
  }

  .officers table {
    tr {
      opacity: 0.9;
      transition: 0.1s;
    }
    tr:hover {
      opacity: 1;
      background: rgba(0,0,0, 0.1);
    }
    tr:active {
      opacity: 0.5;
      transition: none;
    }
  }
  .officers.selected table {
    tr {
      opacity: 0.5;
      filter: grayscale(100%);
      &.selected {
        opacity: 1;
        background: rgba(0,0,0, 0.1);
        filter: none;
      }
      &:active {
        opacity: 0.4;
      }
    }
  }

}
