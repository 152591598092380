
@keyframes TextBlink {
  from { opacity: 0.2 }
  to   { opacity: 0.6 }
}

@keyframes TextToTransparent {
  0%   { opacity: 0.5 }
  50%  { opacity: 0.5 }
  100% { opacity: 0.0 }
}

.CardTornozeladosHistoricoArea {
  min-width: 510px;
 
  .card-title span {
    display: inline-block;
    padding-left: 5px;
    opacity: 0.7;
  }

  p.message {
    padding: 30px 20px 5px 20px;
    font-size: 18px;
    font-family: 'Share Tech';
    text-align: center;
    text-rendering: geometricPrecision;
    text-transform: uppercase;
    color: #fff;
    opacity: 0.7;
    &.empty {
      color: #DAA709;
    }
  }

  .flex-container .SwitcherOnOffContainer {
    display: inline-block;
    padding-right: 30px;
  }

  .summary {
    position: relative;
    margin: -5px -20px 0 -20px;
    padding: 10px 20px;
    background: #3B4046;
    .stream-status {
      margin-bottom: -20px;
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      font-family: 'Share Tech';
      text-align: center;
      text-rendering: geometricPrecision;
      color: #fff;
      opacity: 0.5;
      &.error {
        color: #f9dfaa;
        animation: TextBlink infinite 0.6s ease alternate;
      }
      &.done {
        opacity: 0;
        animation: TextToTransparent 8s ease alternate;
      }
      &.blink {
        animation: TextBlink infinite 0.3s ease alternate;
      }
    }
    .input {
      position: relative;
      padding-top: 10px;
      margin-top: -5px;
      input {
        width: 100%;
        padding: 2px 80px 2px 40px;
        font-size: 16px;
        color: #3B4046;
        text-transform: uppercase;
        text-rendering: geometricPrecision;
        outline: 0;
        background: #F6F8FA url(../../../../images/icon-search2.svg) no-repeat 12px center;
        border: 1px solid rgba(255,255,255, 0.4);
        border-radius: 50px;
        &::placeholder { color: #3B4046; text-transform: capitalize; }
        &:focus {
          background-color: #F6F8FA;
        }
      }
    }
    .total-hidden {
      pointer-events: none;
      position: absolute;
      top: 15px;
      right: 15px;
      color: #3B4046;
    }
    .detail span {
      vertical-align: middle;
      padding: 0 10px 0 30px;
      font-size: 12px;
      text-transform: uppercase;
      color: #fff;
      opacity: 0.5;
      text-rendering: geometricPrecision;
    }
  }

  .ui-list {
    margin-top: 15px;
    .column {
      padding: 0 10px;
      &.code  { width: 80px }
      &.collects  { width: 90px }
      &.time-range  { width: 300px; text-align: center; }
    }
  }

  .table-container {
    margin: 10px -20px -20px -20px;
    padding-bottom: 20px;
    max-height: 500px;
    overflow-y: auto;
    table.vehicles {
      width: 100%;
      min-width: 550px;
      td {
        padding: 5px 10px;
        text-rendering: geometricPrecision;
        cursor: pointer;
        color: rgba(255,255,255, 0.8);
        opacity: 0.8;
        &.date  { width: 80px }
        &.collects { width: 90px; text-align: right }
        &.text { text-align: center }
        &.hour  { width: 70px }
        &.close { width: 45px }
      }
      tr td:first-child { padding-left: 30px }
      tr:hover td {
        opacity: 1;
        background: rgba(0,0,0, 0.05);
      }
      tr:active td {
        opacity: 0.8;
        transition: none;
      }
      tr.selected td {
        color: rgba(255,255,255, 1);
        background: rgba(220,200,50, 0.2);
      }
    }
  }

  .table-container.selected {
    tr.selected td.close {
      opacity: 0.6;
      background-size: 10px;
      background-image: url(../../../../images/cancel-x.svg);
      background-position: center;
      background-repeat: no-repeat;
    }
    tr.selected:hover td.close {
      opacity: 0.8;
    }
    tr.selected:active td.close {
      opacity: 0.5;
    }
    tr:not(.selected) {
      display: none;
    }
  }

  .table-collects {
    margin: 10px -20px -20px -20px;
    .ReactVirtualized__List {
      outline: 0;
    }
    .row {
      div {
        display: inline-block;
        width: 140px;
        padding: 5px 10px;
        text-rendering: geometricPrecision;
        cursor: default;
        color: rgba(255,255,255, 0.8);
        opacity: 0.8;
        &.time {
          width: 120px;
          margin-left: 75px;
          padding-left: 40px;
        }
        &.coord {
          padding-right: 5px;
          text-align: right;
          font-size: 12px;
          color: rgba(255,255,255, 0.3);
        }
      }
    }
    .row:hover {
      opacity: 1;
      background: rgba(0,0,0, 0.05);
    }
  }

}

