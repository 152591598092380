
.HeaderCenter {
    position: fixed;
    z-index: 999999;
    top: 0;
    top: -60px;
    left:0;
    width: 100%;
    height: 50px;
    text-align: center;
    background: rgba(214,216,218, 0.8);
    transform: translate(0,60px);
    transition: 0.2s;
    user-select: none;
    &.slow {
        transition: 0.5s;
    }
    &.hidden {
        transform: translate(0,0);
    }
    &.minimized {
        transition: 0.2s;
        transform: translate(0,22px);
        .HeaderCenterSearch {
            opacity: 0;
        }
    }
}

body.no-header .HeaderCenter {
    transform: translate(0,-50px);
    transition: 0.2s;
}

body.is-search-focus .Header {
    height: 58px;
    background: rgba(214,216,218, 0.95);
    transition: 0.2s;
}
