
.CardCameraStream {
  max-width: 600px;

  .title span {
    display: inline-block;
    padding-left: 5px;
    opacity: 0.5;
  }

  .error-message {
    padding: 30px 80px 20px 80px;
    text-align: center;
    font-family: 'Share Tech';
    color: #DAA709;
    opacity: 0.4;
    text-rendering: geometricPrecision;
    text-transform: uppercase;
    font-size: 18px;
    border-top: 1px solid rgba(255,255,255, 0.1);
  }

  .video-container {
    position: relative;
    margin: 0 -20px;
    min-width: 350px;
    min-height: 100px;
    background: #3B4046;
    .message {
      padding: 30px 50px 20px 50px;
      font-family: 'Share Tech';
      font-size: 20px;
      text-align: center;
      text-rendering: geometricPrecision;
      color: #DAA709;
    }
    img {
      pointer-events: none;
      position: relative;
      width: 100%;
    }
  }

  .ui-btn-fullscreen {
    position: absolute;
    outline: 0;
    z-index: 99;
    bottom: 10px;
    right: 5px;
    width: 35px;
    height: 18px;
    cursor: pointer;
    opacity: 0.7;
    background: url(../../../../images/icon-fullscreen.svg) no-repeat center;
    background-size: contain;
    border: none;
    transition: 0.2s;
    &:hover {
      opacity: 0.9;
    }
    &:active {
      opacity: 0.4;
      transition: none;
    }
    &.hidden {
      display: none;
    }
  }

  .details {
    pointer-events: none;
    margin: 10px 0 -10px 0;
    line-height: 1.1;
    font-family: 'Share Tech';
    font-size: 14px;
    text-rendering: geometricPrecision;
    text-transform: uppercase;
    color: rgba(255,255,255, 0.6);
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      line-height: 1.1;
      padding: 0;
      margin: 2px 0;
      white-space: normal;
    }
    .quality {
      vertical-align: middle;
      padding-left: 20px;
      label {
        vertical-align: middle;
      }
      .select-input {
        display: inline-block;
        padding: 2px 9px 2px 3px;
        margin-left: 5px;
        background: #b3babdcc;
        border-radius: 3px;
      }
      select {
        pointer-events: initial;
        vertical-align: middle;
        background: none;
        color: #444;
        outline: none;
        border: 0;
      }
    }
  }

}
