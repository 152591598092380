.CardFactionsEventsSearch {

  .MapCircleSelection .fields {
    text-align: center;
  }

  .card-title.category span {
    display: inline-block;
    padding-left: 5px;
    opacity: 0.7;
  }

  p.empty {
    pointer-events: none;
    padding: 30px 20px;
    text-align: center;
    font-family: 'Share Tech';
    color: #DAA709;
    text-rendering: geometricPrecision;
    font-size: 18px;
    &.white {
      color: #fffa;
      padding-bottom: 0;
    }
  }

  .container {
    align-items: center;
    margin: -15px -20px 0 -20px;
    padding: 10px 20px 15px 20px;
    text-rendering: geometricPrecision;
    color: #fff;
    background: #3B4046;

    border-bottom: 1px solid rgba(255,255,255, 0.1);
    border-top: 1px solid rgba(255,255,255, 0.1);
  }

  .ui-list .column {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.type       { width: 200px }
    &.date       { width: 150px }
    &.assignment { width: 370px }
  }

}