.CardCivilHistorico {
  width: 100%;

  .card-title-civil {

    display: flex;
    pointer-events: none;
    font-family: 'Share Tech';
    letter-spacing: -1px;
    font-size: 20px;
    text-rendering: geometricPrecision;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 30px;
    padding-right: 55px;

    span {
      padding-left: 5px;
      opacity: 0.5;
    }
  }


  .MenuBar {
    margin-top: -15px;
  }

  p.message {
    padding: 0 100px 10px 100px;
    text-align: center;
    font-family: "Share Tech";
    color: #daa709;
    text-rendering: geometricPrecision;
    font-size: 18px;
  }

  .message-loading {
    margin: 0;
    padding: 5px 0 5px 20px;
    font-size: 14px;
    font-family: "Share Tech";
    text-rendering: geometricPrecision;
    color: #fff;
    opacity: 0.5;
    background: rgba(255, 255, 255, 0.1);
    animation: TextBlink infinite 0.3s ease alternate;
  }
}