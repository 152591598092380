.CardEnelHistorico {
  width: 100%;

  .card-title {
    text-rendering: geometricPrecision;
    span {
      display: inline-block;
      padding-left: 5px;
      opacity: 0.6;
    }
  }

  .message {
    padding: 25px 100px 50px 100px;
    font-family: 'Share Tech';
    color: #DAA709;
    text-align: center;
    text-rendering: geometricPrecision;
    font-size: 18px;
    &.white {
      color: #DAA709;
    }
  }

  .message-loading {
    margin: 0 20px 0 0;
    padding: 5px 0 5px 20px;
    font-size: 14px;
    font-family: 'Share Tech';
    text-rendering: geometricPrecision;
    color: #fff;
    opacity: 0.5;
    background: rgba(255,255,255, 0.1);
    animation: TextBlink infinite 0.3s ease alternate;
  }

  .container-filter {
    position: relative;
    //margin: -5px -20px 0 -20px;
    margin: -5px 0;
    padding: 10px 20px;
    background: #3B4046;

    .SearchFilterWords {
      margin: -10px 20px 0 20px;
    }
  }

  .content {
    //margin: 10px -20px -20px -20px;
    margin: 10px 0;
    padding-bottom: 10px;
    .ReactVirtualized__List {
      outline: 0;
      padding: 10px 0;
    }
    .row {
      padding: 8px 0 0 0;
      cursor: default;
      border-top: 1px solid #ccc1;
      .line {
        font-family: 'Roboto';
        text-rendering: geometricPrecision;
        line-height: 1.1;
        color: #fff;
        font-size: 14px;
        opacity: 0.8;
        label {
          vertical-align: middle;
          display: inline-block;
          width: 90px;
          padding-right: 5px;
          text-align: right;
          color: rgba(255,255,255, 0.5);
        }
        & > div {
          display: inline-block;
          vertical-align: middle;
          padding-right: 10px;
          width: calc(100% - 90px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-family: 'Share Tech';
          font-size: 15px;
          color: #fff;
        }
      }
    }
    &.done .row:hover {
      background: #ffffff05;
    }
  }
}
