
.TimelineRoutes {
  margin: 0 -20px -20px -20px;
  &.no-interaction {
    cursor: default;
  }

  &.is-moving {
    cursor: col-resize;
  }
  
  .container {
    position: relative;
  }

  .routes {
    padding: 12px 40px 2px 50px;
    background: rgba(255,255,255, 0.1);

    .route {
      & > button.eye {
        position: absolute;
        display: inline-block;
        vertical-align: middle;
        margin: 10px 0 0 -25px;
        width: 18px;
        height:18px;
        outline: 0;
        opacity: 0.4;
        cursor: pointer;
        background: url(../../../../images/icon-eye-opened.svg) no-repeat center;
        background-size: contain;
        border: 0;
        border-radius: 0;
        transition: 0.2s;
        &:hover {
          opacity: 0.6;
        }
        &:active {
          opacity: 0.3;
          transform: scale(0.9);
          transition: none;
        }
      }
      & > .label {
        pointer-events: none;
        padding: 5px 0 0 0;
        text-rendering: geometricPrecision;
        color: #fff;
      }
      & > .traces {
        pointer-events: none;
        .trace-container {
          display: inline-block;
          width: 60px;
          height: 20px;
          .trace {
            margin: 0 auto;
            width: calc(100% - 4px);
            height: 5px;
            background: rgba(255,255,255, 0.2);
          }
        }
      }
      &.eye-closed {
        & > button.eye {
          background-image: url(../../../../images/icon-eye-closed.svg);
        }
        & > .label {
          opacity: 0.5;
        }
        & > .traces .trace {
          background: rgba(255,255,255, 0.1) !important;
        }
      }
    }
  }

  .timeline {
    padding-left: 50px;
    vertical-align: middle;
    background: rgba(255,255,255, 0.05);
    .time {
      display: inline-block;
      width: 60px;
      padding: 5px 0;
      font-size: 12px;
      text-rendering: geometricPrecision;
      vertical-align: middle;
      text-align: center;
      cursor: pointer;
      color: rgba(255,255,255, 0.6);
      &:hover {
        color: #fff;
      }
      &:active {
        color: rgba(255,255,255, 0.7);
      }
      &.selected {
        font-weight: bold;
        color: #fff;
      }
    }
    &.hidden .time {
      opacity: 0.1;
      transition: 0.2s;
    }
  }
  &.no-interaction .timeline .time {
    cursor: default;
  }
  &.no-labels .timeline .time {
    opacity: 0.1;
    transition: 0.2s;
  }

  .control {
    pointer-events: none;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 60px;
    height: 100%;
    margin-left: 50px;
    background: rgba(255,255,255, 0.1);
    transition: 0.2s;
    &.moving {
      transition: none;
    }
    & > div {
      position: absolute;
      bottom: 4px;
      font-size: 12px;
      font-weight: bold;
      text-rendering: geometricPrecision;
      color: #fff;
      padding: 2px 5px;
      background: #70797d;
      background: none;
      transition: 0.2s;
      opacity: 1;
      &.begin {
        left: 0;
        transform: translate(-100%,0);
        border-right: 1px solid #fff;
      }
      &.end {
        right: 0;
        transform: translate(+100%,0);
        border-left: 1px solid #fff;
      }
    }
    &.no-labels > div {
      opacity: 0;
    }
    &.one-label {
      background: #838b8f9e;
      animation: timelineRoutesOpacityLoop 0.35s ease infinite alternate;
      & > div.begin {
        left: 1px;
        width: 50px;
        font-size: 12px;
        text-align: center;
        transform: translate(-50%,1px);
        border: 0;
      }
      & > div.end {
        opacity: 0;
      }
    }
  }
}

@keyframes timelineRoutesOpacityLoop {
  from { opacity: 0.3 }
  to   { opacity: 0.9 }
}

