.involved-container {
  display: flex;
  margin: 0 -20px -20px -20px;

  .involved-tabs {
    width: 180px;
    max-height: 650px;
    margin-top: -3px;
    padding: 8px 0 20px 0;
    overflow-y: auto;
    border-right: 1px solid rgba(255,255,255, 0.1);
    border-top: 1px solid rgba(255,255,255, 0.1);

    .tab-separator {
      width: 100%;
      height: 1px;
      background-color: #FFF2;
      margin: 8px 0;
    }


    button {
      display: block;
      width: 100%;
      line-height: 1.1;
      text-align: left;
      outline: 0;
      margin: 0;
      padding: 7px 20px;
      text-rendering: geometricPrecision;
      color: rgba(255,255,255, 0.5);
      cursor: pointer;
      background: none no-repeat 90% center;
      background-size: 22px;
      border: 0;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;

      div {
        font-family: 'Share Tech';
        font-size: 14px;
        text-transform: uppercase;
        opacity: 0.8;
      }
      &.selected {
        cursor: default;
        pointer-events: none;
        color: #fff;
        background-color: rgba(255,255,255, 0.1);
        border-top-color: rgba(255,255,255, 0.07);
        border-bottom-color: rgba(255,255,255, 0.07);
      }
      &:hover {
        color: rgba(255,255,255, 0.8);
        background-color: rgba(255,255,255, 0.05);
      }
      &:active {
        color: rgba(255,255,255, 0.8);
        opacity: 0.8;
      }
    }
  }

  .cpf-button {
    display: flex;
    align-items: center;
    letter-spacing: 0;
    padding-left: 1px;
    margin-right: 20px;
    font-family: 'Share Tech';
    font-size: 16px;
    color: #fff;
    opacity: 0.75;
    cursor: pointer;
    background-size: 0;
    border-bottom: 1px solid rgba(255,255,255, 0.5);
    transition: .2s;

    .cpf-button-image {
      margin-left: -10px;
      margin-right: 0px;
      width: 12px;
      height: 12px;
      background:url(../../../../images/icon-search-16x16.svg) no-repeat 0 center;
      background-size: 12px;
      opacity: 0;
      transition: .2s;
    }

    &:hover {
      border-bottom-color: #ccb64a;
      opacity: 0.8;
      margin-right: 0;

      .cpf-button-image {
        margin-left: 8px;
        margin-right: 2px;
        opacity: 1;
      }
    }
    &:active {
      border-bottom-color: #ccb64a;
      opacity: 0.6;
    }
  }
}