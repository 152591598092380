.HelpHeaderLeft {
  width: 100vw;
  background: #fff;
  box-shadow: 0 5px 5px rgba(0, 0, 0, .25);
}

.HelpHeaderLeftAppLogo {
  width: 365px;
}

.HelpHeaderLeftParceiros {
  left: 355px;
}
