
@keyframes TextBlink {
  from { opacity: 0.2 }
  to   { opacity: 0.6 }
}

.CardCVLISituacaoAtual {

  .card-title {
    span {
      display: inline-block;
      padding-left: 5px;
      opacity: 0.5;
      &.years {
        vertical-align: top;
        padding-left: 10px;
        letter-spacing: 1px;
        font-size: 14px;
      }
    }
  }

  .loading-message {
    position: absolute;
    top: 15px;
    right: 45px;
    text-align: right;
    font-family: 'Share Tech';
    color: #fff;
    text-rendering: geometricPrecision;
    font-size: 14px;
    animation: TextBlink infinite 0.3s ease alternate;
  }

  .tabs {
    margin: 0 -20px;
    padding: 0 20px;
    text-align: center;

    margin: -5px -20px 4px -20px;

    background: rgba(0,0,0, 0.1);
    border-top: 1px solid rgba(255,255,255, 0.1);
    border-bottom: 1px solid rgba(255,255,255, 0.1);
    button {
      display: inline-block;
      vertical-align: top;
      width: 120px;
      padding: 7px 0 5px 0;
      font-family: 'Share Tech';
      font-size: 14px;
      letter-spacing: 1px;
      text-align: center;
      text-rendering: geometricPrecision;
      line-height: 1.1;
      color: #fff;
      cursor: pointer;
      background: rgba(0,0,0, 0);
      outline: 0;
      border: 0;
      opacity: 0.5;
      transition: 0.1s;
      &:hover {
        opacity: 0.7;
        background: rgba(0,0,0, 0.02);
      }
      &:active {
        opacity: 0.4;
        background: rgba(0,0,0, 0);
        transform: scale(0.98);
        transition: none;
      }
      div {
        font-family: 'Roboto';
        font-size: 13px;
        letter-spacing: 0;
        color: rgba(255,255,255, 0.7);
      }
    }
    button.selected {
      opacity: 0.9;
      background: rgba(0,0,0, 0.07);
    }
  }

  .chart {
    margin: 5px -20px -20px -20px;
    padding: 20px 0;
    text-rendering: geometricPrecision;
    background: rgba(0,0,0, 0.05);
    .row {
      vertical-align: middle;
      padding: 0 0 0 20px;
      cursor: pointer;
      .label {
        display: inline-block;
        vertical-align: middle;
        width: 50px;
        font-family: 'Share Tech';
        font-size: 16px;
        line-height: 1;
        color: rgba(255,255,255, 0.5);
      }
      .lifebar {
        top: -1px;
        position: relative;
        vertical-align: middle;
        display: inline-block;
        width: 100px;
        height: 10px; /* 8px */
        line-height: 1;
        background: rgba(255,255,255, 0.03);
        .life {
          position: relative;
          vertical-align: middle;
          display: inline-block;
          top: -4px;
          height: 100%;
          line-height: 1;
          background: #fff;
        }
        .value {
          position: absolute;
          top: -2px;
          left: 110px;
          font-family: 'Share Tech';
          font-size: 14px;
          line-height: 1;
          color: #fff;
          opacity: 0.5;
        }
      }
      .total {
        display: inline-block;
        margin-left: 70px;
        font-family: 'Share Tech';
        font-size: 14px;
        text-align: center;
        text-rendering: geometricPrecision;
        color: rgba(255,255,255, 0.5);
        opacity: 0.7;
        span {
          display: inline-block;
          &.left, &.right {
            width: 50px;
            text-align: right;
          }
          &.right {
            text-align: left;
          }
          &.center {
            width: 20px;
            text-align: center;
          }
        }
      }
    }
    .row.green .life { background: #479260 }
    .row.yellow {
      .life { background: #bda763 }
      .value { color: #ffe38d; opacity: 0.7 }
    }
    .row:not(.maximized):hover {
      background: rgba(255,255,255, 0.05);
      .lifebar {
        background: rgba(255,255,255, 0);
      }
      .total {
        opacity: 1
      }
    }
    .row:not(.maximized):active {
      opacity: 0.7;
    }
  }

  .chart .bars {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    width: 170px;
    height: 22px;
    margin-top: -3px;
    .label {
      display: inline-block;
      vertical-align: middle;
      width: 40px;
      font-size: 14px;
      font-family: 'Share Tech';
      color: rgba(255,255,255, 0.5);
      &.left {
        text-align: right;
        padding-right: 3px;
      }
      &.right {
        text-align: left;
        padding-left: 3px;
      }
    }
    .life {
      display: inline-block;
      vertical-align: middle;
      height: 10px;
    }
    .spacer {
      display: inline-block;
      vertical-align: middle;
      width: 1px;
      height: 100%;
      background: #c3c9cc;
    }
  }
  .chart .bars {
    &.negative {
      .right { visibility: hidden }
    }
    &.positive {
      .left { visibility: hidden }
    }
  }

  .chart .row {
    transition: 0.1s;
    background: rgba(255,255,255, 0);
    .content {
      display: none;
      width: calc(100% - 20px);
      margin-bottom: 10px;
      border-bottom: 1px solid rgba(255,255,255, 0.1);
    }
  }
  .chart .row.maximized {
    background: rgba(255,255,255, 0.05);
    .bars {
      height: 30px;
    }
    .content {
      display: block;
    }
  }
  .chart .row .content .columns {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .chart .row .content .column {
    flex: 1;
    width: 1px;
    max-width: 50px;
    text-align: center;
    .verticalbar-container {
      display: flex;
      flex: 1;
      align-items: flex-end;
      justify-content: center;
      height: 75px;
      div {
        margin: 0 2px;
        width: 8px;
        background: #aaa;
        &.old { background: rgba(200,200,200, 0.5) }
        &.new { background: rgba(255,255,255, 0.7) }
        .hint {
          position: relative;
          font-family: 'Share Tech';
          font-size: 14px;
          color: #eee;
          opacity: 0.8;
          background: none;
          transform: translate(0,-20px);
        }
      }
    }
    .label {
      display: flex;
      flex: 1;
      width: auto;
      padding-top: 3px;
      align-items: center;
      text-align: center;
      justify-content: center;
      font-family: 'Share Tech';
      font-size: 14px;
      color: #fff;
      opacity: 0.9;
      &.value {
        padding-top: 0;
        opacity: 0.7;
      }
    }
  }
  .chart .legend {
    padding: 5px 0 10px 0;
    .circle {
      display: inline-block;
      width: 8px;
      height: 8px;
      margin: 0 5px 0 0;
      background: #fff;
      border-radius: 50%;
      &.old { background: rgba(200,200,200, 0.5) }
      &.new { background: rgba(255,255,255, 0.7) }
    }
    .name {
      display: inline-block;
      padding: 0 10px 0 0;
      font-family: 'Share Tech';
      font-size: 14px;
      text-align: left;
      color: rgba(255,255,255, 0.7);
    }
    .tip {
      display: inline-block;
      font-family: 'Share Tech';
      font-size: 14px;
      color: rgba(255,255,255, 0.3);
    }
  }

  .filter-button-row{
    display: flex;
    justify-content: flex-end;
    user-select: none;


    .dropdown-label {
      position: relative;
      display: inline-block;
      color: #F6F8FA;
      font-family: Open sans, sans-serif;
      font-size: 1rem;
      font-weight: bold;
      padding: 0.375rem 0;
      margin-right: 0.5rem;
      margin-bottom: 6px;
  
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 7px;
      cursor: pointer;
    }

    .dropdown-button {
      position: relative;
      display: inline-block;
      background: #F6F8FA;
      color: #3B4046;
      font-family: Open sans, sans-serif;
      font-size: 0.875rem;
      font-weight: bold;
      width: 170px;
      padding: 0.375rem 1.5rem;
      margin-bottom: 6px;
  
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 7px;
      cursor: pointer;
    }

    .arrow-icon{
      width: 22px;
      height: 22px;
      margin-left: 0.5rem;
      background: url(../../../../images/dropdown-arrow.svg) no-repeat center;
      background-size: 14px;
    }
   
    .dropdown-content {
      display: block;
      position: absolute;
      top: 95px;
      right: 20px;
      z-index: 1;

      min-width: 170px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      padding: 12px 14px;

      background: #F6F8FA;
      color: #3B4046;
      border-radius: 7px;
      justify-content: center;
  
      font-family: Open sans, sans-serif;
      font-size: 0.875rem;
      font-weight: bold;
      
      cursor: pointer;
      user-select: none;
    }

    .dropdown-content::after{
      content: "";
      position: absolute;
      bottom: 100%;
      right: 15%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #F6F8FA transparent;
    }
  
    .dropdown-hidden {
      display: none;
    }
  
    .dropButton-row{
      border-bottom: 1px solid #3B4046;
      padding: 10px 0;
    }
  
    .dropButton-row:last-child{
      border-bottom: none;
    }
  }
}
