.spi-oauth2-login-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2C383E url(../../images/streets.svg) no-repeat center fixed;
  background-size: cover;
  transition: 0.3s;

  &.hidden {
    opacity: 0;
    z-index: 0;
    pointer-events: none;
    transition-delay: 0.3s;
  }

  .horizontal-bar {
    position: fixed;
    z-index: 1;
    left: 0;
    right: 0;
    top: 45%;
    height: 70vh;
    max-height: 300px;
    background: #ECECEC;
    opacity: 0.5;
    transform: translate(0, -50%) scale(1, 1);
    transform-origin: center;
    transition: 0.4s;
  }

  &.hidden .horizontal-bar {
    transform: translate(0, -50%) scale(1, 0);
    transition-delay: 0.2s;
  }

  .main-supesp-logo {
    position: absolute;
    margin-left: 10px;
    transform: translateY(0px);
    top: calc(22vh - 130px);
    background-image: url("../../images/supesp-logo-large.png");
    background-repeat: no-repeat;
    background-size: calc(70vh - 130px);
    height: 15vh;
    width: 50vw;
    opacity: 1;
    transition: 0.4s;
  }

  &.hidden .main-supesp-logo {
    transform: translateY(-50px);
    opacity: 0;
  }

  .main-shape {
    position: fixed;
    z-index: 2;
    left: -50%;
    right: 25%;
    top: calc(45% - 190px);
    height: 400px;
    background: #ECECEC;
    opacity: 0.7;
    transform: translate(0, 0) skew(-25deg, 0);
    transition: 0.5s ease 0.5s;
  }

  &.hidden .main-shape {
    opacity: 0;
    transform: translate(-50px, 0) skew(-25deg, 0);
    transition-delay: 0.1s;
  }

  .form {
    position: fixed;
    z-index: 4;

    top: 45%;
    left: 50%;

    width: 350px;
    max-width: 700px;
    text-align: center;
    transform: translate(-50%, -50%);
    transition: 0.3s ease 1.2s;

    display: flex;
    width: 600px;
    justify-content: center;
    align-items: center;
    border-radius: 22px;
    flex-direction: column;

    &>.application {
      padding: 10px 0;
      line-height: 1.1;
      font-family: 'Saira Condensed';
      font-size: 32px;
      color: #777;
    }

    &>.version {
      display: inline-block;
      padding: 2px 15px 3px 15px;
      font-size: 14px;
      color: hsla(0, 0%, 33%, 0.47);
      background: #e0e0e0;
      border-radius: 50px;
      font-family: 'Open Sans';
      text-rendering: geometricPrecision;
      opacity: 0.99;
      margin-bottom: 20px;
    }

    &>.text {
      padding: 20px;
      font-family: 'Open Sans';
      font-size: 14px;
      font-style: italic;
      line-height: 1.3;
      color: #aaa;
    }

    &>button {
      display: block;
      width: 250px;
      margin: 40px auto 0 auto;
      padding: 2px 25px 1px 25px;
      transform: translate(0, 0);
      transition: 0.3s ease 1.3s;

      .column.icon {
        width: 50px;
        height: 40px;
      }

      .column.text .main {
        font-size: 22px;
      }

      .column.text .help {
        letter-spacing: 3px;
      }
    }
  }

  &.hidden .form {
    opacity: 0;
    transform: translate(-50%, -40%);
    transition-delay: 0.2s;

    &>button {
      opacity: 0;
      transform: translate(0, -10px);
      transition-delay: none;
    }
  }

  .footer-access {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 440px;
    height: 70px;
    transition: 0.3s ease 1s;

    display: flex;

    .logos-container {
      z-index: 6;
      display: flex;
      gap: 8px;
      margin-right: 40px;


      .gov-logo {
        width: 32px;
        height: auto;
        background: url(../../images/gov-logo.png) no-repeat center;
        z-index: 6;
        transition: 0.1s ease 1.5s;
      }

      .pca-logo {
        width: 32px;
        height: auto;
        background: url(../../images/pca-logo.png) no-repeat center;
        z-index: 6;
        transition: 0.1s ease 1.4s;
      }

      .supesp-logo {
        width: 32px;
        height: auto;
        background: url(../../images/supesp-logo.png) no-repeat center;
        z-index: 6;
        transition: 0.1s ease 1.3s;
      }
    }

    .footer-shape {
      position: absolute;
      top: 0;
      right: 0;
      width: 75%;
      height: 100%;
      background: #ECECEC;
      opacity: 0.3;
      transform: translate(25px, 0) skew(25deg, 0);
    }
  
    .footer-text {
      position: absolute;
      top: 25px;
      right: 20px;
      line-height: 1.5;
      font-family: 'Open Sans';
      font-size: 12px;
      text-align: right;
      color: #ffffff;
  
      font-weight: 400;
      line-height: 28px;
    }
  }

  &.hidden .footer-access {
    opacity: 0;
    transition-delay: 0.1s;
    transform: translate(50px, 0) skew(25deg, 0);

    .logos-container {
      .gov-logo {
        opacity: 0;
        transform: scale(0);
      }

      .pca-logo {
        opacity: 0;
        transform: scale(0);
      }

      .supesp-logo {
        opacity: 0;
        transform: scale(0);
      }
    }
  }

  &.hidden .footer {
    opacity: 0;
    transform: translate(20px, 20px);
    transition-delay: 0s;
  }

  .spacer {
    height: 50px;
  }

}

.modal-overlay {
  position: absolute;
  background: #000000;
  opacity: 0.3;
  width: 100%;
  height: 100vh;
  transition: 0.2s ease 0.2s;

  z-index: 3;

  &.hidden {
    opacity: 0;
    transform: scaleY(0, 0);
  }
}