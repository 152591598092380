
.HeaderRightNotifications {
    user-select: none;
    vertical-align: middle;
    display: inline-block;
    width: 28px;
    height: 28px;
    margin: 12px 30px 2px 10px;
    padding: 16px 20px;
    font-size: 16px;
    text-align: right;
    cursor: pointer;
    color: #555;
    background: url(../../../images/icon-notification.svg) no-repeat center;
    opacity: 0;
    transition: 0.3s;
    &.visible {
        opacity: 1;
    }
    &:hover {
        transition: 0.1s;
        transform: translate(0,1px);
        b {
            transform: translate(3px,-19px);
        }
    }
    &:active {
        transition: none;
        transform: translate(0,1px) scale(0.98);
        opacity: 0.7;
        b {
            transition: none;
            transform: translate(2px,-18px);
        }
    }
  
    b {
        font-size: 11px;
        font-weight: normal;
        color: #fff;
        display: inline-block;
        width: 21px;
        height: 21px;
        background: #c54;
        border-radius: 50px;
        border: 2px solid #fff;
        transform: translate(3px,-17px);
        text-align: center;
        font-family: 'Open Sans';
        transition: 0.1s;
    }
}

.HeaderRightNotifications.empty {
    pointer-events: none;
    &.visible {
        opacity: 0.8;
    }
    b {
        background: #ccc;
    }
}
