
.CardPNIDDetalhe {
  .title {
    padding-right: 20px;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  .subtitle {
    margin: -2px 0 10px 0;
    line-height: 1;
    font-family: 'Share Tech';
    font-size: 16px;
    text-transform: uppercase;
    color: #fff;
    opacity: 0.5;
  }
  img {
    pointer-events: none;
    width: 100%;
    min-width: 600px;
    max-width: 800px;
    margin: 0 -20px -20px -20px;
  }
}
