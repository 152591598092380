.CardCadOccurrencesList {
  .card-title span {
    display: inline-block;
    padding-left: 5px;
    opacity: 0.5;
    text-transform: uppercase;
  }

  .card-sub-title {
    color: white;
    opacity: 0.5;
    margin-top: -12px;
    margin-bottom: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }

  p.message {
    pointer-events: none;
    padding: 30px 20px;
    font-family: 'Share Tech';
    font-size: 18px;
    text-align: center;
    text-rendering: geometricPrecision;
    text-transform: uppercase;
    color: #f9dfaab5;
    &.no-data {
      min-width: 610px;
      color: #DAA709;
    }
  }

  .summary {
    position: relative;
    margin: -10px 0 0 0;
    padding: 10px 0;
    input {
      width: 100%;
      padding: 2px 80px 2px 50px;
      font-size: 16px;
      color: #3B4046;
      text-transform: uppercase;
      text-rendering: geometricPrecision;
      outline: 0;
      background: #F6F8FA url(../../../../images/icon-search2.svg) no-repeat 12px center;
      border: 1px solid rgba(255,255,255, 0.4);
      border-radius: 50px;
      &::placeholder { color: #3B4046; text-transform: capitalize; }
      &:focus {
        background-color: #F6F8FA;
      }
    }
    .total-hidden {
      position: absolute;
      top: 15px;
      right: 20px;
      color: #3B4046;
      font-weight: bold;
    }
    .btn-circle {
      display: none;
    }
  }

  .table-list .ReactVirtualized__List {
    outline: 0;
  }

  .table-list {
    padding: 5px 0 10px 0;
    margin: -5px -20px -20px -20px;
    max-height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
    .table-row.header {
      pointer-events: none;
      padding-top: 12px;
      padding-bottom: 7px;
      background: #3B4046;
      .col {
        font-family: 'Roboto';
        font-weight: bold;
        font-size: 12px;
      }
    }
    .table-row {
      padding: 5px 20px;
      line-height: 1.2;
      cursor: pointer;
      color: rgba(255,255,255, 0.7);
      text-rendering: geometricPrecision;
      text-transform: uppercase;
      opacity: 0.9;
      &:hover {
        opacity: 1;
      }
      &:active {
        opacity: 0.5;
      }
      .col {
        display: inline-block;
        width: 180px;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
        
        &.address {
          width: 200px;
          padding-right: 20px;
        }

        &.type {
          width: 200px;
          padding-right: 20px;
        }
      }
    }
  }
}