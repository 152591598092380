
.CardFUNCEMEDetalhe {
  .title {
    padding-right: 30px;
    span {
      display: inline-block;
      padding-left: 5px;
      opacity: 0.5;
    }
  }

  .galeria {
    max-width: 500px;
    min-height: 20px;
    margin: -5px -20px 5px -20px;
    padding: 5px 25px;
    background: #3B4046;
    .imgs {
      transition: 0.3s;
    }
    .image {
      vertical-align: middle;
      overflow: hidden;
      display: inline-block;
      max-height: 200px;
      width: 280px;
      padding: 2px;
      margin: 5px;
      cursor: pointer;
      border-radius: 5px;
      border: 2px solid rgba(255,255,255, 0.1);
      transition: 0.1s;
      &:hover {
        border-color: rgba(255,255,255, 0.3);
        transition: none;
      }
      &:active {
        transform: scale(0.99);
        transition: none;
      }
      img {
        vertical-align: middle;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .info {
    pointer-events: none;
    .group {
      font-family: 'Share Tech';
      font-size: 16px;
      color: #fff;
    }
    .label {
      display: inline-block;
      width: 120px;
      text-align: right;
      opacity: 0.5;
    }
    .value {
      display: inline-block;
      width: calc(100% - 120px);
      padding-left: 5px;
      text-transform: uppercase;
    }
  }

}
