.CardCNHHabilitacao {
  width: 100%;
  margin-top: 10px;

  .message {
    padding: 20px 100px 10px 100px;
    text-align: center;

    font-family: "Share Tech";
    color: #daa709;
    text-rendering: geometricPrecision;
    font-size: 18px;
  }

  .message-loading {
    margin: 0;
    padding: 5px 0 5px 20px;
    font-size: 14px;
    font-family: "Share Tech";
    text-rendering: geometricPrecision;
    color: #fff;
    opacity: 0.5;
    background: rgba(255, 255, 255, 0.1);
    animation: TextBlink infinite 0.3s ease alternate;
  }
}
