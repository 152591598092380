.CardSispenVisitante {
  width: 100%;

  .card-title {
    margin-bottom: 10px;

    span {
      display: inline-block;
      padding-left: 5px;
      opacity: 0.6;
    }

    b {
      display: inline-block;
      padding-left: 5px;
      font-weight: normal;
      color: #DAA709;
    }
  }
  
  .error-container {
    display: flex;
    flex-direction: row;
    padding: 6px;
    margin: 0 -20px;
    
    .error-message {
      display: flex;
      align-items: center;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.7);
      background-color: #ce3e3e70;
      padding: 5px;
      margin: 0;
      width: 100%;

      .alert-icon {
        display: flex;
        margin-right: 5px;
        margin-top: -1px;
        width: 25px;
        height: 25px;
        background: url(../../../../images/icon-alert.svg) no-repeat center;
      }
    }
  }

  .message {
    pointer-events: none;
    padding: 20px;
    text-align: center;
    font-family: 'Share Tech';
    color: #DAA709;
    text-rendering: geometricPrecision;
    font-size: 18px;
  }

  .message-loading {
    margin: 0;
    padding: 5px 0 5px 20px;
    font-size: 14px;
    font-family: 'Share Tech';
    text-rendering: geometricPrecision;
    color: #fff;
    opacity: 0.5;
    background: rgba(255, 255, 255, 0.1);
    animation: TextBlink infinite 0.3s ease alternate;
  }

  .return-button-container {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;

    .return-button {
      margin-bottom: 25px;
      padding: 8px 8px;
      width: fit-content;
      border-radius: 7px;
      background: #F6F8FA;
      color: #3B4046;
      align-items: center;

      font-size: 0.875rem;
      font-family: Open sans, sans-serif;
      font-weight: 700;

      cursor: pointer;
    }
  }

  .label-list {
    font-family: 'Share Tech';
    font-size: 18px;
    color: #fff;
  }

  .line-values {
    //pointer-events: none;
    display: flex;
    padding: 3px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: -3px;
    margin-bottom: 5px;

    .item {
      flex: 1;
      padding-right: 20px;
      text-rendering: geometricPrecision;

      .label {
        text-transform: uppercase;
        font-size: 12px;
        font-family: 'Roboto';
        color: #b4bcbf;
        opacity: 0.9;
      }

      .value {
        display: block;
        font-size: 20px;
        font-family: 'Share Tech';
        line-height: 1;
        color: rgba(255, 255, 255, 0.7);
      }
    }

    .item.max-width {
      flex-grow: 9;
    }
  }

  .line-values.text {
    .value {
      font-size: 16px;
    }
  }

  .line-values.no-border {
    border: 0;
  }

  .line-values.inline {
    display: block;
    width: 100%;

    .item {
      display: inline-block;
    }
  }

  .group.columns {
    //pointer-events: none;
    padding: 0 20px 0 10px;
    margin: 0 -20px 0 -20px;
    min-height: 100px;
    background: rgba(0, 0, 0, 0.05);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);

    .column {
      vertical-align: top;
      display: inline-block;
    }

    .column.photo {
      width: 210px;
      margin: -8px;
      padding: 10px 0;
      transition: 0.1s;

      &.avatar {
        width: 120px;
        min-height: 130px;
        padding: 20px 0 0 20px;

        img {
          width: 90px;
        }
      }

      img {
        width: 200px;
        max-width: 200px;
        margin-right: -18px;
      }
    }

    .column.info {
      vertical-align: middle;
      width: calc(100% - 220px);
      padding: 30px 0 0 20px;
      line-height: 1;

      &.padding-top {
        padding-top: 50px;

        .line-values {
          padding: 3px 0;
        }
      }

      &.avatar {
        padding-top: 20px;
      }

      &>.line-values {
        display: block;
        width: 100%;
        padding: 2px 0;

        .item {
          display: inline-block;
        }
      }
    }
  }

 


  .container-list {
    min-width: 650px;

    .ReactVirtualized__List {
      width: calc(100% + 20px + 20px) !important;
      outline: 0;
      margin: 0 -20px -20px -20px;

      .ReactVirtualized__Grid__innerScrollContainer {
        top: 10px;
        margin-bottom: 10px;
        max-width: 100% !important;
      }
    }

    .row {
      cursor: pointer;
      padding: 5px;
      background: rgba(255, 255, 255, 0);
      border-top: 1px solid rgba(255, 255, 255, 0.05);
      opacity: 0.8;

      &.no-click {
        pointer-events: none;
      }

      &:hover {
        opacity: 1;
        background: rgba(255, 255, 255, 0.03);
      }

      &:active {
        opacity: 0.7;
      }
    }

    .row .column {
      display: inline-block;
      vertical-align: top;
      text-rendering: geometricPrecision;

      &.a {
        width: 100px;
        padding-left: 20px;
      }

      &.b {
        padding-left: 15px;
        width: calc(100% - 110px);
      }
    }

    .row .column.a {
      padding-top: 15px;
      line-height: 1.1;
      text-align: center;
      color: rgba(255, 240, 130, 0.9);

      .date {
        font-size: 14px;
        opacity: 0.7;
      }

      .time {
        font-size: 14px;
        font-weight: bold;
      }
    }

    .row .column.b {
      color: rgba(255, 255, 255, 0.8);

      .line {
        padding-top: 3px;
        line-height: 1;
        font-size: 12px;
        opacity: 0.5;
      }

      .mainline {
        font-family: 'Share Tech';
        font-size: 18px;
        line-height: 1;

        b {
          font-weight: normal;
          display: inline-block;
          padding-right: 5px;
        }
      }

      .labels {
        line-height: 1.1;
        font-size: 13px;

        span {
          display: inline-block;
          margin: 0 20px 0 0;
          color: rgba(255, 255, 255, 0.8);

          b {
            display: inline-block;
            padding-right: 5px;
            font-weight: normal;
            font-size: 12px;
            opacity: 0.5;
          }
        }
      }

      .labels.link {
        opacity: 0.5;
      }
    }
  }

}