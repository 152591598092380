.SearchPanel-BodyOptions-TagList {
  transition: 0.1s;

  &.hiddenZZZ {
    opacity: 0;
    transform: translate(0, -5px);
  }

  button {
    display: inline-block;
    margin: 5px;
    padding: 4px 14px;
    outline: 0;
    font-size: 14px;
    cursor: pointer;
    color: rgba(255,255,255, 0.8);
    background: rgba(0,0,0, 0);
    border: 2px solid rgba(255,255,255, 0.2);
    border-radius: 50px;

    &:hover {
      color: rgba(255, 255, 255, 0.9);
      border-color: rgba(255, 255, 255, 0.4);
    }

    &:active {
      opacity: 0.5;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    position: relative;

    .parent {
      opacity: 0.4;
      font-size: 10px;
      margin-right: 4px;
      margin-bottom: 2px;
    }

    .tooltip-hidden {
      pointer-events: none;
      opacity: 0;
      display: none;
    }

    .tooltip-tag-message {
      background: #E7E7E7;
      color: #2C383E;
      font-size: 0.625rem;
      font-family: Roboto, sans-serif;
      text-align: start;
      border-radius: 2px;
      width: 150px;
      height: auto;
      padding: 0.5rem;
      position: absolute;
      top: 35px;
      left: -25px;
      z-index: 2;
    }

    .tooltip-tag-message::after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 8%;
      margin-left: 15px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #E7E7E7 transparent;
    }

  }


}