
.Map .map-marker.pointer .box-container {
  cursor: pointer;
}

.Map .map-marker .box-container {
  width: 20px;
  height: 20px;
}

.Map .map-marker.border.fullborder .box-container:before {
  border-top-color: rgba(255,255,255, 0.5) !important;
}

.Map .map-marker.circle {
  width:  12px;
  height: 12px;
  background: #3dca85;
  border-radius: 50px;
  &.nobody {
    pointer-events: none;
    background: transparent !important;
  }
  &.animated {
    animation: loopMapMarkerOpacity 0.5s ease infinite alternate;
    .box {
      position: absolute;
      width: 38px;
      height: 38px;
      margin: -13px 0 0 -13px;
      background: #fff;
      border-radius: 50px;
      animation: loopMapMarkerZoomOut 1s ease infinite;
    }
  }
  &.slow.animated {
    animation: none;
    .box {
      animation: loopMapMarkerMidZoomOut 1s ease infinite alternate;
    }
  }
  &.fast.animated {
    animation: none;
    .box {
      animation: loopMapMarkerMidZoomOut 0.25s ease infinite alternate;
    }
  }
  &.border {
    .box-container:before {
      position: absolute;
      content: ' ';
      width: 32px;
      height: 32px;
      margin: -10px 0 0 -10px;
      opacity: 0.4;
      background: transparent;
      border-radius: 50px;
      border: 3px solid rgba(255,255,255, 0.5);
      border-top-color: transparent;
      border-bottom-color: transparent;
      animation: loopMapMarkerScaleX 0.5s linear alternate infinite;
    }
  }
}

.Map .map-marker.square {
  width:  12px;
  height: 12px;
  background: #3dca85;
  &.animated {
    animation: loopMapMarkerOpacity 0.5s ease infinite alternate;
    .box {
      position: absolute;
      width: 32px;
      height: 32px;
      margin: -10px 0 0 -10px;
      background: #fff;
      animation: loopMapMarkerZoomOut 1s ease infinite;
    }
  }
  &.animated.rotate .box {
    animation-name: loopMapMarkerOpacityRotate;
  }
  &.border {
    .box-container:before {
      position: absolute;
      content: ' ';
      width: 32px;
      height: 32px;
      margin: -10px 0 0 -10px;
      opacity: 0.4;
      background: transparent;
      // border-radius: 50%;
      border: 3px solid rgba(255,255,255, 0.5);
      border-top-color: transparent;
      border-bottom-color: transparent;
      animation: loopMapMarkerRotate 1.5s linear infinite;
    }
  }
  &.fullborder .box-container:before {
    border-radius: 50%;
  }
}

.Map .map-marker.triade {
  margin: -6px 0 0 0;
  background: none !important;
  .box-container {
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: #ddd;
  }
  //
  &.animated {
    animation: loopMapMarkerOpacity 0.5s ease infinite alternate;
    .box {
      position: absolute;
      width: 32px;
      height: 32px;
      margin: -10px 0 0 -16px;
      background: #fff;
      animation: loopMapMarkerZoomOut 1s ease infinite;
    }
  }
  &.animated.rotate .box {
    animation-name: loopMapMarkerOpacityRotate;
  }
}
.Map .map-marker.triade.bottom {
  margin-top: 5px;
  .box-container {
    border-bottom-color: transparent;
    border-top-color: #ddd;
    .box {
      margin-top: -21px;
    }
  }
}

.Map .map-marker.triade:not(.bottom) {
  &.green  .box-container { border-bottom-color: #3a3 }
  &.yellow .box-container { border-bottom-color: #fd5 }
  &.orange .box-container { border-bottom-color: #d52 }
  &.blue   .box-container { border-bottom-color: #4ad }
}
.Map .map-marker.triade.bottom {
  &.green  .box-container { border-top-color: #3a3 }
  &.yellow .box-container { border-top-color: #fd5 }
  &.orange .box-container { border-top-color: #d52 }
  &.blue   .box-container { border-top-color: #4ad }
}


@keyframes loopMapMarkerOpacity {
  from { opacity: 0.6 }
  to   { opacity: 0.9 }
}
@keyframes loopMapMarkerRotate {
  from { transform: rotate(0deg)   }
  to   { transform: rotate(360deg) }
}
@keyframes loopMapMarkerScaleX {
  from { transform: scale3d(0.9,1,1) }
  to   { transform: scale3d(1.2,1,1) }
}
@keyframes loopMapMarkerOpacityRotate {
  from { opacity: 0.3; transform: rotate(45deg)   }
  to   { opacity: 0.05; transform: rotate(135deg) }
}
@keyframes loopMapMarkerZoomOut {
  from { opacity: 0.8; transform: scale(0.6) }
  to   { opacity: 0.0; transform: scale(1.0) }
}
@keyframes loopMapMarkerMidZoomOut {
  from { opacity: 0.3; transform: scale(0.6) }
  to   { opacity: 0.1; transform: scale(0.7) }
}
