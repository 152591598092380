.LoginInputsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;

    width: 520px;
    height: 350px;

    background: #3B4046;
    border-radius: 14px;
    border: 0.5px solid #5f6672;
    box-shadow: 1px 2px 10px #3B4046;
    transition: 0.2s ease 0.1s;

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: 0.2s;

        &.hidden{
            opacity: 0;
            transform-origin: center;
            transform: scaleX(0);
            width: 0;
            height: 0;
        }
    }

    &.hidden {
        opacity: 0;
        transform: scaleY(0);
        pointer-events: none;
    }

    .feedback {
        opacity: 0;
        transition: 0.2s;
        transform: scale(1,0.4);
        transform-origin: center;
        margin: 0 50px;
    }

    &.message .feedback {
        opacity: 1;
        transition-delay: 0.2s;
        transform: scale(1, 1);
        width: fit-content;
        height: auto;
    }

    &.sending {
        pointer-events: none;
    }

    .close-button{
        position: absolute;
        top: 20px;
        right: 20px;
        width: 22px;
        height: 22px;
        cursor: pointer;
        outline: 0;
        background: rgba(0, 0, 0, 0) url(../../images/cancel-x.svg) no-repeat center;
        background-size: 14px;
        border: 0;
        border-radius: 50%;
        opacity: 0.3;

        &.hidden {
            opacity: 0;
        }
    }

    .title {
        color: #FFFFFF;
        font-family: Saira Condensed;
        // font-family: Share Tech, sans-serif;
        font-size: 32px;
        font-weight: 400;
        line-height: 28px;
        text-align: center;
    }

    .sub-title {
        color: #FFFFFF;
        font-family: Saira Condensed;
        // font-family: Share Tech, sans-serif;
        font-size: 20px;
        font-weight: 400;
        opacity: 0.7;
        text-align: center;
    }

    .auth-form {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 32px;

        gap: 1rem;

        input {
            background: #FFFFFF;
            font-size: 18px;
            text-align: left;
            padding: 8px 15px 8px 50px;
            border: 0;
            box-shadow: 1px 2px 3px #26292e;
            border-radius: 2px;
            background: #fff no-repeat 20px center;
            outline: 0;

            &::placeholder {
                color: #78807e;
                font-family: Open Sans;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1;

                opacity: 0.75;
            }

            &:focus {
                &::placeholder {
                    opacity: 0;
                    background: none;
                }
            }

            &.cpf-input{
                background-image: url(../../images/icon-id.svg);
            }

            &.password-input {
                background-image: url(../../images/icon-lock.svg);
            }

            &.email-input{
                background-image: url(../../images/email-icon.svg);
            }
        }

        .remembered-password {
            display: flex;
            flex-direction: row;
            align-items: baseline;

            p {
                margin: 0;
                margin-top: -10px;
                color: white;

                &::after {
                    content: "\00a0";
                }
            }
        }

        .forgot-password {
            color: #DAA709;
            text-decoration: underline;
            margin-top: -10px;
            font-family: Open Sans;
            font-size: 0.9rem;
            font-weight: 400;

            cursor: pointer;

            &:hover {
                opacity: 0.75;
            }
        }
    }

    .instructions-text {
        padding: 0 82px 20px 82px;

        color: #FFFFFF;
        font-family: Open Sans;
        font-size: 1rem;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }

    .message-warning {
        padding-top: 20px;

        color: #DAA709;
        font-family: Open Sans;
        font-size: 1rem;
        font-weight: 600;
        line-height: 28px;
        text-align: center;
    }

    .acess-button {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;

        background: #DAA709;
        box-shadow: 1px 2px 10px #26292e;
        color: #FFFFFF;
        width: 168px;
        height: 40px;
        margin-top: 5px;
        border-radius: 30px;
        border: 0;
        cursor: pointer;
        transition: 0.3s;

        font-family: Open Sans;
        font-size: 1.1rem;
        font-weight: 400;
        line-height: 24px;

        &.sending {
            cursor: default;
            width: 40px;
            color: transparent !important;
            background-image: url(../../images/icon-sending.svg);
            background-position: center;
            background-repeat: no-repeat;
        }
    }
}

