
.HeaderLeftAppLogo {
    width: 210px;
    height: 70px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    background: url(../../../images/layout-header-left-gold.svg) no-repeat bottom right;
    transition: 0.2s;
  
    b {
        display: inline-block;
        margin: 15px 0 0 40px;
        font-family: 'Share Tech';
        text-rendering: geometricPrecision;
        letter-spacing: -1px;
        font-size: 20px;
        font-weight: bold;
        color: rgba(255,255,255, 0.9);
        padding-top: 4px;
    }
    span {
        display: inline-block;
        margin-top: 13px;
        padding-left: 3px;
        vertical-align: top;
        font-family: 'Share Tech';
        letter-spacing: -1px;
        font-size: 15px;
        font-weight: lighter;
        color: rgba(255,255,255, 0.3);
        transition: 0.2s;
        i {
            padding: 2px 5px 2px 1px;
            font-size: 14px;
            color: #fff;
            background: #c69d50;
        }
    }
    &:hover span {
        color: rgba(255, 255, 255, 0.6);
        transform: translate(3px,0);
    }
    
}

