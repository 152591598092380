
.HeaderLeft {
    position: fixed;
    z-index: 9999999;
    top:  -26px;
    left: -10px;
    width: 350px;
    height: 80px;
    padding-top: 20px;
    display: flex;
    justify-content: flex-start;
    background: url(../../../images/layout-header-left-white.svg) no-repeat right bottom;
    transform: translate(0,-90px);
    transition: 0.5s;
    &.visible {
        transform: translate(0,0);
    }
    &.large {
        transform: translate(-20px,0);
        transition: 0.2s;
    }
}

body.no-header .HeaderLeft {
    transform: translate(0,-70px);
    transition: 0.2s;
}

body.is-search-focus .HeaderLeft {
    transform: translate(-25px,4px);
    transition: 0.2s;
}
